import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import PulseLoader from 'react-spinners/PulseLoader';
import { Link } from "react-router-dom"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import './contact-form.style.scss';

const override = {
	display: 'block',
	margin: '0 auto',
	borderColor: 'red',
};

const ContactForm = () => {
	const [loading, setLoading] = useState(false);
	const [color, setColor] = useState('#ffffff');
	const [emailResponse, setEmailResponse] = useState(null);
	const form = useRef();
	const formik = useFormik({
		initialValues: {
			name: '',
			phone: '',
			email: '',
			comments: '',
			terms: false, // Change the default value to false
		},
		validationSchema: Yup.object({
			name: Yup.string().label('Full Name').required('Required'),
			email: Yup.string()
				.email('Email must be a valid email')
				.required('Required'),
			phone: Yup.number().required('Required'),
			terms: Yup.boolean()
				.oneOf([true], 'You must accept the Terms and Conditions to proceed') // Validate that it's true
				.required('You must accept the Terms and Conditions to proceed'), // Also add a required validation
		}),
		onSubmit: function (values, { resetForm }) {
			setLoading(true);
			emailjs
			.sendForm(
				'service_ncg7lcx',
				'template_qrgij2i',
				form.current,
				'leKa87PO2IJcDQlhA'
			)
			.then(
				(result) => {
					setLoading(false);
					setEmailResponse(result);
					resetForm();
				},
				(error) => {
					setLoading(false);
					setEmailResponse(error);
					resetForm();
				}
			);
			
			setTimeout(()=>{
				setEmailResponse(null);
			}, 10000)
		},
	});

	return (
		<div className="contact-form-wrapper">
			<h5>Contact us</h5>
			<Form onSubmit={formik.handleSubmit} role="form" ref={form}>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Full Name</Form.Label>
					<Form.Control
						type="text"
						name="name"
						placeholder="Full Name"
						className={`${
							formik.touched.name && formik.errors.name ? 'border-red' : null
						}`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.name}
					/>
					{formik.touched.name && formik.errors.name && (
						<span className="text-red">{formik.errors.name}</span>
					)}
				</Form.Group>

				<Form.Group className="mb-3" controlId="formBasicPassword">
					<Form.Label>Phone number</Form.Label>
					<Form.Control
						type="text"
						name="phone"
						placeholder="Phone Number"
						className={`${
							formik.touched.phone && formik.errors.phone ? 'border-red' : null
						}`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.phone}
					/>
					{formik.touched.phone && formik.errors.phone && (
						<span className="text-red">{formik.errors.phone}</span>
					)}
				</Form.Group>

				<Form.Group className="mb-3" controlId="formBasicPassword">
					<Form.Label>Email</Form.Label>
					<Form.Control
						type="text"
						name="email"
						placeholder="Email"
						className={`${
							formik.touched.email && formik.errors.email ? 'border-red' : null
						}`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.email}
					/>
					{formik.touched.email && formik.errors.email && (
						<span className="text-red">{formik.errors.email}</span>
					)}
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasicPassword">
					<Form.Label>Comments</Form.Label>
					<Form.Control
						as="textarea"  
						name="comments"  
						placeholder="Type your comments here"
						className={`${
						formik.touched.comments && formik.errors.comments ? 'border-red' : null
						}`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.comments}
					/>
					{formik.touched.comments && formik.errors.comments && (
						<span className="text-red">{formik.errors.comments}</span>
					)}
					</Form.Group>

				<Form.Group className="mb-3" controlId="formBasicCheckbox">
					<Form.Check
						type="checkbox"
						name="terms"
						label={
							<span>
								I have read and agree to 
								{' '} <Link to="/term-of-service">Terms of Service</Link>
								{' '} and 
								{' '} <Link to="/privacy-and-policy">Privay & Policy</Link>
							</span>
						}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.terms}
					/>
					{formik.touched.terms && formik.errors.terms && (
						<span className="text-red">{formik.errors.terms}</span>
					)}
				</Form.Group>
				{emailResponse != null && emailResponse.status == 200 ? (
					<Alert key="success" variant="success">
						<span style={{ marginRight: '10px' }}>
							<FontAwesomeIcon icon={faCircleCheck} />
						</span>
						Email has been sent successfully. We'll get in touch soon!
					</Alert>
				) : null}

				<Button
					variant="primary"
					type="submit"
					disabled={loading ? true : false}
				>
					{loading ? (
						<PulseLoader
							color={color}
							loading={loading}
							cssOverride={override}
							size={10}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					) : (
						'Submit'
					)}
				</Button>
			</Form>
		</div>
	);
};

export default ContactForm;
