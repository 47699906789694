import React, { useEffect, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/images/document.png";
import document1 from "../../../assets/images/document1.jpg";
import document2 from "../../../assets/images/document2.jpg";

import ContactForm from "../../../components/contact-form/contact-form.component";
import GetStarted from "../../../components/home/get-started/get-started.component";
import Faq from "../../faq/faq.component";
import WhoCanWork from "../../../components/home/who-can-work/who-can-work.component";

import "./documents.style.scss";

const Documents = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const changeHandler = (path) => {
    navigate(path);
  };
  return (
    <Fragment>
      <section className="truck-dispatch services-common">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <div className="banner">
                <img src={img} alt="box-truck" />
              </div>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col lg={12} className="p-0">
            <div className="content">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="heading">
                      <h1>Document Management</h1>
                    </div>
                    <div className="points-wrapper">
                      <ul>
                        <li>Comprehensive coverage</li>
                        <li>Management of all trucking documents</li>
                        <li>
                          Years of industry expertise and dedicated personnel
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <ContactForm />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="">
                    <div className="truck-content mt-5 mb-3">
                      <h2>Document Management</h2>
                      <p className="mt-4">
                        At SM Logistics, our unwavering commitment lies in
                        providing exceptional care to our clients within the
                        logistics industry – an essential pillar of the nation's
                        economy. We pride ourselves on delivering a
                        comprehensive suite of solutions tailored for truckers
                        and trucking companies, with a primary focus on dispatch
                        services. Our dedicated dispatch services aim to offer
                        invaluable support, allowing clients to redirect their
                        attention from ancillary tasks to core business
                        activities. For numerous clients, our cutting-edge
                        trucking document management proves to be a vital
                        lifeline, streamlining operations and enhancing overall
                        efficiency.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="services my-5">
                      <h4 className="my-4">Choose Your Truck</h4>
                      <ul>
                        <li onClick={() => changeHandler("/trucks/power-only")}>
                          <Link to="">Power Only</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/hot-shot")}>
                          <Link to="">Hotshot</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/box-truck")}>
                          <Link to="">Box Truck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/flatbed")}>
                          <Link to="">FlatBed</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/reefer")}>
                          <Link to="">Reefer</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/step-deck")}>
                          <Link to="">Step Deck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/dry-van")}>
                          <Link to="">Dry Van</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <img src={document1} alt="document" className="w-100" />
                    <h2 className="my-4">Management of Trucking Documents</h2>
                    <p>
                      When the public envisions the role of truckers, they
                      frequently overlook the extensive administrative
                      responsibilities integral to the profession. In an era
                      dominated by digitalization, additional concerns arise,
                      particularly in the realm of data and information
                      security. The meticulous management of mandatory
                      documentation, ensuring accurate completion, filing, and
                      secure retention, demands a significant investment of time
                      and effort. This undertaking not only presents inherent
                      stress but can also burden the seamless operation of your
                      logistics enterprise. Enter SM Logistics, poised to
                      alleviate these challenges by offering strategic solutions
                      tailored to streamline administrative burdens and fortify
                      the overall efficiency of your trucking operations.
                    </p>
                    <h2 className="my-4">Comprehensive Coverage</h2>
                    <p>
                      Trucking entails much more than just driving, and in
                      reality, navigating through stringent paperwork
                      requirements, compliance with safety and DOT regulations,
                      and managing insurance matters adds significant
                      complexity. Beyond that, the challenges extend to finding,
                      negotiating, and securing loads. At SM Logistics, we
                      comprehensively address every facet of these demands. From
                      broker/carrier packages and paperwork setup to handling
                      W-9 forms, proof of insurance, insurance certificates,
                      rate confirmations, invoicing, waybills, bills of lading,
                      and customs/transportation documents, we've got you
                      covered. Our dedicated team also manages the intricacies
                      outlined in customer agreements, including providing
                      directions to shippers or consignees and coordinating
                      appointment scheduling. With SM Logistics, rest assured
                      that every operational detail is seamlessly taken care of,
                      allowing you to focus on driving success in your logistics
                      endeavors.
                    </p>
                    <h2 className="my-4">Expertise and Excellence</h2>
                    <p>
                      At SM Logistics, our dedication to client satisfaction is
                      the cornerstone of our service ethos. This commitment is
                      underpinned by an in-depth understanding of the
                      intricacies of the trucking industry, encompassing
                      industry standards and regulatory mandates. We not only
                      comprehend the what, but also the why and who behind these
                      requirements. Our meticulous approach to preparing,
                      filing, and managing trucking documentation is
                      characterized by diligence and precision, ensuring that
                      every essential element is accurately included. We
                      approach your success with utmost seriousness, recognizing
                      that your achievements directly contribute to ours as
                      well. With SM Logistics, trust in a partner that
                      prioritizes your success and consistently delivers
                      excellence in the intricate landscape of the logistics
                      industry.
                    </p>
                    <img src={document2} alt="document" className="w-100 my-4" />
                    <h2 className="my-4">
                      Our Approach to Document Management
                    </h2>
                    <p>
                      At SM Logistics, we excel in delivering top-notch
                      management of trucking documentation either independently
                      or as an integral component of a comprehensive dispatch
                      service package. Recognizing that diverse trucking
                      businesses require varying levels of support, we uphold a
                      no-nonsense approach to service. Our commitment lies in
                      collaborating closely with our clients to ascertain their
                      specific needs. We understand that you possess a deep
                      understanding of your business, and our role is to provide
                      precisely what you require. At SM Logistics, we are not
                      here to promote services you don't desire; instead, we are
                      dedicated to tailoring our offerings to align seamlessly
                      with your unique operational requirements.
                    </p>
                    <h2 className="my-4">
                      But More Than Just Document Management
                    </h2>
                    <p>
                      At SM Logistics, a significant number of our valued
                      clients appreciate our comprehensive suite of services,
                      entrusting us with tasks they prefer not to handle,
                      allowing them to concentrate on their core strengths.
                      Through our dispatch service, we take on the
                      responsibilities of load searching, rate negotiation –
                      always striving for the highest rates to maximize our
                      clients' earnings – and meticulous coordination of
                      logistical details. Billing, proficient management of
                      trucking paperwork, and ensuring the timely and accurate
                      completion of all requirements outlined in customer
                      contracts are seamlessly handled by our capable team.
                      Beyond these core services, we extend our support to
                      clients by managing bookkeeping and accounting tasks,
                      establishing and maintaining IFTA reports, collaborating
                      with online transportation management services, and more.
                      At SM Logistics, our commitment is not just to meet but to
                      exceed the diverse needs of our clients, ensuring a
                      comprehensive and tailored approach to logistics
                      excellence.
                    </p>
                    <h2 className="my-4">
                      But More Than Just Document Management
                    </h2>
                    <p>
                      At SM Logistics, a significant number of our valued
                      clients appreciate our comprehensive suite of services,
                      entrusting us with tasks they prefer not to handle,
                      allowing them to concentrate on their core strengths.
                      Through our dispatch service, we take on the
                      responsibilities of load searching, rate negotiation –
                      always striving for the highest rates to maximize our
                      clients' earnings – and meticulous coordination of
                      logistical details. Billing, proficient management of
                      trucking paperwork, and ensuring the timely and accurate
                      completion of all requirements outlined in customer
                      contracts are seamlessly handled by our capable team.
                      Beyond these core services, we extend our support to
                      clients by managing bookkeeping and accounting tasks,
                      establishing and maintaining IFTA reports, collaborating
                      with online transportation management services, and more.
                      At SM Logistics, our commitment is not just to meet but to
                      exceed the diverse needs of our clients, ensuring a
                      comprehensive and tailored approach to logistics
                      excellence.
                    </p>
                  </Col>
                </Row>
              </Container>
              <Container fluid>
                <Row>
                  <Col lg={12}>
                    <WhoCanWork />
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col lg={12}>
                    <Faq page="truck-dispatch" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="contact-links">
                      <Link to="/contact-us">Contact Us</Link>
                      <Link to="tel:7076469911">(707) 646-9911</Link>
                      <Link to="mailto:smlogistics.us@gmail.com">
                        smlogistics.us@gmail.com
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
    </Fragment>
  );
};

export default Documents;
