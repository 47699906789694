import React from 'react'
import { Row, Col, Container } from "react-bootstrap"
import Slider from '../../slider/slider.component'

import "./testimonials.style.scss"

const Testimonials = () => {
  return (
    <section className='testimonials'>
        <Container fluid>
            <Row>
                <Col lg={12}>
                    <div className='content-wrapper'>
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className='started-heading-wrapper'>
                                        <h1>Testimonials</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className='testimonials-wrapper'>
                                        <Row>
                                            <Col lg={12}>
                                                <div className='slider'>
                                                    <Slider />
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default Testimonials
