import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Row, Col } from "react-bootstrap"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons'

import img1 from "../../assets/images/truck-types/truck-type-1.png"
import img2 from "../../assets/images/truck-types/truck-type-2.png"
import img3 from "../../assets/images/truck-types/truck-type-3.png"

import './slider.style.scss';

const Slider = () => {
	const onChange = () => {};

	const onClickItem = () => {};

	const onClickThumb = () => {};

	return (
		<div className="silder-wrapper">
			<Carousel
				showArrows={true}
				onChange={onChange}
				onClickItem={onClickItem}
				onClickThumb={onClickThumb}
                autoPlay={true}
                infiniteLoop={true}
                stopOnHover={true}
                interval={7000}
                showThumbs={false}
			>
                <Row>
                    <Col lg={12}>
                        <div className='slide'>
                            <Row>
                                <Col lg={5}>
                                    <div className='img'>
                                        <img src={img1} width={100} alt='testimonial' />
                                    </div>
                                </Col>
                                <Col lg={7}>
                                    <div className='content'>
                                        <h2 className='name'>Sohn Oconner</h2>
                                        <p className='mc'>MC # 1521395</p>
                                        <p className='details'>SM Logistics has been exceptional for my trucking business. Their communication is outstanding, and their industry knowledge is top-notch. They handle dispatching so efficiently that I haven't needed anyone else since. What's more, their pricing is incredibly reasonable, far better than companies charging over 10 percent. Thanks, SM Logistics!</p>
                                        <div className='quote-mark'>
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <div className='slide'>
                            <Row>
                                <Col lg={5}>
                                    <div className='img'>
                                        <img src={img2} width={100} alt='testimonial' />
                                    </div>
                                </Col>
                                <Col lg={7}>
                                    <div className='content'>
                                        <h2 className='name'>Junior Kesseh</h2>
                                        <p className='mc'>MC # 1560208</p>
                                        <p className='details'>SM Logistics has been a game-changer for my trucking business. Their communication is exceptional, and their industry expertise is second to none. They've taken care of our dispatching needs so effectively that we haven't had to look elsewhere. Plus, their pricing is incredibly reasonable, much better than other companies charging high percentages. Thank you, SM Logistics!</p>
                                        <div className='quote-mark'>
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12}>
                        <div className='slide'>
                            <Row>
                                <Col lg={5}>
                                    <div className='img'>
                                        <img src={img3} width={100} alt='testimonial' />
                                    </div>
                                </Col>
                                <Col lg={7}>
                                    <div className='content'>
                                        <h2 className='name'>Anthony Dennard</h2>
                                        <p className='mc'>MC # 1567421</p>
                                        <p className='details'>Entrusting our trucking business to SM Logistics has been a game-changer. Their communication is top-notch, and their industry expertise is unmatched. We've had no need for another dispatcher since. Their pricing is fair, much better than companies charging high percentages. Thanks, SM Logistics!</p>
                                        <div className='quote-mark'>
                                            <FontAwesomeIcon icon={faQuoteLeft} />
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
			</Carousel>
		</div>
	);
};

export default Slider;
