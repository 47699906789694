import React, { useEffect, useState } from 'react'
import { Row, Col, Container } from "react-bootstrap"
import { useNavigate } from "react-router-dom"

import ContactForm from '../../contact-form/contact-form.component';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'

import "./get-started.style.scss"

const GetStarted = ({ page }) => {
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const navigate = useNavigate()

    useEffect(() => {
        loadSection()
    }, [])


    const changeHandler = (path) => {
        navigate(path)
    }

    const loadSection = () => {
        if (page === "home" || page === "truck-dispatch") {
            setTitle("How To Get Started")
            const details = <div className='started-info-wrapper'>
                <p>
                    SM Logistics job is to provide the best loads in the market for every driver. Our expert team ensures that drivers from any region and state get high-quality and best-paying loads.
                </p>
                <ul>
                    <li onClick={() => changeHandler("/contact-us")}>Fill out the form or call us: (707) 646-9911 <FontAwesomeIcon icon={faArrowRight} /></li>
                    <li onClick={() => changeHandler("/how-to-start")}>Upload your MC Authority, W9 Form, and Certificate of Insurance <FontAwesomeIcon icon={faArrowRight} /></li>
                    <li onClick={() => changeHandler("/how-to-start")}>Sign the Dispatch Agreement completed by our team <FontAwesomeIcon icon={faArrowRight} /></li>
                    <li onClick={() => changeHandler("/how-to-start")}>We are all set. Let’s get to work <FontAwesomeIcon icon={faArrowRight} /></li>
                </ul>
            </div>
            setContent(details)
        } else if (page === "contact-us") {
            setTitle("Contact us")
            const details = <div className='contact-us-wrapper'>
                <div className='details'>
                    <h4 >YOUR CONVENIENCE
                        IS OUR PRIORITY</h4>
                    <hr />
                    {/* <p className='my-4'>Head Office</p> */}
                    <h4 className='mb-4'><FontAwesomeIcon icon={faPhone} />&nbsp;(707) 646-9911</h4>
                    <h4><FontAwesomeIcon icon={faEnvelope} />&nbsp;smlogistics.us@gmail.com</h4>
                </div>
            </div>
            setContent(details)
        }
    }
    return (
        <section className='get-started'>
            <Container fluid>
                <Row>
                    <Col lg={12}>
                        <div className='content-wrapper'>
                            <Container>
                                <Row>
                                    <Col lg={12} className='p-0'>
                                        <div className='started-heading-wrapper'>
                                            <h1>{title}</h1>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6} className={page === "contact-us" ? "p-0" : ""}>
                                        {content}
                                    </Col>
                                    <Col lg={6} className={page === "contact-us" ? "p-0" : ""}>
                                        <div className='started-form-wrapper'>
                                            <ContactForm />
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default GetStarted
