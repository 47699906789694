import React, { useEffect, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/images/rate-negotiation-banner.png";
import document1 from "../../../assets/images/rate-negotiation1.jpg";

import ContactForm from "../../../components/contact-form/contact-form.component";
import Faq from "../../faq/faq.component";
import WhoCanWork from "../../../components/home/who-can-work/who-can-work.component";

import "./rate-negotiation.style.scss";

const RateNegotiation = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const changeHandler = (path) => {
    navigate(path);
  };
  return (
    <Fragment>
      <section className="rate-negotiation services-common">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <div className="banner">
                <img src={img} alt="box-truck" />
              </div>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col lg={12} className="p-0">
            <div className="content">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="heading">
                      <h1>Rate negotiation</h1>
                    </div>
                    <div className="points-wrapper">
                      <ul>
                        <li>The best pay rate on every job</li>
                        <li>
                          Hands-off process for truckers and owner-operators
                        </li>
                        <li>Transparent pricing that you can rely on</li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <ContactForm />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="">
                    <div className="truck-content mt-5 mb-3">
                      <h2>Get the Best Truck Driver Pay Rate on Every Job</h2>
                      <p className="my-4">
                        The service you provide is so valuable to your clients.
                        Your trucking operation is keeping America moving and
                        keeping our society functioning at its best. This is why
                        you deserve the best rates possible for every trucking
                        and logistics job you take on.
                      </p>

                      <p>
                        With our expert negotiation service, you get more for
                        every mile. Our services go far beyond simple average
                        rate negotiation, and we help you position your
                        operation and your brand at the forefront of the market.
                      </p>

                      <p>
                        We’ll take care of everything. All you need to do is
                        drive.
                      </p>

                      <p>
                        Get started today. Reach out to us via phone or email to
                        get a quote for our services or find out more about what
                        we can provide to you and your operation.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="services my-5">
                      <h4 className="my-4">Choose Your Truck</h4>
                      <ul>
                        <li onClick={() => changeHandler("/trucks/power-only")}>
                          <Link to="">Power Only</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/hot-shot")}>
                          <Link to="">Hotshot</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/box-truck")}>
                          <Link to="">Box Truck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/flatbed")}>
                          <Link to="">FlatBed</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/reefer")}>
                          <Link to="">Reefer</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/step-deck")}>
                          <Link to="">Step Deck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/dry-van")}>
                          <Link to="">Dry Van</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <img
                      src={document1}
                      alt="document"
                      className="w-100 my-3"
                    />
                    <h2 className="my-4">
                      The Benefits of Factoring for Trucking Companies
                    </h2>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        More and more truck drivers across the US are turning to
                        SM Logistics for rate negotiation and more. Here’s why.
                      </span>
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-c915ca11-287a-43e8-ad49-1362558118f9="loaded">
                        <span style={{ fontWeight: 400 }}>
                          A fair rate for you and your clients
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        You provide a great service for your clients, and so you
                        deserve to get paid a fair truck rate per mile and per
                        load. You also work to support your clients’ needs, and
                        so you want to offer a competitive rate that keeps your
                        business moving and your customers smiling. We
                        understand this, which is why we apply our expert
                        negotiation skills to get you the best truck driver pay
                        rate for your services.
                      </span>
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-c915ca11-287a-43e8-ad49-1362558118f9="loaded">
                        <span style={{ fontWeight: 400 }}>
                          Connection with the ideal opportunities
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        We can search for loads nationwide, which means that you
                        only need to worry about driving while we handle the
                        rest. This translates to a solid revenue stream for your
                        business. You can scale and grow your operation,
                        connecting with the opportunities you need and building
                        lasting relationships with all-important clients.
                      </span>
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-c915ca11-287a-43e8-ad49-1362558118f9="loaded">
                        <span style={{ fontWeight: 400 }}>
                          Expert negotiation of terms
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        Many factors go into crafting the ideal relationship
                        between truckers and clients. There is the pricing, but
                        there are also other terms and conditions that must be
                        discussed and agreed upon. This is where our negotiation
                        expertise comes into play, and it goes way beyond simply
                        organizing a pay rate for truck drivers. You will enjoy
                        opportunities and terms that suit you while also helping
                        your clients achieve their objectives.
                      </span>
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-c915ca11-287a-43e8-ad49-1362558118f9="loaded">
                        <span style={{ fontWeight: 400 }}>
                          A hands-off process for truckers and owner-operators
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        You take care of the driving; we take care of the rest.
                        We apply this motto to all of our services, including
                        our rate negotiation services. Our aim is to secure a
                        great truck driver hourly rate without the stress and
                        anxiety. When you work with us, you know that you are
                        getting the best services, so you can focus on what you
                        do best.
                      </span>
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-c915ca11-287a-43e8-ad49-1362558118f9="loaded">
                        <span style={{ fontWeight: 400 }}>
                          A close and personal service, tailored to what you
                          need
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        We don’t believe in ‘off the peg’ services or a ‘one
                        size fits all’ approach. We know that the truckers we
                        work with each have specific needs and expectations,
                        which is why we work closely with you to get to know
                        what you and your operation are all about. We can then
                        provide a tailored service that helps you go above and
                        beyond your goals.
                      </span>
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-c915ca11-287a-43e8-ad49-1362558118f9="loaded">
                        <span style={{ fontWeight: 400 }}>
                          Transparent pricing that you can rely on
                        </span>
                      </li>
                    </ul>
                    <p>
                      <span style={{ fontWeight: 400 }}>
                        There are no nasty surprises when you work with us. We
                        offer a transparent and reliable pricing system, so you
                        know exactly how much you’ll pay for our services. You
                        can plan your routes and forecast your revenue with
                        complete peace of mind.
                      </span>
                    </p>
                    <h2
                      className="wp-block-heading"
                      id="why-freight-rate-negotiations-are-so-important"
                    >
                      Why Freight Rate Negotiations Are So Important
                    </h2>
                    <p>
                      What is it that makes freight rate negotiations so
                      important? Why do you need to connect with the best rate
                      negotiation services in the industry? There are a number
                      of factors to consider here. Let’s take a look at some of
                      the most critical for you and your business.
                    </p>
                    <h3
                      className="wp-block-heading"
                      id="secure-a-fair-truck-rate-for-drivers"
                    >
                      Secure a fair truck rate for drivers
                    </h3>
                    <p>
                      Truck drivers are the backbone of the United States. The
                      services you provide help keep this country moving and
                      businesses thriving, and help ensure that the domestic
                      economy stays strong. This means you deserve a fair truck
                      driver pay rate.
                    </p>
                    <p>
                      Whether you operate on an average rate per mile basis or
                      you prefer to run your truck driver business according to
                      an hourly rate, that pay rate needs to support you as a
                      business owner and as an individual. Truck drivers like
                      you have a difficult and taxing job to do, and your goals
                      include achieving great results for clients. Freight rate
                      negotiations make sure that this is the case.
                    </p>
                    <h3
                      className="wp-block-heading"
                      id="support-safety-and-security-in-the-industry"
                    >
                      Support safety and security in the industry
                    </h3>
                    <p>
                      The trucking industry is regulated for a
                      reason.&nbsp;Truck drivers have a right to feel safe and
                      secure when they are out on the road. Clients have the
                      right to feel that their logistics drivers and couriers
                      are providing a reliable service. Other road users have
                      the right to drive without the fear of encountering risks
                      and hazards along the way.
                    </p>
                    <p>
                      If customers make low ball offers to drivers and if truck
                      drivers themselves undercut their fellow professionals
                      with substandard services, everyone is put at risk. When
                      we negotiate rates for drivers, we are helping to make
                      sure everyone gets a fair deal and stays protected.
                    </p>
                    <h3
                      className="wp-block-heading"
                      id="stay-competitive-in-your-field-with-the-right-truck-driver-pay-rate"
                    >
                      Stay competitive in your field with the right truck driver
                      pay rate
                    </h3>
                    <p>
                      Logistics is a competitive field. There are a great many
                      different drivers and service providers out there, each
                      vying for a share of a limited market. This makes it
                      crucial that your business is competitive&nbsp;— that your
                      business offers rates that reflect the need and demand of
                      the market.
                    </p>
                    <p>
                      This does not mean cutting into your profits. Nor does it
                      mean offering the cheapest rates possible&nbsp;— we’ll
                      discuss this further below. Instead, it means providing
                      great services at a competitive rate that enables you to
                      grow your market share while securing full and fair
                      remuneration for what you offer.
                    </p>
                    <h3
                      className="wp-block-heading"
                      id="build-your-reputation-and-identity-as-a-driver"
                    >
                      Build your reputation and identity as a driver
                    </h3>
                    <p>
                      Your clients don’t simply want the cheapest option out
                      there. They need to know that they are working with a
                      driver and a service provider they can really trust. They
                      need to know that they can rely on you to deliver their
                      cargo in the right way, safely, on time and in accordance
                      with all applicable rules and&nbsp;regulations.
                    </p>
                    <p>
                      Make this high level of quality part of your reputation
                      and your identity. Build this identity into your rate
                      negotiations so that it becomes clear to your clients
                      precisely what kind of value you offer. As your reputation
                      grows over time, these rate negotiation services will grow
                      even more important, scaling alongside your business.
                    </p>
                    <h3
                      className="wp-block-heading"
                      id="expand-the-services-you-offer-with-consulting-rates"
                    >
                      Expand the services you offer with consulting rates
                    </h3>
                    <p>
                      On the surface, freight rate negotiations might seem
                      relatively simple. You have a truck and an average rate
                      per mile for you as the truck driver, or perhaps an hourly
                      rate. You also have the type of cargo being transported,
                      and any other concerns or considerations that may come
                      along with this. These elements can be factored into your
                      negotiations.
                    </p>
                    <p>
                      But there is more to freight and logistics than this.
                      Clients may require your expert insight into shipping and
                      other aspects of logistics and may require your
                      consultation. Consultation rate negotiations can provide
                      an additional source of revenue for your business, as well
                      as building your authority in the field.
                    </p>
                    <h2>
                      Get in Touch with SM Logistics and Achieve the Right Truck
                      Rate for Your Services
                    </h2>
                    <p>
                      Here at SM Logistics, we are all about securing the right
                      truck driver hourly rate and so much more. Are you ready
                      to get started? It all begins when you reach out to us for
                      a quote.
                    </p>
                    <p>
                      Use the contact information below to get in touch with our
                      skilled and friendly team. You can reach out via email or
                      telephone, and we’ll take it from there.
                    </p>
                    <p>
                      Let us take care of rate negotiations and more for your
                      trucking operation. We’ll let you take care of the
                      driving.
                    </p>
                  </Col>
                </Row>
              </Container>
              <Container fluid>
                <Row>
                  <Col lg={12}>
                    <WhoCanWork />
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col lg={12}>
                    <Faq page="truck-dispatch" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="contact-links">
                      <Link to="/contact-us">Contact Us</Link>
                      <Link to="tel:7076469911">(707) 646-9911</Link>
                      <Link to="mailto:smlogistics.us@gmail.com">
                        smlogistics.us@gmail.com
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
    </Fragment>
  );
};

export default RateNegotiation;
