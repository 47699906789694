import React, { useEffect, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/images/factoring-banner.png";
import document1 from "../../../assets/images/service-factory1.jpg";
import document2 from "../../../assets/images/service-factory2.jpg";

import ContactForm from "../../../components/contact-form/contact-form.component";
import Faq from "../../faq/faq.component";
import WhoCanWork from "../../../components/home/who-can-work/who-can-work.component";

import "./factoring-service.style.scss";

const FactoringService = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const changeHandler = (path) => {
    navigate(path);
  };
  return (
    <Fragment>
      <section className="factoring-service services-common">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <div className="banner">
                <img src={img} alt="box-truck" />
              </div>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col lg={12} className="p-0">
            <div className="content">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="heading">
                      <h1>Factoring Service</h1>
                    </div>
                    <div className="points-wrapper">
                      <ul>
                        <li>Assistance in selecting a reliable partner</li>
                        <li>Finding factoring partner according to your needs</li>
                        <li>
                        Expert support to all our clients
                        </li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <ContactForm />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="">
                    <div className="truck-content mt-5 mb-3">
                      <h2>Better options of Factoring Services for Trucking Companies</h2>
                      <p className="my-4">Operating a business, particularly a small or startup, comes with its own set of challenges. Maintaining a
                        reliable and steady cash flow is crucial, as it can be the deciding factor between success and struggle.
                        Unpaid invoices can complicate matters, especially when those funds are essential for payroll or rent.</p>

                        <p>At SM Logistics, we understand the importance of a seamless cash flow for the smooth functioning of your
                            business. That's why we've partnered with professional factoring companies to offer our clients specialized
                            factoring services that can truly transform the way they conduct business.</p>

                        <p>Our collaboration with reputable factoring companies ensures that your unpaid invoices don't hinder your
                            operations. With our factoring services, you can depend on a consistent cash flow, giving you the financial
                            stability needed to navigate the challenges of running a business.</p>

                        <p>Choose SM Logistics for a reliable partnership that goes beyond traditional logistics solutions. We're here to
                        help you maintain a steady course in the dynamic world of business.</p>
                        <h2 className="my-4">What is freight factoring?</h2>
                        <p>SM Logistics offers a premier Truck Factoring Service, also recognized as freight factoring, empowering carriers and owner-operators to swiftly convert outstanding invoices into immediate cash. Our dedicated team at SM Logistics acts as a third-party freight factoring partner, seamlessly purchasing your unpaid accounts receivable at an advantageous advance rate, typically ranging from 80% to 90% of their total value. By taking this strategic approach, we ensure that your business maintains a steady cash flow while we take charge of collecting on those unpaid invoices. Trust SM Logistics for efficient and reliable truck factoring solutions, allowing you to focus on what you do best – delivering excellence on the road.</p>
                        <h2 className="my-4">Why You Should Consider a Truck Factoring Service</h2>
                        <p>At SM Logistics, we understand the significance of mitigating risks and ensuring a steady cash flow for trucking companies. Our Truck Factoring Service goes beyond mere financial transactions; it's a strategic partnership that relieves you of the uncertainties associated with non-payment. By entrusting your unpaid invoices to SM Logistics, you empower your trucking business to operate with confidence, as we absorb the risks and provide you with immediate funds.</p>
                        <p>Our freight factoring specialists for trucking companies are dedicated to offering you a hassle-free experience. Even with a discounted amount for your invoices, the benefits far outweigh the costs, allowing you to promptly address overhead expenses and seize new opportunities for increased revenue. Choose SM Logistics for a reliable and efficient freight factoring solution that allows you to focus on the road ahead, knowing that your financial stability is in capable hands.</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="services my-5">
                      <h4 className="my-4">Choose Your Truck</h4>
                      <ul>
                        <li onClick={() => changeHandler("/trucks/power-only")}>
                          <Link to="">Power Only</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/hot-shot")}>
                          <Link to="">Hotshot</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/box-truck")}>
                          <Link to="">Box Truck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/flatbed")}>
                          <Link to="">FlatBed</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/reefer")}>
                          <Link to="">Reefer</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/step-deck")}>
                          <Link to="">Step Deck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/dry-van")}>
                          <Link to="">Dry Van</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <img src={document1} alt="document" className="w-100 my-3" />
                    <h2 className="my-4">The Benefits of Factoring for Trucking Companies</h2>
                    <p>
                        We’ve touched on a few of the benefits of factoring for a trucking company,
                        but let’s break these down in more detail. It’s not unusual for drivers to
                        feel a little reluctant to part with a portion of their profits, and this is
                        a necessary fact of working with a factoring service for the trucking
                        industry. However, the small amount of lost profit can actually lead to
                        serious advantages for businesses and drivers and can even see you
                        increasing your profits over the long term.
                    </p>
                    <ul>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        Swift capital upfront
                        </li>
                    </ul>
                    <p>
                        There can often be a lag between taking on a job and then getting paid for
                        it. While this is not always a problem — for example, while business is good
                        and while company revenue is high — it can cause serious disadvantages for
                        drivers in leaner times. It’s also not fair, as the truck driver has held up
                        their end of the deal and has still not been paid.
                    </p>
                    <p>
                        With factoring, there is no such concern. Money is paid upfront by the
                        third-party freight factoring or trucking factoring company, and the truck
                        driver receives their capital up swiftly and without delay.
                    </p>
                    <ul>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        A more reliable source of revenue
                        </li>
                    </ul>
                    <p>
                        Forecasting and projecting for the future can be difficult for truck
                        drivers. Even when orders are coming in, it is likely that many of the
                        payments on these orders will be delayed, meaning that drivers cannot
                        predict precisely when they will receive the money that is owed to them.
                        This is a major issue, particularly when it comes to securing loans or
                        investment for business growth projects. To put it simply, a lack of
                        reliability makes it hard for truck drivers and owner-operators to grow
                        their business in the way they’d like.
                    </p>
                    <p>
                        With a truck factoring service, this uncertainty is removed. When taking on
                        factored orders and jobs, drivers are securing a reliable stream of income.
                        They can then use this reliable income stream to prove their annual revenue,
                        which makes it easier to attract investors, qualify for loans, and expand
                        the scope and capability of the business.
                    </p>
                    <ul>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        Better flexibility solutions in terms of income
                        </li>
                    </ul>
                    <p>
                        Some drivers may not like the idea of accepting a factored order. After all,
                        when accepting this kind of order, the driver can only expect to receive
                        around 80% or 90% of the total cost of the order, which may seem unfair as
                        it is the driver who is doing the actual driving. However, the idea of
                        factoring services for a trucking company or driver is that they accept this
                        discounted rate so that they can connect with a more reliable payment.
                    </p>
                    <p>
                        This gives you flexibility as a truck driver or owner-operator. You can
                        accept factored orders that provide you with a baseline of income — a
                        regular stream of revenue that you can put your faith in. Then, you can
                        enhance this revenue by taking on other orders at full payment. As your
                        situation changes and you become more or less risk-averse, you may decide to
                        shift the ratio one way or another — the choice is yours, thanks to this
                        increased flexibility.
                    </p>
                    <ul>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        Reduced risk as a better service
                        </li>
                    </ul>
                    <p>
                        An unreliable payment schedule is one thing, but the risk of not getting
                        paid at all is an even more serious concern for truck drivers. Disputes with
                        clients, unscrupulous customers, and a whole host of other factors can
                        contribute to the risk of non-payment. For small trucking businesses or for
                        truck driver owner-operators, this can be catastrophic. Too many businesses
                        have run into serious trouble because they cannot secure the payments they
                        are entitled to.
                    </p>
                    <p>
                        This is obviously a major benefit for truck drivers. With a truck factoring
                        service, the risk of non-payment is taken on by someone else. You do not
                        need to worry about receiving the money due to you, and you can focus on
                        other — more positive — aspects of running your business.
                    </p>
                    <h2 className="wp-block-heading" id="how-trucking-factoring-works">
                        How Trucking Factoring Works
                    </h2>
                    <p>
                        The general process you encounter with trucking factoring is as follows:
                    </p>
                    <ul>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        You invoice your client for work done.
                        </li>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        You assign unpaid invoices to a truck factoring service. If you are doing
                        contract factoring (high volume), you are assigning all your unpaid
                        invoices to the trucking factoring company. If you are going by way of
                        spot factoring for trucking (low volume), you decide which invoices you
                        will assign for truck factoring services.
                        </li>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        The truck factoring company pays you the agreed-upon advance. Once
                        everything is set up, this can be as soon as within 24 hours.
                        </li>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        Your client pays the truck factoring service — it is now the
                        responsibility of the factoring company to chase down clients for payment.
                        </li>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        Once the invoice is paid, the factoring company will take its fee cut and
                        then send you the balance.
                        </li>
                    </ul>
                    <img src={document2} alt="documen2" className="w-100 my-5" />
                    <h2
                        className="wp-block-heading"
                        id="which-is-best-for-you-recourse-or-non-recourse-factoring"
                    >
                        Which is best for you: recourse or non-recourse factoring?
                    </h2>
                    <p>
                        Your agreement for truck factoring services can be a recourse agreement or a
                        non-recourse agreement.
                    </p>
                    <p>
                        If you have a recourse agreement and your customers do NOT pay their
                        invoices, you are required to buy them back. The trucking factoring company
                        may even charge additional fees.&nbsp;
                    </p>
                    <p>
                        If you have a non-recourse agreement, once the factoring company is assigned
                        the unpaid invoices, they belong to the factoring company. If the clients
                        don’t pay, it is no longer your problem. As a consequence, the rates charged
                        by factoring companies tend to be higher for non-recourse agreements.
                    </p>
                    <h2 className="wp-block-heading" id="factoring-for-trucking-pros-and-cons">
                        Factoring for Trucking: Pros and Cons
                    </h2>
                    <p>
                        As with any important business decision, whether you decide to use a truck
                        factoring service depends on your business model, scale of operations, and
                        how much risk you want to assume.
                    </p>
                    <h2 className="wp-block-heading" id="types-of-freight-companies-who-factor">
                        Types of freight companies who factor
                    </h2>
                    <p>
                        Your agreement for truck factoring services can be a recourse agreement or a
                        non-recourse agreement. Some of the pros of truck factoring include:
                    </p>
                    <ul>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        Few qualification requirements – This type of invoice factoring revolves
                        around how creditworthy your clients are, so you won’t have many
                        qualification requirements.
                        </li>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        Almost immediate funding – Instead of waiting for the invoice due date to
                        come and go before receiving the monies owing to you, you can get most of
                        the sum upfront through the factoring company.
                        </li>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        The more your business grows, the more financing you can get – This is
                        because the value of the invoices you assign determines the amount of your
                        advance. So, as you get more established, your advances can become larger.
                        </li>
                    </ul>
                    <p>Some of the cons of hiring a trucking factoring company include:</p>
                    <ul>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        It can be expensive – In exchange for getting funds in advance through the
                        trucking factoring service, you lose a portion of the sum owed to you
                        (that is, the factoring company’s discount rate).
                        </li>
                        <li data-rocket-lazy-bg-a996afd2-a422-4b84-adc7-82e8fa43b56c="loaded">
                        It can end up costing more if your client doesn’t pay the invoice – If you
                        have a recourse factoring agreement, you are obligated to buy back the
                        invoice if the customer doesn’t pay, and you will likely have to pay a fee
                        to the factoring company.
                        </li>
                    </ul>
                    <h2
                    >
                        Why Choose SM Logistics Factoring Services for Your Trucking Company
                    </h2>
                    <p>
                        There are many factors that lead drivers to choose SM Logistics and the
                        factoring companies we deal with. We’ve compiled a few of the most important
                        factors for you to help you make your choice and learn more about what makes
                        us the best in the business.
                    </p>
                    <h3
                        className="wp-block-heading"
                        id="we-are-focused-on-protecting-our-clients-profits"
                    >
                        We are focused on protecting our clients’ profits
                    </h3>
                    <p>
                        Here at SM Logistics, we have always been all about our clients and their
                        profits. We help our clients get the very best from truck factoring services
                        and enjoy the benefits that these services offer. You can rely on us to
                        select a trusted factoring partner to put you first.
                    </p>
                    <h3
                        className="wp-block-heading"
                        id="we-have-a-strong-track-record-of-success"
                    >
                        We have a strong track record of success
                    </h3>
                    <p>
                        We already have a large number of clients who turn to us for help in finding
                        quality freight factoring services. These clients are taking advantage of
                        the potential that freight factoring offers and are expanding their
                        business, increasing flexibility and planning for the future in the process.
                        We suggest you take advantage of this family.
                    </p>
                    <h3
                        className="wp-block-heading"
                        id="we-understand-the-trucking-industry-and-all-that-it-entails"
                    >
                        We understand the trucking industry and all that it entails
                    </h3>
                    <p>
                        You need to know that you are working with a team that really understands
                        your field, your industry, and what you do. Here at SM Logistics, we are
                        proud to be that team. We know this industry inside out, and we are also
                        experts in selecting the ideal factoring partner for your business, helping
                        you get the best from this. You can trust us to be on your side and in your
                        corner.
                    </p>
                    <h3 className="wp-block-heading" id="trucking-invoice-factoring-made-easy">
                        Trucking invoice factoring made easy
                    </h3>
                    <p>
                        We are proud to be working alongside professional factoring companies. They
                        give the means required to offer highly effective trucking factoring
                        services to our clients and connect them with all kinds of advantages.
                        Together we are committed to helping you and your business achieve more when
                        you are out on the road.
                    </p>
                    <h3
                        className="wp-block-heading"
                        id="we-provide-expert-support-to-all-our-clients"
                    >
                        We provide expert support to all our clients
                    </h3>
                    <p>
                        The best services are always backed up by the best support. We are serious
                        about this at SM Logistics, and we are always on hand to provide guidance
                        and assistance and answer any questions you might have regarding our
                        service. You can reach out to us at any time, and we will be there for you.
                    </p>
                    <h2
                        className="wp-block-heading"
                        id="learn-more-about-our-truck-factoring-services-or-get-started-today"
                    >
                        Learn More About Our Truck Factoring Services or Get Started Today
                    </h2>
                    <p>
                        For many trucking companies, the ability to retain factoring services helps
                        keep them in business and maintain a healthy cash flow so their businesses
                        can grow.&nbsp; We help our clients to find a reliable partner that provides
                        the quality factoring services they need to move forward.
                    </p>
                    
                  </Col>
                </Row>
              </Container>
              <Container fluid>
                <Row>
                  <Col lg={12}>
                    <WhoCanWork />
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col lg={12}>
                    <Faq page="truck-dispatch" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="contact-links">
                      <Link to="/contact-us">Contact Us</Link>
                      <Link to="tel:7076469911">(707) 646-9911</Link>
                      <Link to="mailto:smlogistics.us@gmail.com">
                        smlogistics.us@gmail.com
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
    </Fragment>
  );
};

export default FactoringService;
