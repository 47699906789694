import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap"

import img from "../../assets/images/contact-banner-light.png"

import GetStarted from '../../components/home/get-started/get-started.component'

import "./contact-us.style.scss"

function ContactUs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='contact-us'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='contact-banner'>
                <img src={img} alt="banner-img" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='contact-content'>
                <GetStarted page="contact-us" />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default ContactUs
