import React, { useEffect, Fragment } from 'react'
import { Col, Row, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import img from "../../../assets/images/truck-types/truck-header.png"
import boxtruck from "../../../assets/images/truck-types/box-stuck.png"

import ContactForm from '../../../components/contact-form/contact-form.component'

import "./box-truck.style.scss"

const BoxTruck = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <Fragment>
            <section className='box-truck truck-common'>
                <Container fluid>
                    <Row>
                        <Col lg={12} className='p-0'>
                            <div className='banner'>
                                <img src={img} alt="box-truck" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col lg={12} className='p-0'>
                            <div className='content'>
                                <Container>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='heading'>
                                                <h1>Box Truck</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <div className='points-wrapper'>
                                                <ul>
                                                    <li>No forced dispatch and hidden payments</li>
                                                    <li>Personal dispatcher with 24 / 7 support</li>
                                                    <li>Only 10% of gross</li>
                                                </ul>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <ContactForm />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6} className='pr-0 my-5'>
                                            <div className='truck-img'>
                                                <img src={boxtruck} alt="boxtruck" width="100%" />
                                            </div>

                                        </Col>
                                        <Col lg={6} className='pl-0 my-5'>
                                            <div className='truck-details'>
                                                <h2>Box Truck</h2>
                                                <p>SM Logistics, a box trucking business, thrives on the flexibility and freedom to execute loads efficiently without the constraints of obtaining a commercial driver’s license. The keys to our success lie in our adeptness at identifying the most lucrative loads, skillful coordination of trips, and a seamless management of the perpetual paperwork. For many small and medium-sized box truck businesses, this balancing act may seem unending with limited returns. However, for SM Logistics, the introduction of SM Logistics box truck dispatch service stands out as one of the most strategic decisions contributing to the enhancement of our bottom line.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12} className=''>
                                            <hr />
                                            <div className='truck-content'>
                                                <h2>Box Truck Dispatching</h2>
                                                <p>Numerous box truck businesses have shared concerns about the trustworthiness of box truck dispatch services, highlighting the challenge of determining if they are receiving honest and accurate information. Some have recounted instances of feeling misled by a dispatch service's pitch, only to discover continued struggles in their operations. Additionally, there have been cases where businesses unknowingly committed to accepting any offered load, leading to unforeseen challenges. It is precisely these industry challenges that drive businesses like SM Logistics to place their confidence in SM Logistics. Our reliance on SM Logistics is grounded in its reputation for transparency and reliability, ensuring that truckers associated with SM Logistics receive straightforward and trustworthy support.</p>
                                                <h5 className='mt-5'>Saving You Time, Money, and Hassle</h5>
                                                <p>
                                                At SM Logistics, our clients experience maximum benefits through our all-encompassing suite of services, covering dispatch, document management, IFTA, Safety and DOT compliance, Broker and Shipper Communications, and Online TMS. However, at SM Logistics, we place trust in our clients' expertise to discern their specific needs. You have the flexibility to engage us for a comprehensive service package, select a few specific offerings, or even opt for just one service—empowering you to operate your business according to your preferences.
                                                </p>
                                                <p>
                                                Certainly, at SM Logistics, we value understanding your unique business requirements. Our team is eager to familiarize ourselves with your operations and collaborate with you to determine the most effective ways we can support your goals. Together, we can elevate your productivity and enhance profitability, facilitating the growth and expansion of your box truck business, one strategically negotiated load at a time.
                                                </p>
                                            </div>
                                            <hr />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                           
                                            <div className='services'>
                                                <h4 className='my-4'>Choose our services</h4>
                                                <ul>
                                                    <li><Link to="">Truck Dispatching</Link></li>
                                                    <li><Link to="">Billing</Link></li>
                                                    <li><Link to="">Document Management</Link></li>
                                                    <li><Link to="">Factoring Service</Link></li>
                                                    <li><Link to="">Rate Negotiation</Link></li>
                                                    <li><Link to="">Trucking Invoice Service</Link></li>
                                                </ul>
                                            </div>
                                            
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='contact-links'>
                                                <Link to="/contact-us">Contact Us</Link>
                                                <Link to="tel:7076469911">(707) 646-9911</Link>
                                                <Link to="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</Link>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default BoxTruck