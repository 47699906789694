import React, { useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import PulseLoader from 'react-spinners/PulseLoader';
import { Link } from "react-router-dom"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

import './referral-form.style.scss';

const override = {
	display: 'block',
	margin: '0 auto',
	borderColor: 'red',
};

const ReferralForm = () => {
	const [loading, setLoading] = useState(false);
	const [color, setColor] = useState('#ffffff');
	const [emailResponse, setEmailResponse] = useState(null);
	const form = useRef();
	const formik = useFormik({
		initialValues: {
			yourName: '',
			yourEmail: '',
			invitesName: '',
			invitesPhone: '',
			invitesEmail: '',
			terms: false, // Change the default value to false
		},
		validationSchema: Yup.object({
			yourName: Yup.string().label('Your Name').required('Required'),
			yourEmail: Yup.string()
				.email('Email must be a valid email')
				.required('Required'),
			invitesName: Yup.string().label("Invitee's Name").required('Required'),
			invitesPhone: Yup.number().required('Required'),
			invitesEmail: Yup.string()
				.email('Email must be a valid email')
				.required('Required'),
			terms: Yup.boolean()
				.oneOf([true], 'You must accept the Terms and Conditions to proceed') // Validate that it's true
				.required('You must accept the Terms and Conditions to proceed'), // Also add a required validation
		}),
		onSubmit: function (values, { resetForm }) {
			setLoading(true);
			emailjs
				.sendForm(
					'service_ncg7lcx',
					'template_litlc39',
					form.current,
					'leKa87PO2IJcDQlhA'
				)
				.then(
					(result) => {
						setLoading(false);
						setEmailResponse(result);
						resetForm();
					},
					(error) => {
						setLoading(false);
						setEmailResponse(error);
						resetForm();
					}
				);

			setTimeout(() => {
				setEmailResponse(null);
			}, 10000)
		},
	});

	return (
		<div className="referral-form-wrapper">
			<h5>Fill out the form to refer someone</h5>
			<Form onSubmit={formik.handleSubmit} role="form" ref={form}>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Your Name</Form.Label>
					<Form.Control
						type="text"
						name="yourName"
						placeholder="Your Name"
						className={`${formik.touched.yourName && formik.errors.yourName ? 'border-red' : null
							}`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.yourName}
					/>
					{formik.touched.yourName && formik.errors.yourName && (
						<span className="text-red">{formik.errors.yourName}</span>
					)}
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Your Email</Form.Label>
					<Form.Control
						type="text"
						name="yourEmail"
						placeholder="Your Email"
						className={`${formik.touched.yourEmail && formik.errors.yourEmail ? 'border-red' : null
							}`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.yourEmail}
					/>
					{formik.touched.yourEmail && formik.errors.yourEmail && (
						<span className="text-red">{formik.errors.yourEmail}</span>
					)}
				</Form.Group>
				<Form.Group className="mb-3" controlId="formBasicEmail">
					<Form.Label>Invitee's Name</Form.Label>
					<Form.Control
						type="text"
						name="invitesName"
						placeholder="Invitee's Name"
						className={`${formik.touched.invitesName && formik.errors.invitesName ? 'border-red' : null
							}`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.invitesName}
					/>
					{formik.touched.invitesName && formik.errors.invitesName && (
						<span className="text-red">{formik.errors.invitesName}</span>
					)}
				</Form.Group>

				<Form.Group className="mb-3" controlId="formBasicPassword">
					<Form.Label>Invitee's Phone Number</Form.Label>
					<Form.Control
						type="text"
						name="invitesPhone"
						placeholder="Invitee's Phone Number"
						className={`${formik.touched.invitesPhone && formik.errors.invitesPhone ? 'border-red' : null
							}`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.invitesPhone}
					/>
					{formik.touched.invitesPhone && formik.errors.invitesPhone && (
						<span className="text-red">{formik.errors.invitesPhone}</span>
					)}
				</Form.Group>

				<Form.Group className="mb-3" controlId="formBasicPassword">
					<Form.Label>Invitee's Email</Form.Label>
					<Form.Control
						type="text"
						name="invitesEmail"
						placeholder="Invitee's Email"
						className={`${formik.touched.invitesEmail && formik.errors.invitesEmail ? 'border-red' : null
							}`}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.invitesEmail}
					/>
					{formik.touched.invitesEmail && formik.errors.invitesEmail && (
						<span className="text-red">{formik.errors.invitesEmail}</span>
					)}
				</Form.Group>

				<Form.Group className="mb-3" controlId="formBasicCheckbox">
					<Form.Check
						type="checkbox"
						name="terms"
						label={
							<span>
								I have read and agree to 
								{' '} <Link to="/term-of-service">Terms of Service</Link>
								{' '} and 
								{' '} <Link to="/privacy-and-policy">Privay & Policy</Link>
							</span>
						}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values.terms}
					/>
					{formik.touched.terms && formik.errors.terms && (
						<span className="text-red">{formik.errors.terms}</span>
					)}
				</Form.Group>
				{emailResponse != null && emailResponse.status == 200 ? (
					<Alert key="success" variant="success">
						<span style={{ marginRight: '10px' }}>
							<FontAwesomeIcon icon={faCircleCheck} />
						</span>
						Email has been sent successfully. We'll get in touch soon!
					</Alert>
				) : null}

				<Button
					variant="primary"
					type="submit"
					disabled={loading ? true : false}
				>
					{loading ? (
						<PulseLoader
							color={color}
							loading={loading}
							cssOverride={override}
							size={10}
							aria-label="Loading Spinner"
							data-testid="loader"
						/>
					) : (
						'Send Invite'
					)}
				</Button>
			</Form>
		</div>
	);
};

export default ReferralForm;
