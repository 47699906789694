import React, { useEffect, Fragment } from 'react'
import { Col, Row, Container } from "react-bootstrap"
import img from "../../../assets/images/career-header.png"
import "./careers.style.scss"

const Careers = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <Fragment>
            <section className='careers'>
                <Container fluid>
                    <Row>
                        <Col lg={12} className='p-0'>
                            <div className='banner'>
                                <img src={img} alt="box-truck" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col lg={12} className='p-0'>
                            <div className='content'>
                                <Container>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='heading'>
                                                <h1>Careers</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='content-details'>
                                                <h2>Truck Driving Jobs for Owner-Operators</h2>
                                                <p>If you're on the hunt for lucrative truck driver opportunities across America, here's exciting news: SMLogistics is actively recruiting professionals like you. Whether you're seeking owner-operator truck driver positions or you're a part-time line hauler eager for additional loads, elevate your business potential by teaming up with SMLogistics Dispatch. We're dedicated to maximizing your success on the road.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='content-details'>
                                                <h2>What Types of Jobs for Truck Drivers?</h2>
                                                <p>At SMLogistics, we offer a variety of truck driving opportunities tailored for owner-operators. Our team at SMLogistics Dispatch is committed to securing the most lucrative loads for you, aligning with your unique operations and business objectives. With five years of dedicated industry experience, SMLogistics Dispatch has cultivated strong connections across different search platforms, enabling us to handpick the best loads available.</p>
                                                <p>But our commitment doesn't end there. Our proficient and seasoned dispatchers go the extra mile, negotiating rigorously to secure the best rates for every load. This strategic approach ensures that you earn more money per load, enhancing your overall profitability. With increased earnings in your pocket, SMLogistics empowers you to make impactful business decisions and achieve a stronger bottom line for your enterprise.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='content-details'>
                                                <h2>You’re in Good Company with Our Driver Hiring</h2>
                                                <p>At SMLogistics, we believe that anyone, regardless of experience in truck driving or running a trucking business, can achieve success in the transportation industry. If you're considering venturing into this field, now is an opportune moment to join forces with us, a company specializing in driver hiring. By collaborating with qualified and seasoned dispatchers from SMLogistics, even beginners and veteran truckers can access well-paying truck driving jobs.</p>
                                                <p>Contrary to common perceptions, the cost of working with our professional and proficient dispatchers may surprise you. In many instances, it proves to be more cost-effective than hiring your own full-time dispatch personnel. The expenses associated with renting space and investing time and resources in the recruitment process are significant considerations. However, by partnering with SMLogistics Dispatch, you not only save on these costs but also gain automatic access to a vast network of resources and benefit from the expertise of professional and dedicated dispatchers. Seize the opportunity to leverage our industry knowledge and comprehensive services for your </p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='content-details'>
                                                <h2>Why Are You Hiring Truck Drivers?</h2>
                                                <p>At SMLogistics, our mission is simple: to assist trucking business owners/operators in maximizing their earnings. While we may use the term "hiring," our commitment goes beyond offering typical truck driving jobs for owner-operators. We aim to provide comprehensive support, and reaching out to you is just one way SMLogistics Dispatch distinguishes itself from the competition.</p>
                                                <p>We understand that many truck drivers have encountered challenges and developed a sense of distrust, especially through online searches like "dispatch companies near me" or other truck driver job postings. Some have felt pressured to accept every load assigned to them, even when it wasn't the right fit. Others experienced disappointment after initially being promised unrealistic expectations by dispatch companies. At SMLogistics Dispatch, we prioritize transparency and realism. We believe in being honest about what we can deliver, rather than making promises we can't keep. Our approach sets us apart, ensuring that our partnerships are built on trust and reliability.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='content-details'>
                                                <h2>What You Get with Our Jobs</h2>
                                                <p>When SMLogistics offers truck driver jobs for owner-operators, it includes the unparalleled administrative and business support that sets SMLogistics Dispatch apart. What makes it even more appealing is our commitment to respecting your autonomy as a business owner. We tailor our services to your unique strengths and areas of improvement. Many of our truck drivers have developed their own strategies, like negotiating their loads, but seek assistance with managing paperwork and documentation. SMLogistics Dispatch is here to provide targeted support in those specific areas.</p>
                                                <p>On the flip side, some owner-operators prefer to focus solely on what they do best: driving. They thrive on being on the road, delivering loads punctually across the country. The administrative aspects, such as paperwork, negotiations, and compliance, can be overwhelming and hinder efficiency. In such cases, SMLogistics Dispatch steps in with comprehensive dispatch services, enabling them to handle more loads and truly optimize their operations. We are delighted to collaborate with diverse trucking and transport businesses, offering spot assistance, full assistance, and everything in between. SMLogistics Dispatch ensures that you don't have to work harder; you'll be working smarter and better, maximizing your efficiency on the road.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='content-details'>
                                                <h2>Still Wondering About Our Jobs?</h2>
                                                <p>Got questions? Curious about how SMLogistics would handle the specifics of your business and its objectives? Eager to learn more about how we can help you work smarter and better to boost your earnings? Concerned about whether partnering with us is the right move for your business? No problem. We'd be delighted to have a conversation with you. Our goal is to understand your business, your aspirations, and where you could truly benefit from our services.</p>
                                                <p>At SMLogistics, we don't just talk the talk – we walk the walk when it comes to providing straightforward information. We encourage you to ask your questions and share any concerns you've had with other dispatch services in the past. Trust is crucial in this partnership, and we're committed to earning it. Our dedication to building trust is evident in every dispatch load we handle for our truck drivers, every day.</p>
                                                <p>Connect with us today – you'll quickly discover why so many owner-operators rely on SMLogistics to manage their documentation, find and negotiate loads, and much more.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default Careers