import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt, faEnvelope, faLocationDot  } from '@fortawesome/free-solid-svg-icons'

import footer_bg from '../../assets/images/footer-bg.png';
import footer_logo from '../../assets/images/header-logo.png';

import './footer.style.scss';

function Footer() {
	return (
		<footer style={{ backgroundImage: `url(${footer_bg})` }}>
      <Container>
        <Row>
          <Col lg={12}>
            <div className="footer-content" >
              {/* <p className="text-center">
                © {new Date().getFullYear()}. All rights reserved by{' '}
                <Link to="/">SM Logistics LLC</Link>.
              </p> */}
              <Row>
                <Col lg={3}>
                  <div className='block-1'>
                    <Link to="/"><img src={footer_logo} alt="footer-logo"/></Link>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className='block-2'>
                    <h4>Links</h4>
                    <ul>
                      <li><Link to="/about">About us</Link></li>
                      <li><Link to="/contact-us">Contact us</Link></li>
                      <li><Link to="/services">Services & Pricing</Link></li>
                      <li><Link to="/truck-types">Truck types</Link></li>
                      {/* <li><Link to="/terms">Terms of service</Link></li> */}
                    </ul>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className='block-3'>
                    <ul>
                      <li><Link to="/faq">FAQ</Link></li>
                      <li><Link to="/how-to-start">How to start</Link></li>
                      {/* <li><Link to="/services">Testimonials</Link></li>
                      <li><Link to="/services">Blogs</Link></li>
                      <li><Link to="/services">Privacy policy</Link></li> */}
                    </ul>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className='block-4'>
                    <h4>Contacts</h4>
                    <div className='mobile'>
                      <div className='icon'><FontAwesomeIcon icon={faMobileAlt} /></div>
                      <div className='label'><span>(707) 646-9911</span></div>
                    </div>
                    <div className='message'>
                      <div className='icon'><FontAwesomeIcon icon={faEnvelope} /></div>
                      <div className='label'><span>smlogistics.us@gmail.com</span></div>
                    </div>
                    <div className='location'>
                      <div className='icon'><FontAwesomeIcon icon={faLocationDot} /></div>
                      <div className='label'>
                        <span>SM LOGISTICS LLC
                          30 N GOULD ST, STE R, SHERIDAN, WYOMING 82801
                        </span>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
		</footer>
	);
}

export default Footer;
