import React, { useEffect, Fragment } from 'react'
import { Col, Row, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import img from "../../../assets/images/truck-types/truck-header.png"
import stepdeck from "../../../assets/images/truck-types/step-desk.png"

import ContactForm from '../../../components/contact-form/contact-form.component'

import "./step-deck.style.scss"

const StepDeck = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='step-deck truck-common'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='banner'>
                <img src={img} alt="step-deck" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='content'>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className='heading'>
                        <h1>Step Deck</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className='points-wrapper'>
                        <ul>
                          <li>No forced dispatch and hidden payments</li>
                          <li>Personal dispatcher with 24 / 7 support</li>
                          <li>Only 5% of gross</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <ContactForm />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0 my-5'>
                      <div className='truck-img'>
                        <img src={stepdeck} alt="boxtruck" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0 my-5'>
                      <div className='truck-details'>
                        <h2>Step Deck</h2>

                        <p>
                          In the realm of step deck (or "drop deck") trailer hauling, it's a "go big
                          or go home" scenario. Transporting oversize and over-height freight is a
                          substantial responsibility, requiring the navigation of organizational and
                          logistical challenges to keep your firm solvent.
                        </p>
                        <p>
                          If a significant portion of your day is consumed by the search for and
                          evaluation of potential step deck loads, negotiating prices and freight
                          rates, handling paperwork, and ensuring compliance with regulations, it
                          becomes challenging to focus on the crucial task of safely and punctually
                          delivering large step-deck or flatbed loads. This is precisely where SM
                          Logistics Dispatch comes into play.
                        </p>
                        <p>
                          At SM Logistics, we specialize in alleviating the burdens associated with
                          step deck hauling. Our team takes charge of the time-consuming tasks,
                          allowing you to concentrate on the safe and timely delivery of your valuable
                          cargo. From load acquisition and negotiation to meticulous paperwork
                          management, SM Logistics is your dedicated partner in navigating the
                          complexities of step deck transportation. Drive with confidence, knowing
                          that SM Logistics is committed to streamlining your operations and ensuring
                          the success of your step deck hauling endeavors.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className=''>
                      <hr />
                      <div className='truck-content'>
                        <h2>Steps Up the Step Deck Truck Dispatch</h2>
                        <p>
                          At SM Logistics, our mission since our establishment in 2015 has been to
                          empower our clients in the trucking business, enabling them to earn more
                          money. Through our dedicated step deck dispatch services, encompassing
                          contract negotiations, thorough searches, timely interfacing, and expert
                          document management, we've consistently worked towards helping our clients
                          achieve their business goals.
                        </p>
                        <p>
                          Transparency and open communication are at the core of our approach. We
                          engage with our clients honestly, providing insights into market realities
                          and setting realistic expectations. Unlike coercive approaches, we never
                          push clients to accept step deck freight they don't want. Our focus is not
                          to manage your company but to assist you in running it more effectively.
                        </p>
                        <p>
                          SM Logistics offers step deck dispatch services that not only cost less than
                          anticipated but also save you significant time, allowing you to complete
                          more trips efficiently. In many cases, our services prove to be more
                          cost-effective than hiring in-house, full-time dispatchers. The savings on
                          office leases and hiring expenses can be redirected to reduce other costs
                          and enhance your bottom line.
                        </p>
                        <p>
                          As specialists in step deck trucking transportation, SM Logistics stands as
                          one of the most reliable partners among step deck trucking companies
                          offering dispatching services. Our step deck load board ensures you have
                          access to a comprehensive listing of available freight, guaranteeing that
                          you find the right loads to meet your trucking needs. Partner with SM
                          Logistics for unparalleled support in optimizing your step deck trucking
                          operations.
                        </p>
                        <h2>Honest Step Deck Support</h2>
                        <p>
                          At SM Logistics, we recognize the critical importance of efficient
                          dispatching for step decks, and that's why we offer dependable and timely
                          dispatch services for step deck shipments. Our dedicated team works
                          diligently to align your trucking capabilities with suitable step deck
                          freight load boards, maximizing your earning potential in the competitive
                          market.
                        </p>
                        <p>
                          If you're in search of step deck truck driving jobs, look no further. SM
                          Logistics, as a reliable partner for step deck trucking companies and
                          owner-operators, provides rewarding opportunities for professional drivers.
                          Whether you're an experienced step deck driver or embarking on a new career,
                          we are here to assist you in finding the right trucking job that aligns with
                          your skills and preferences.
                        </p>
                        <p>
                          At SM Logistics, transparency is a cornerstone of our philosophy. We believe
                          in delivering real-world information to our clients without making empty
                          promises or embellishing our services. When you choose to partner with us,
                          expect honest and reliable support that aligns with your business goals.
                        </p>
                        <p>
                          Contact SM Logistics today, either by calling or reaching out online, and
                          discover how we can collaborate to elevate your step deck trucking business.
                          Experience the difference of working with a partner committed to your
                          success and dedicated to providing the support you need to thrive in the
                          competitive landscape of step deck transportation.
                        </p>
                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>

                      <div className='services'>
                        <h4 className='my-4'>Choose our services</h4>
                        <ul>
                          <li><Link to="">Truck Dispatching</Link></li>
                          <li><Link to="">Billing</Link></li>
                          <li><Link to="">Document Management</Link></li>
                          <li><Link to="">Factoring Service</Link></li>
                          <li><Link to="">Rate Negotiation</Link></li>
                          <li><Link to="">Trucking Invoice Service</Link></li>
                        </ul>
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='contact-links'>
                        <Link to="/contact-us">Contact Us</Link>
                        <Link to="tel:7076469911">(707) 646-9911</Link>
                        <Link to="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default StepDeck