import React, { Fragment, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "./privacy-and-policy.style.scss";

const TermOfService = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <Fragment>
      <section className="privacy-and-policy">
        <Container>
          <Row>
            <Col lg={12} className="p-0">
              <div className="heading">
                <i>
                  <u>
                    <h6 className="text-center">PRIVACY POLICY</h6>
                  </u>
                </i>
              </div>
              <div className="content my-5">
                <ol>
                  <li>
                    <h6>
                      <strong>Introduction</strong>
                    </h6>
                    <p>
                      Thank you for choosing SM Logistics LLC. This Privacy
                      Policy is designed to inform you about the collection,
                      usage, disclosure, and protection of your personal
                      information. Your use of our services implies your
                      acceptance of the terms outlined in this Privacy Policy
                    </p>
                    <ul>
                      <li>
                        <p>
                          By providing a telephone number and submitting the
                          form you are consenting to be contacted by SMS text
                          message. Message & data rates may apply. Reply to STOP
                          to opt out of further messaging.
                        </p>
                      </li>
                    </ul>
                    <p>
                      <strong>
                        Do we share your information with third parties?
                      </strong>
                    </p>
                    <p>
                      <u>
                          We do not sell, trade, or rent your information like
                          Contact number to any third parties for advertising
                          purpose
                      </u>
                    </p>
                    <p>
                      No mobile information be shared with third
                      parties/affiliates for marketing/promotional purposes. All
                      other categories exclude text messaging originator opt-in
                      data and consent; this information will be shared with any
                      third parties.
                    </p>
                  </li>
                  <li>
                    <h6>
                      <strong>Information We Collect</strong>
                    </h6>
                    <p>
                      As part of our dispatching services at SM Logistics LLC,
                      we collect specific information to efficiently manage and
                      fulfill your dispatch needs. This information includes:
                    </p>
                    <ul>
                      <li>
                        <p>
                          Order Details: When you place an order for our
                          dispatch services, we collect relevant details such as
                          pickup and delivery locations, item descriptions, and
                          any special instructions provided
                        </p>
                      </li>
                      <li>
                        <p>
                          Customer Contact Information: We gather contact
                          details, including names and phone numbers, to
                          facilitate communication regarding dispatch updates,
                          delivery status, and any issues that may arise during
                          the process
                        </p>
                      </li>
                      <li>
                        <p>
                          Route and Timing Information: To optimize dispatch
                          operations, we may collect data related to the
                          preferred routes, delivery schedules, and time
                          constraints specified by our customers
                        </p>
                      </li>
                      <li>
                        <p>
                          Tracking Information: In order to provide real-time
                          updates on dispatch status, we may utilize tracking
                          technologies to monitor the movement of dispatched
                          items.
                        </p>
                      </li>
                      <li>
                        <p>
                          Rest assured, we handle all collected information with
                          the utmost care and in compliance with the terms
                          outlined in this Privacy Policy. By utilizing our
                          dispatching services, you implicitly agree to the
                          collection and processing of the mentioned information
                          for dispatch-related purposes.
                        </p>
                      </li>
                    </ul>
                  </li>

                  <li>
                    <h6>
                      <strong>How We Use Your Information:</strong>
                    </h6>
                    <p>
                      At SM Logistics LLC, we use the collected information for
                      the following purpose
                    </p>
                    <ul>
                      <li>
                        <p>
                          Providing Dispatch Services: Managing and assigning
                          loads to truck drivers
                        </p>
                      </li>
                      <li>
                        <p>
                          Communication: Distributing updates, notifications,
                          and addressing inquiries.
                        </p>
                      </li>
                      <li>
                        <p>
                          Service Enhancement: Examining data to improve the
                          efficiency and quality of our dispatch services.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6>
                      <strong>Information Sharing</strong>
                    </h6>
                    <p>
                      SM Logistics LLC does not engage in the sale, rental, or
                      trading of your personal information to third parties.
                    </p>
                    <ul>
                      <li>
                        <p>
                          Service Providers: Trusted partners assist in our
                          operational processes
                        </p>
                      </li>
                      <li>
                        <p>
                          Legal Compliance: In case where it is mandated by law
                          or to safeguard our rights.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6>
                      <strong>Your Choice:</strong>
                    </h6>
                    <ul>
                      <li>
                        <p>
                          Access and Update: Review and rectify your personal
                          information.
                        </p>
                      </li>
                      <li>
                        <p>
                          Opt-out: Choose to unsubscribe from non-essential
                          communications.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6>
                      <strong>Security Measure:</strong>
                    </h6>
                    <ul>
                      <li>
                        <p>
                          SM Logistics implement industry-standard security
                          measures to protect your information from unauthorized
                          access, disclosure, or alteration.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6>
                      <strong>Cookies and Similar Technologies</strong>
                    </h6>
                    <ul>
                      <li>
                        <p>
                          SM Logistics LLC may use cookies to analytics and
                          functionality. You can manage cookie preferences
                          through your browser settings.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6>
                      <strong>Changes to this Privacy Policy</strong>
                    </h6>
                    <ul>
                      <li>
                        <p>
                          SM Logistics LLC reserves the right to update this
                          Privacy Policy periodically. Any modifications will be
                          prominently displayed on our website along with the
                          revised effective date. It is advisable to review this
                          policy regularly to stay informed about how we
                          collect, use, and protect your information.
                        </p>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <h6>
                      <strong>Contact Us</strong>
                    </h6>
                    <ul>
                      <li>
                        <p>
                          If you have any questions or concerns about our
                          privacy policy, Please contact us by phone or email
                          which is mention at the end of this privacy policy.
                        </p>
                      </li>
                    </ul>
                  </li>
                </ol>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default TermOfService;
