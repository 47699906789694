import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import img_1 from "../../../assets/images/truck-types/truck-type-1.png";
import img_2 from "../../../assets/images/truck-types/truck-type-2.png";
import img_3 from "../../../assets/images/truck-types/truck-type-3.png";
import img_4 from "../../../assets/images/truck-types/truck-type-4.png";
import img_5 from "../../../assets/images/truck-types/truck-type-5.png";
import img_6 from "../../../assets/images/truck-types/truck-type-6.png";
import img_7 from "../../../assets/images/truck-types/truck-type-7.png";

import "./who-can-work.style.scss";

const WhoCanWork = () => {
  const navigate = useNavigate();

  const changeHandler = (path) => {
    navigate(path);
  };

  return (
    <section className="who-can-work">
      <Container fluid>
        <Row>
          <Col lg={12}>
            <div className="content-wrapper">
              <Container>
                <Row>
                  <Col lg={12}>
                    <div className="started-heading-wrapper">
                      <h1>Who can work with us</h1>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="truck-types-wrapper">
                      <Row>
                        <Col lg={3} className="truck-block">
                          <a
                            className="type-link"
                            style={{ backgroundImage: `url(${img_1})` }}
                            onClick={() => changeHandler("/trucks/dry-van")}
                          >
                            <h4>Dry Van</h4>
                          </a>
                        </Col>
                        <Col lg={3} className="truck-block">
                          <a
                            className="type-link"
                            style={{ backgroundImage: `url(${img_2})` }}
                            onClick={() => changeHandler("/trucks/step-deck")}
                          >
                            <h4>Step Desk</h4>
                          </a>
                        </Col>
                        <Col lg={3} className="truck-block">
                          <a
                            className="type-link"
                            style={{ backgroundImage: `url(${img_3})` }}
                            onClick={() => changeHandler("/trucks/reefer")}
                          >
                            <h4>Reefer</h4>
                          </a>
                        </Col>
                        <Col lg={3} className="truck-block">
                          <a
                            className="type-link"
                            style={{ backgroundImage: `url(${img_4})` }}
                            onClick={() => changeHandler("/trucks/flatbed")}
                          >
                            <h4>Flatbed</h4>
                          </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} className="truck-block">
                          <a
                            className="type-link"
                            style={{ backgroundImage: `url(${img_5})` }}
                            onClick={() => changeHandler("/trucks/power-only")}
                          >
                            <h4>Power Only</h4>
                          </a>
                        </Col>
                        <Col lg={4} className="truck-block">
                          <a
                            className="type-link"
                            style={{ backgroundImage: `url(${img_6})` }}
                            onClick={() => changeHandler("/trucks/hot-shot")}
                          >
                            <h4>Hotshot</h4>
                          </a>
                        </Col>
                        <Col lg={4} className="truck-block">
                          <a
                            className="type-link"
                            style={{ backgroundImage: `url(${img_7})` }}
                            onClick={() => changeHandler("/trucks/box-truck")}
                          >
                            <h4>Boxtruck</h4>
                          </a>
                        </Col>
                        <Col lg={3} className="truck-block"></Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WhoCanWork;
