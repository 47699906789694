import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

import "./accordion.style.scss"

const AccordionComponent = () => {
    return (
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>What will SM Logistics services do for my business?</Accordion.Header>
                <Accordion.Body>
                    <p>Very simply, we buy you time so you can make more money. Most owners/operators get into this business to drive loads and make money, then find themselves bogged down by all the paperwork, regulatory requirements, and negotiations — things that keep you from doing what actually makes you money. By taking on all the business support activities you would like to offload, SM Logistics allows your business to run efficiently — and for you to take on more loads. In addition, when it comes to negotiating, we aim to get you the best rates for your loads, so you get to put more money in your pocket.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>Do I have to enter into a long-term contract with SM Logistics?</Accordion.Header>
                <Accordion.Body>
                    <p>Nope. You hire SM Logistics on a month-to-month basis. We don't make trucker drivers commit long-term upfront because we believe in earning your business. If you aren't happy, we aren't happy, so we'll work hard for you. But we also believe that all business owners should have the freedom to work with whomever they want. It's important for us to keep that freedom for you.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>How do I sign up with you, and what do I need? </Accordion.Header>
                <Accordion.Body>
                <p>Signing up with SM Logistics is a breeze since you can submit documents to us online.</p>
                <p>- First, you sign our Dispatch Carrier Agreement, a document that clearly sets out our responsibilities and our responsibilities. This is meant to protect both of us — it is not a long-term service contract by any means. Our services are month-to-month, and you are not penalized for discontinuing our service.</p>
                <p>- You will also need insurance that names SM Logistics as the certificate holder. This is common industry practice for shippers, dispatchers, and brokers — it just allows us to conduct business at your behest and on your behalf when you want us to. Make sure the insurance sets out liability as well as cargo coverage.</p>
                <p>- We also need the Carrier Set-Up Package and references for the shippers and brokers we work with and for our records. Your MC Permit and signed W9 form should be here as well.</p>
                <p>- At SM Logistics, we keep impeccable and accurate records — and always maintain compliance with all safety, regulatory, and industry requirements — so we also need a copy of the CDL for each driver we will be dispatching.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header> Do I have to be working under my own MC authority to hire SM Logistics? </Accordion.Header>
                <Accordion.Body>
                <p>We work with owners/operators working under their own MC authorities — but also with carriers with company drivers.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>Do I have to request insurance certificates every time?  </Accordion.Header>
                <Accordion.Body>
                <p>No. Unless you have specifically instructed your insurance agent to process the certificate requests made by "the insured only" — you — we work with your insurance agent to get the insurance certificates with the certificate holder information. </p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>Will you work with me if I'm new to the business?  </Accordion.Header>
                <Accordion.Body>
                <p>Absolutely. We love helping truck drivers and carriers in all stages of their careers. What we look for are keen, hardworking drivers, period. Realistically, if you are a new carrier, it may initially be harder for us to find you the best loads because most shippers and brokers prefer carriers with MC authorities more than six months to a year old. However, you can rest assured that SM Logistics will give it our all so you can make better money in your first year. Once you hit the one year milestone, access to better rates and loads will be even easier.</p>
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>Can I choose which SM Logistics services I want?</Accordion.Header>
                <Accordion.Body>
                <p>Yes. Many of our clients love our comprehensive service package — which includes dispatch, documentation, compliance, and all the business supports a carrier needs — but we also have clients who, say, just want assistance with documentation and paperwork. At SM Logistics, we believe our clients should have the freedom to do business as they see fit. After all, no one knows their business better than they do.<br />
                    Have any other questions? Please don't hesitate to talk to us. We look forward to helping you grow your trucking business.</p>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
    );
}

export default AccordionComponent;