import React, { useEffect, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/images/services/billing.png";
import billing1 from "../../../assets/images/billing-1.jpg";

import ContactForm from "../../../components/contact-form/contact-form.component";
import GetStarted from "../../../components/home/get-started/get-started.component";
import Faq from "../../faq/faq.component";
import WhoCanWork from "../../../components/home/who-can-work/who-can-work.component";

import "./billing.style.scss";

const Billing = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const changeHandler = (path) => {
    navigate(path);
  };
  return (
    <Fragment>
      <section className="biling services-common">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <div className="banner">
                <img src={img} alt="box-truck" />
              </div>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col lg={12} className="p-0">
            <div className="content">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="heading">
                      <h1>Billing</h1>
                    </div>
                    <div className="points-wrapper">
                      <ul>
                        <li>Invoicing, accounting and billing</li>
                        <li>Personal approach</li>
                        <li>Hands-off process for drivers</li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <ContactForm />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="">
                    <div className="truck-content mb-3 mt-5">
                      <h2>
                      SM Logistics: Empowering America's Road Heroes
                      </h2>

                        <p>
                        At SM Logistics, we recognize the indispensable role that truck drivers play as the unsung heroes of our society, ensuring the seamless flow of key logistics and delivery services that keep America moving. We understand the challenges they face on the road, and that's why we are committed to providing the necessary support and assistance to ensure they can consistently excel in their vital tasks.
                        </p>

                        <h3>Tailored Accounting Services for Truck Drivers</h3>

                        <p>
                        Our specialized accounting services are designed to truly work for truck drivers and owner-operators. We aim to create an environment where drivers feel supported throughout their journeys, gaining the advantages they rightfully deserve when it comes to tax time. By entrusting their financial management to SM Logistics, drivers can focus on what they do best—delivering goods efficiently and safely.
                        </p>

                        <h3>Efficient Billing for a Seamless Client Experience</h3>

                        <p>
                        At SM Logistics, we go beyond accounting to cover every aspect of your business needs. Our comprehensive service includes billing solutions that not only ensure accurate and timely invoicing but also contribute to delivering an exceptional experience to your clients. We understand that a well-managed billing process is essential for client satisfaction and the overall success of your trucking business.
                        </p>

                        <h3>Ready and Able to Fulfill an Important Role on America's Roads</h3>

                        <p>
                        Our commitment is to contribute to the success of your trucking business or freelance operation. By providing reliable accounting and billing services, we empower you to focus on the road ahead, knowing that the financial aspects of your operation are in capable hands. SM Logistics is dedicated to helping you be ready and able to fulfill your important role in keeping America's roads moving.
                        </p>

                        <h2>Contact Us Today for a Tailored Solution</h2>

                        <p>
                        Reach out to our dedicated team at SM Logistics today to discover more about our specialized services or to get a personalized quote. Let us be your partner in success, supporting you every mile of the way. Read on for additional information on what you can expect when you choose SM Logistics for your accounting and billing needs.
                        </p>
                        </div>
                    
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="services my-5">
                      <h4 className="my-4">Choose Your Truck</h4>
                      <ul>
                        <li onClick={() => changeHandler("/trucks/power-only")}>
                          <Link to="">Power Only</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/hot-shot")}>
                          <Link to="">Hotshot</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/box-truck")}>
                          <Link to="">Box Truck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/flatbed")}>
                          <Link to="">FlatBed</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/reefer")}>
                          <Link to="">Reefer</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/step-deck")}>
                          <Link to="">Step Deck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/dry-van")}>
                          <Link to="">Dry Van</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <img src={billing1} alt="billing1" className="img w-100" />
                   
                    <h2 className="wp-block-heading my-4" id="sm-logistics-services">
                        SM Logistics Services: Enhancing Your Trucking Business
                    </h2>
                    <p>
                        Many American truckers and owner-operators are already benefiting from
                        our expert services. Here’s what you can expect when you work with us:
                    </p>
                    <ul>
                        <li>
                        Smooth and reliable trucking accounting services for business
                        </li>
                    </ul>
                    <p>
                        When done right, accounting can save you money and help your business
                        thrive — and this is exactly the kind of service we provide. We adopt
                        an expert approach, removing all confusion and uncertainty from the
                        process so that your business receives the truck driver accounting
                        services it needs. You end up with a trucking business or freelance
                        operation that is lean, mean, and flexible enough to compete with the
                        best out there on the road.
                    </p>
                    <ul>
                        <li>Trucking billing that supports your business</li>
                    </ul>
                    <p>
                        You provide your trucking services, and then you get paid. But there is
                        a missing link in the chain here — billing. Billing helps your clients
                        understand how much they are paying for your services while also
                        ensuring that you get paid the money you are owed. This is a crucial
                        service for all truck drivers, and we are proud to do our part to keep
                        that all-important revenue flowing in.
                    </p>
                    <ul>
                        <li>Trucking invoice for all aspects of your operation</li>
                    </ul>
                    <p>
                        There may be additional services that you provide outside of your
                        standard logistics and delivery work. You may find yourself needing to
                        deliver invoices to your clients in a flexible and reliable manner so
                        that you can foster a strong relationship with your customers while
                        still receiving the money that is due to you. Let us take care of this
                        with our reliable trucking invoicing services.
                    </p>
                    <ul>
                        <li>A hands-off process for drivers</li>
                    </ul>
                    <p>
                        We are the accounting, billing, and invoicing professionals. We know
                        this industry, and we are proud to deliver the right kind of service to
                        the drivers who work with us and rely on us. But what does this mean? We
                        take care of everything and ensure that drivers do not need to worry or
                        stress about anything relating to accounting or billing. It’s a
                        completely hands-off process for our drivers.
                    </p>
                    <ul>
                        <li>A close and personal service</li>
                    </ul>
                    <p>
                        We know that freelance truckers and owner-operators have unique needs
                        and expectations. For this reason, we work closely with you to
                        understand exactly what you require, and then put our expertise to good
                        use as we meet and exceed those requirements.
                    </p>

                    <h2 className="wp-block-heading" id="benefits-of-sm-logistics-services">
                        The Benefits of SM Logistics Services
                    </h2>
                    <p>
                        Why do so many truckers opt to use our accounting services? Here are a
                        few of the benefits we bring to the table:
                    </p>
                    <ul>
                        <li>
                        Formidable expertise in accounting specifically for the logistics and
                        haulage industry
                        </li>
                        <li>
                        The financial foundation that will help your business to grow and
                        expand in the future
                        </li>
                        <li>
                        Personnel that you can rely on, and who you know have your best
                        interests at heart
                        </li>
                        <li>
                        Full compliance with all tax laws and regulations, so that there are
                        no nasty surprises at tax time
                        </li>
                        <li>
                        Only pay the tax required of you, leaving your business strong and
                        fighting fit
                        </li>
                        <li>
                        A stress-free process with no input required from you after the
                        initial consultation
                        </li>
                        <li>
                        A solid set of accounts that will instill trust in your clients
                        </li>
                        <li>
                        Transparent prices that help you to plan your operations with ease
                        and effectiveness
                        </li>
                    </ul>
                    <p>
                        If you are ready to connect with all of these benefits and experience
                        the advantages of expert accounting for trucking industry
                        professionals, reach out to SM Logistics. We provide centralized
                        services from our headquarters in Wilmington, Delaware, but serve
                        truckers right across the country. Use the contact information below to
                        reach us by phone or email and get a quote for our services.
                    </p>
                    <p>
                        Truck driver accounting doesn’t need to be confusing. With SM
                        Logistics, you just drive while we handle the rest.
                    </p>
                    <p />
                  </Col>
                </Row>
              </Container>
              <Container fluid>
                <Row>
                  <Col lg={12}>
                    <WhoCanWork />
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col lg={12}>
                    <Faq page="truck-dispatch" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="contact-links">
                      <Link to="/contact-us">Contact Us</Link>
                      <Link to="tel:7076469911">(707) 646-9911</Link>
                      <Link to="mailto:smlogistics.us@gmail.com">
                        smlogistics.us@gmail.com
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
    </Fragment>
  );
};

export default Billing;
