import React, { useEffect, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/images/truck-dispatch.png";
import trucks from "../../../assets/images/trucks.jpg";

import ContactForm from "../../../components/contact-form/contact-form.component";
import GetStarted from "../../../components/home/get-started/get-started.component";
import Faq from "../../faq/faq.component";
import WhoCanWork from "../../../components/home/who-can-work/who-can-work.component";

import "./truck-dispatch.style.scss";

const TruckDispatch = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const changeHandler = (path) => {
    navigate(path);
  };
  return (
    <Fragment>
      <section className="truck-dispatch services-common">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <div className="banner">
                <img src={img} alt="box-truck" />
              </div>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col lg={12} className="p-0">
            <div className="content">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="heading">
                      <h1>Truck Disptach</h1>
                    </div>
                    <div className="points-wrapper">
                      <ul>
                        <li>No forced dispatch and hidden payments</li>
                        <li>Personal dispatcher with 24 / 7 support</li>
                        <li>Only 5% of gross</li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <ContactForm />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="">
                    <div className="truck-content mt-5 mb-3">
                      <h2>
                        Truck Dispatching: Keeping Your Business Moving So You
                        Can Focus on Driving
                      </h2>
                      <p className="mt-4">
                        we are proud to be the truck dispatcher company you can
                        rely on.
                      </p>
                      <p>
                        Your truck driving and SM Logistics business needs to be
                        supported by the right truck driver dispatcher services.
                        This is where SM Logistics can make all of the
                        difference.
                      </p>
                      <p>
                        We provide these services to trucking operators across
                        the country. This means you can continue providing your
                        critical trucking services, supporting our society and
                        our economy with your logistics.
                      </p>
                      <p>
                        Get in touch with us by email or by telephone to get
                        started. Our team is on hand to provide you the quote
                        you need for your business, and to help you learn more
                        about working with SM Logistics.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="services my-5">
                      <h4 className="my-4">Choose Your Truck</h4>
                      <ul>
                        <li onClick={() => changeHandler("/trucks/power-only")}>
                          <Link to="">Power Only</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/hot-shot")}>
                          <Link to="">Hotshot</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/box-truck")}>
                          <Link to="">Box Truck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/flatbed")}>
                          <Link to="">FlatBed</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/reefer")}>
                          <Link to="">Reefer</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/step-deck")}>
                          <Link to="">Step Deck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/dry-van")}>
                          <Link to="">Dry Van</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <img src={trucks} alt="trucks" className="w-100" />
                    <h2 className="my-4">
                      What to Expect from Our Truck Dispatch Services
                    </h2>
                    <p>
                      You need to be able to rely on regular dispatch and route
                      organization services. With this in mind, we have crafted
                      a<br />
                      suite of dispatch services, supported by the innovations
                      that American truck drivers are looking for. Let us be
                      your
                      <br />
                      trusted operators so that you can get on with what you do
                      best.
                    </p>
                    <p>Here’s what you can expect when you work with us:</p>
                    <ul>
                      <li>
                          Swift and clear truck dispatch to support your
                          business{" "}
                        
                      </li>
                    </ul>
                    <p>
                      Increasingly, logistics relies upon swift turnarounds and
                      real-time communication. Our licensed dispatchers can
                      provide
                      <br />
                      this and then some, giving you the kind of foundation you
                      need to get the job done in the right way. You will enjoy
                      <br />
                      working with our team who works to iron out frustrations
                      and to get you on the way sooner rather than later.
                      <br />
                      <br />
                      Contented clients who are over the moon with your service
                      <br />
                      <br />
                      Your responsibilities to your client base are crucial to
                      your success as a trucker or owner operator. You want to
                      keep
                      <br />
                      these clients happy, going above and beyond their
                      expectations in terms of pickup and delivery speed and
                      quality. We
                      <br />
                      recognize this, so we work to help you build that
                      reputation in the right way.
                    </p>
                    <ul>
                      <li>
                        A reliable stream of revenue 
                      </li>
                    </ul>
                    <p>
                      Reliable dispatching means reliable jobs, and reliable
                      jobs mean reliable revenue. Build your business and keep
                      that
                      <br />
                      income flowing in with truck dispatching services that
                      suit your needs and those of your clients. Get the peace
                      of mind
                      <br />
                      you need to run your trucking operation without the
                      stress.
                    </p>
                    <ul>
                      <li>
                          The opportunity to scale and grow your business
                      </li>
                    </ul>
                    <p>
                      Dispatching helps you to get the best from your business
                      both in the short and long term. You will quickly be able
                      to
                      <br />
                      ascertain the demand for your services and plan your
                      scaling and growth accordingly. You will also be able to
                      recognize
                      <br />
                      untapped opportunities and other areas for development.
                      <br />A hassle-free process every step of the way
                      <br />
                      Tasks such as truck driver dispatch and administration
                      tend to get in the way of the actual driving and delivery
                      parts
                      <br />
                      of your operation. However, this does not need to be the
                      case. We deliver you a hands-off, hassle-free experience
                      with
                      <br />
                      minimized stress and anxiety. This means you can focus on
                      your driving and on supplying the very best service
                      possible
                      <br />
                      without any additional worry.
                    </p>
                    <ul>
                      <li>
                          The right relationship for you and your business
                      </li>
                    </ul>
                    <p>
                      We don’t believe in treating our clients just as names and
                      numbers on a shipping manifest. Instead, we understand
                      that
                      <br />
                      the needs of our clients are unique, so we work to get to
                      know those needs. This allows us to provide the right kind
                      of
                      <br />
                      service to you, so you can be assured that we have your
                      best interests at heart.
                    </p>
                    <ul>
                      <li>
                        Transparent pricing that suits you
                      </li>
                    </ul>
                    <p>
                      Our team is committed to helping you and your operation.
                      This is why our truck freight dispatch service includes
                      <br />
                      transparent pricing that you can believe in. This means
                      you can plan your trucking operations without worrying
                      about how
                      <br />
                      much our services will cost you.
                    </p>
                    <h2>Get Started with SM Logistics Today</h2>
                    <p>
                      Reach out to our team today and get a quote or discover
                      more about how our dispatch services go above and beyond
                      your requirements. Use the contact details below and get
                      in touch with our team by phone or email.
                      <br />
                      <br />
                      We are all about taking care of details such as truck
                      dispatching. This means you just need to focus on driving
                      and delivery.
                    </p>
                  </Col>
                </Row>
              </Container>
              <Container fluid>
                <Row>
                  <Col lg={12}>
                    <WhoCanWork />
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col lg={12}>
                    <Faq page="truck-dispatch" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="contact-links">
                      <Link to="/contact-us">Contact Us</Link>
                      <Link to="tel:7076469911">(707) 646-9911</Link>
                      <Link to="mailto:smlogistics.us@gmail.com">
                        smlogistics.us@gmail.com
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
    </Fragment>
  );
};

export default TruckDispatch;
