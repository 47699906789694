import React, { useEffect, Fragment } from 'react'
import { Col, Row, Container } from "react-bootstrap"
import img from "../../../assets/images/testimonials-header.png"
import cardImg from "../../../assets/images/testimonial.jpg"
import truck1 from "../../../assets/images/truck-types/truck-type-1.png"
import "./testimonial.style.scss"

const Testimonials = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <Fragment>
            <section className='testimonial'>
                <Container fluid>
                    <Row>
                        <Col lg={12} className='p-0'>
                            <div className='banner'>
                                <img src={img} alt="testimonial" />
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Col lg={12} className='p-0'>
                            <div className='content'>
                                <Container>
                                    <Row>
                                        <Col lg={12}>
                                            <div className='heading'>
                                                <h1>Testimonials</h1>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg={6}>
                                            <div className='testimonial-card'>
                                                <Row>
                                                    <Col lg={5} className='pr-0'>
                                                        <div className='card-img'>
                                                            <img src={cardImg} alt="cardImg" width="100"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={7} className='pl-0'>
                                                        <div className='card-content'>
                                                            <h3>Sohn Oconner</h3>
                                                            <p className='truck-mc mb-3'>M&L Transport LLC, MC # 1560208</p>
                                                            <p className='details'>SM Logistics has been exceptional for my trucking business. Their communication is outstanding, and their industry knowledge is top-notch. They handle dispatching so efficiently that I haven't needed anyone else since. What's more, their pricing is incredibly reasonable, far better than companies charging over 10 percent. Thanks, SM Logistics!</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg={6}>
                                            <div className='testimonial-card'>
                                                <Row>
                                                    <Col lg={5} className='pr-0'>
                                                        <div className='card-img'>
                                                            <img src={cardImg} alt="cardImg" width="100"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={7} className='pl-0'>
                                                        <div className='card-content'>
                                                            <h3>Junior Kesseh</h3>
                                                            <p className='truck-mc mb-3'>M&L Transport LLC, MC # 1560208</p>
                                                            <p className='details'>SM Logistics has been a game-changer for my trucking business. Their communication is exceptional, and their industry expertise is second to none. They've taken care of our dispatching needs so effectively that we haven't had to look elsewhere. Plus, their pricing is incredibly reasonable, much better than other companies charging high percentages. Thank you, SM Logistics!</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                    <Col lg={6}>
                                            <div className='testimonial-card'>
                                                <Row>
                                                    <Col lg={5} className='pr-0'>
                                                        <div className='card-img'>
                                                            <img src={cardImg} alt="cardImg" width="100"/>
                                                        </div>
                                                    </Col>
                                                    <Col lg={7} className='pl-0'>
                                                        <div className='card-content'>
                                                            <h3>Anthony Dennard</h3>
                                                            <p className='truck-mc mb-3'>M&L Transport LLC, MC # 1567421</p>
                                                            <p className='details'>Entrusting our trucking business to SM Logistics has been a game-changer. Their communication is top-notch, and their industry expertise is unmatched. We've had no need for another dispatcher since. Their pricing is fair, much better than companies charging high percentages. Thanks, SM Logistics!</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        
                                    </Row>
                                </Container>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default Testimonials