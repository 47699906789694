import React, { useEffect, Fragment } from 'react'
import { Col, Row, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import img from "../../../assets/images/truck-types/truck-header.png"
import poweronly from "../../../assets/images/truck-types/power-only.png"

import ContactForm from '../../../components/contact-form/contact-form.component'

import "./power-only.style.scss"

const PowerOnly = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='power-only truck-common'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='banner'>
                <img src={img} alt="power-only" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='content'>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className='heading'>
                        <h1>Power Only</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className='points-wrapper'>
                        <ul>
                          <li>No forced dispatch and hidden payments</li>
                          <li>Personal dispatcher with 24 / 7 support</li>
                          <li>Only 5% of gross</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <ContactForm />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0 my-5'>
                      <div className='truck-img'>
                        <img src={poweronly} alt="poweronly" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0 my-5'>
                      <div className='truck-details'>
                        <h2>Power Only</h2>
                        <p>SM Logistics has been at the forefront of providing exceptional power-only dispatch services to owner-operators and truck fleets. Our dedicated team simplifies the process of matching power-only loads with independent owners and truck fleets, ensuring seamless transportation for equipment on various trailers, including flatbed, dry van, refrigerated van, shipping container, and tanker trailers. At SM Logistics, we excel in finding the optimal power-only truck solutions tailored to meet the unique demands of any transport situation. Trust us to deliver reliability and efficiency in every shipment.</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className=''>
                      <hr />
                      <div className='truck-content'>
                        <h2>Benefits of Power Only</h2>

                        <p>
                          At SM Logistics, we specialize in power-only trucking services,
                          revolutionizing the transport of trailers through the use of independent
                          driver or carrier tractors. Our approach offers a cost-effective solution,
                          empowering brokers to seamlessly secure end-to-end logistics for various
                          loads. By choosing our power-only dispatch service, you unlock a range of
                          benefits:
                        </p>
                        <ol>
                          <li>
                            <p>
                              <strong>Cost Efficiency:</strong> Our power-only trucking services are
                              designed to be cost-effective, providing a smart solution for brokers
                              seeking efficient logistics without compromising on quality.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Seamless Logistics:</strong> We pride ourselves on delivering
                              end-to-end logistics solutions. With our power-only dispatch service,
                              you can trust us to handle the entire process seamlessly, ensuring your
                              loads reach their destination smoothly.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Flexibility:</strong> SM Logistics offers flexibility to adapt
                              to the diverse needs of our clients. Whether it's a flatbed trailer, dry
                              van trailer, refrigerated van, shipping container, or tanker trailer, we
                              tailor our power-only trucking services to your specific requirements.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Reliable Partnerships:</strong> We prioritize building reliable
                              partnerships with independent drivers and carrier tractors, ensuring a
                              consistent and dependable service for your logistics needs.
                            </p>
                          </li>
                        </ol>
                        <p>
                          Choose SM Logistics for your power-only trucking requirements and experience
                          a new level of efficiency and cost-effectiveness in the transportation of
                          trailers.
                        </p>


                        <h2 className='mt-5'>Power Only Dispatch – Help Finding Loads</h2>
                        <p>
                          At SM Logistics, we understand the value of keeping independent truck
                          drivers on the move. Our dedicated dispatchers excel in connecting drivers
                          with shippers in need of power-only trucking services, ensuring a continuous
                          and efficient workflow. Here's why independent truck drivers never have to
                          sit idle again with SM Logistics:
                        </p>
                        <ol>
                          <li>
                            <p>
                              <strong>Strategic Matchmaking:</strong> Our skilled dispatchers
                              meticulously match drivers with shippers, considering compatibility in
                              services and pricing expectations. This strategic approach ensures a
                              harmonious partnership that benefits both parties.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Flexibility and Autonomy:</strong> We prioritize the autonomy of
                              independent truck drivers. With SM Logistics, drivers have the right to
                              decline loads and choose preferred routes, putting them in control of
                              their schedules and maximizing their efficiency.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Alignment of Pricing Expectations:</strong> We understand that
                              fair pricing is crucial for both drivers and shippers. SM Logistics
                              ensures that drivers are matched with shippers whose pricing
                              expectations align, fostering a transparent and mutually beneficial
                              relationship.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Efficient Dispatch Team:</strong> The SM Logistics dispatch team
                              is dedicated to working tirelessly so that drivers can focus on what
                              they do best – drive. Our commitment is to streamline the process,
                              making it seamless and hassle-free for all parties involved.
                            </p>
                          </li>
                        </ol>
                        <p>
                          Experience a new era of empowerment with SM Logistics, where independent
                          truck drivers thrive and never face idle moments. We're here to support you
                          on the road to success.
                        </p>
                        <h2 className='mt-5'>Negotiating with Customers</h2>
                        <p>
                          We recognize that nobody enjoys the hassle of haggling. However, we understand the importance of securing the best load rates for owner-operators of power-only trucks. Our commitment is clear: better load rates mean more money in drivers' pockets and a significant boost to their bottom lines
                        </p>
                        <h2 className='mt-5'>Power Only Trucking Billing Assistance</h2>
                        <p>
                          We recognize that few things are more time-consuming than the process of finding your own loads and handling invoicing. That's why, when you partner with SM Logistics for power-only trucking management, owner-operators can leave invoicing concerns behind. Our mission is clear: we're here to make your job easier.
                        </p>
                        <h2 className='mt-5'>Document Management and Paperwork</h2>
                        <p>
                          We understand that nobody likes the burden of paperwork and managing the myriad documents involved in successful load shipping. Whether you're a shipper or an owner-operator, SM Logistics steps in to handle the intricacies of all things paperwork on your behalf. Our commitment is to alleviate the burden so you can place your focus where it matters most.
                        </p>
                        <h2 className='mt-5'>Power Only Trucking with SM Logistics</h2>
                        <p>
                          SM Logistics is here to provide top-notch power only trucking dispatch services to owner-operators, truck drivers, and small-to-medium trucking companies with their own fleets. It is our mission and vision to provide trustworthy dispatch services so drivers and businesses can save time and money on their power only trucking needs. Give our team a call at (302) 425-9299 to discuss your options today.
                        </p>

                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>

                      <div className='services'>
                        <h4 className='my-4'>Choose our services</h4>
                        <ul>
                          <li><Link to="">Truck Dispatching</Link></li>
                          <li><Link to="">Billing</Link></li>
                          <li><Link to="">Document Management</Link></li>
                          <li><Link to="">Factoring Service</Link></li>
                          <li><Link to="">Rate Negotiation</Link></li>
                          <li><Link to="">Trucking Invoice Service</Link></li>
                        </ul>
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='contact-links'>
                        <Link to="/contact-us">Contact Us</Link>
                        <Link to="tel:7076469911">(707) 646-9911</Link>
                        <Link to="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default PowerOnly