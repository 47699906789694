import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap"

import dryvan from "../../assets/images/truck-types/dry-van.png"
import stepdeck from "../../assets/images/truck-types/step-desk.png"
import reefer from "../../assets/images/truck-types/reefer.png"
import flatebed from "../../assets/images/truck-types/flatebed.png"
import poweronly from "../../assets/images/truck-types/power-only.png"
import hotshot from "../../assets/images/truck-types/hot-shot.png"
import boxtruck from "../../assets/images/truck-types/box-stuck.png"
import straighttruck from "../../assets/images/truck-types/straight-struck.png"

import "./truck-types.style.scss"

function TruckTypes() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='truck-types'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='content-wrapper'>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className='truck-types-heading'>
                        <h1>Truck types</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='details pl-0'>
                        <h4>Power Only</h4>
                        <p>Since 2015, SM Logistics has provided power only dispatch service to owner-operators and truck fleets. Our team makes it easy to match power-only loads to independent owners and truck fleets any time you need to move equipment on a flatbed trailer, dry van trailer, refrigerated van, shipping container, or tanker trailers. We can find the best power only trucks solution to fit any transport situation.</p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='img'>
                        <img src={poweronly} alt="poweronly" width="100%" />
                      </div>
                    </Col>
                  </Row>
                  <Row>

                    <Col lg={6} className='pr-0'>
                      <div className='img'>
                        <img src={hotshot} alt="Hotshot" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='details'>
                        <h4>Hotshot</h4>
                        <p>A lot of people outside the trucking industry have no idea what goes into getting their equipment, appliances, and materials to them. At SM Logistics, we know that hotshot drivers like you are really the "minutemen" of trucking, delivering loads to where they need to be on time. That's why we offer top-notch hotshot dispatch services that can make all the difference when it comes to the bottom line for your hotshot trucking business.                </p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='details pl-0'>
                        <h4>Straight Truck</h4>
                        <p>We offer straight truck dispatch services that meet the needs of logistics operations all over the U.S.</p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='img'>
                        <img src={straighttruck} alt="straighttruck" width="100%" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='img'>
                        <img src={boxtruck} alt="boxtruck" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='details'>
                        <h4>Boxtruck</h4>
                        <p>A box trucking business takes advantage of the versatility and freedom to run loads without much downtime and without needing to obtain a commercial driver's license. </p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='details pl-0'>
                        <h4>Flatbed</h4>
                          <p>
                            Your flatbed truck is a heavy-duty vehicle. As such, it supports our economy in ways that other vehicles simply cannot. <br />
                            Here at SM Logistics, we want to be your partner, providing you with the dispatch services you need to connect you and your flatbed with the clients who will benefit the most.                </p>
                          <a href="/">Read more</a>
                      </div>
                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='img'>
                      <img src={flatebed} alt="flatebed" width="100%" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='img'>
                        <img src={reefer} alt="reefer" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='details'>
                        <h4>Reefer</h4>
                          <p>
                            You and your reefer truck are already providing an excellent service to your customers. But, to continue offering this level of service, you need a dispatcher you can rely on — one with the right attitude to your business and a range of streamlined, stress-free processes that keep your operation moving.                </p>
                          <a href="/">Read more</a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='details pl-0'>
                        <h4>Step Deck</h4>
                        <p>
                          It is a big responsibility to transport oversize and over-height cargo, never mind having to jump through logistical and organizational hoops to keep your business afloat. <br />
                          We got your back on looking for and assessing prospective loads, haggling over prices and rates, generating and completing all the paperwork required by regulations and industry practices.                </p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='img'>
                      <img src={stepdeck} alt="stepdeck" width="100%" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='img'>
                      <img src={dryvan} alt="Dry-Van" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='details'>
                        <h4>Dry Van</h4>
                        <p>
                          Dry vans provide a huge benefit to clients across the United States, keeping their goods and safe and sound while they are out on the roads. To achieve the best for your clients, you need a friendly, honest dry van dispatcher to support your efforts. This is where SM Logistics comes in — we have spent years building great relationships with drivers.                </p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default TruckTypes
