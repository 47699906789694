import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap"

import img from "../../../assets/images/reward-bg-ref.png"
import refBox from "../../../assets/images/ref-box.png"
import headerLogo from "../../../assets/images/header-logo.png"

import ReferralForm from '../../../components/referral-form/referral-form.component'

import "./referral-program.style.scss"

function ReferralProgram() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='referral-program'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='banner'>
                <img src={img} alt="banner-img" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={7} className='p-0'>
              <div className='referral-content'>
                <div className='title'>
                  <h1>Refer a Trucker</h1>
                  <h2>and you get bonous when the<br /> invitee delivers 3 gift loads</h2>
                </div>
                <div className='img'>
                  <img src={refBox} alt="ref-box" />
                  <div className='logo'>
                    <img src={headerLogo} alt="logo" />
                  </div>
                  <h3>3 free loads</h3>
                  <h6>For a Fellow </h6>
                  <h5>Get more $</h5>
                </div>
              </div>
            </Col>
            <Col lg={5} className='p-0'>
              <div className='referral-form'>
                <ReferralForm />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='my-5'>
                <h1 className='mb-4'>How It Works</h1>
                <p>We believe that the best partnerships come from sharing great experiences. That’s why we’re excited to introduce our Referral Program, designed to reward you for spreading the word about our exceptional services.</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='refer-block'>
                <div className='count'><span>1</span></div>
                <div className='details'>
                  <h3>Refer a Trucker:</h3>
                  <p>Have a fellow who's in need of a reliable dispatching solution? Refer them to SM Logistics by simply sharing their contact information with us.</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='refer-block'>
                <div className='count'><span>2</span></div>
                <div className='details'>
                  <h3>Gift for Them:</h3>
                  <p>Your fellow will receive an exclusive gift of 3 trial loads for free when they sign up for our services through your referral.</p>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className='refer-block'>
                <div className='count'><span>3</span></div>
                <div className='details'>
                  <h3>Reward for You:</h3>
                  <p>Once your fellow completes 3 trial loads using SM Logistics, we thank you for your trust and support. As a token of our appreciation</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default ReferralProgram
