import React, { useEffect, Fragment } from 'react'
import { Col, Row, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import img from "../../../assets/images/truck-types/truck-header.png"
import poweronly from "../../../assets/images/truck-types/hot-shot.png"

import ContactForm from '../../../components/contact-form/contact-form.component'

import "./hot-shot.style.scss"

const HotShot = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='hot-shot truck-common'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='banner'>
                <img src={img} alt="hot-shot" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='content'>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className='heading'>
                        <h1>Hotshot</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className='points-wrapper'>
                        <ul>
                          <li>No forced dispatch and hidden payments</li>
                          <li>Personal dispatcher with 24 / 7 support</li>
                          <li>Only 5% of gross</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <ContactForm />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0 my-5'>
                      <div className='truck-img'>
                        <img src={poweronly} alt="poweronly" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0 my-5'>
                      <div className='truck-details'>
                        <h2>Hotshot</h2>
                        <p>We recognize that many individuals outside the trucking industry may not fully grasp the intricate process involved in getting equipment, appliances, and materials to their doorstep. We understand that hotshot drivers play a pivotal role as the "minutemen" of trucking, ensuring timely deliveries where they are needed most. That's why we proudly offer unparalleled hotshot dispatch services, making a significant impact on the bottom line of your hotshot trucking business.</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className=''>
                      <hr />
                      <div className='truck-content'>
                        <h2>Other Hotshot Dispatch Companies</h2>



                        <p>
                          At SM Logistics, our commitment to delivering high-quality dispatch services
                          has been unwavering, extending across the expansive landscape of the
                          trucking sector throughout the United States since our inception in 2015.
                          Our extensive interactions and heart-to-heart discussions with clients have
                          illuminated the primary concerns they harbor about entrusting hotshot
                          dispatch services to professionals rather than managing everything
                          independently. Here's what we've uncovered:
                        </p>
                        <ol>
                          <li>
                            <p>
                              <strong>Eliminating Forced Dispatch Worries:</strong>
                              Many clients fear being subjected to forced dispatch decisions that
                              don't align with their business goals. At SM Logistics, we prioritize
                              open communication and collaboration. Our approach ensures that dispatch
                              decisions are made in consultation with you, respecting your preferences
                              and preserving your control over your operations.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Optimizing Time and Cost Efficiency:</strong>
                              The apprehension of wasted time and financial resources for minimal
                              benefits is a valid concern. SM Logistics is dedicated to maximizing
                              your operational efficiency and minimizing costs. Our dispatch services
                              are designed to provide tangible benefits, ensuring that every resource
                              invested translates into substantial value for your hotshot trucking
                              business.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Realistic Expectations, Not Inflated Promises:</strong>
                              Puffed up expectations often lead to disappointment. At SM Logistics, we
                              believe in transparency and integrity. Our team sets realistic
                              expectations, avoiding exaggerated promises. The success of your
                              business is our priority, and we work diligently to meet and exceed
                              achievable goals.
                            </p>
                          </li>
                        </ol>
                        <p>
                          Partner with SM Logistics for dispatch services that address your concerns
                          head-on, fostering a collaborative and transparent relationship. Our proven
                          track record and client-centric approach make us the reliable choice for
                          navigating the challenges of the trucking sector with confidence.
                        </p>


                        <h2 className='mt-5'>Empowering Your Profitability Through Streamlined Hotshot</h2>


                        <p>
                          At SM Logistics, our approach to hotshot truck dispatching is grounded in a
                          simple yet powerful mission: we exist to help our clients earn more money.
                          Our strategy is clear, aiming to enhance your profitability through a range
                          of dedicated services. Here's how we make a difference:
                        </p>
                        <ol>
                          <li>
                            <p>
                              <strong>Maximizing Load Profitability:</strong>
                              Our team at SM Logistics is dedicated to seeking prospective hotshot
                              loads from various platforms, including those not readily accessible to
                              individuals arranging loads independently. We leverage our expertise to
                              negotiate the highest prices, ensuring that each load is inherently more
                              profitable for your business.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Time and Aggravation Savings:</strong>
                              We understand that time is money in the trucking industry. SM Logistics
                              streamlines the process of load arrangement, saving clients valuable
                              time and sparing them the aggravation of navigating this complex aspect
                              of the business. Our goal is to allow you to focus on what you do best –
                              delivering the goods.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Comprehensive Document Management:</strong>
                              Beyond load arrangement, SM Logistics takes care of all the paperwork
                              and more through our comprehensive trucking document management
                              services. We handle the administrative aspects, leaving you with the
                              peace of mind that your documentation is in capable hands.
                            </p>
                          </li>
                        </ol>
                        <p>
                          At SM Logistics, our commitment is to empower your business by facilitating
                          more profitable loads, saving you time and effort, and managing the
                          essential paperwork seamlessly. Partner with us to experience a new level of
                          efficiency and profitability in your hotshot trucking endeavors. We're here
                          to support your success every step of the way.
                        </p>


                        <h2 className='mt-5'>Empowering Your Hotshot Trucking Business with Strategic Dispatch Services</h2>

                        <p>
                          In the dynamic landscape of hotshot trucking, where overlays, fees, and
                          expenses shape the business, the core of your profitability lies in the art
                          of negotiation and securing lucrative loads. However, with the multitude of
                          responsibilities you juggle, the time and effort required for searching and
                          negotiating can feel overwhelming. Particularly for startups lacking
                          bargaining power and industry know-how, this aspect of the business can be a
                          significant challenge. This is where SM Logistics steps in to make a
                          difference:
                        </p>
                        <ol>
                          <li>
                            <p>
                              <strong>Strategic Rate Negotiation:</strong>
                              At the heart of hotshot trucking profitability is the ability to
                              negotiate rates effectively. SM Logistics brings expertise to the table,
                              ensuring that your rates are not just competitive but optimized for
                              maximum profitability.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Time-Saving Solutions:</strong>
                              Searching and negotiating can be time-consuming, especially when you
                              have numerous other tasks demanding your attention. SM Logistics takes
                              the burden off your shoulders, providing time-saving solutions that
                              allow you to focus on core aspects of your business.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Expertise for Startups:</strong>
                              For startups navigating the complex landscape of hotshot trucking, the
                              learning curve can be steep. SM Logistics offers the expertise needed,
                              guiding and supporting startups to make informed decisions and enhance
                              their bargaining power.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Informed and Strategic Approach:</strong>
                              Our dispatch services for hotshot trucking go beyond mere logistics. We
                              adopt an informed and strategic approach to load arrangement and
                              negotiation, ensuring that your business is positioned for success in
                              the competitive market.
                            </p>
                          </li>
                        </ol>
                        <p>
                          Choose SM Logistics as your partner in hotshot trucking, and let us handle
                          the intricacies of negotiation, allowing you to focus on growing your
                          business and delivering exceptional service. Our commitment is to empower
                          your profitability and streamline your operations for lasting success.
                        </p>


                        <h2 className='mt-5'>Driving Cost Efficiency for Small and Medium Hotshot Trucking Companies</h2>
                        <p>
                          At SM Logistics, we recognize the unique challenges faced by small and medium hotshot trucking companies, and we're committed to providing significant cost savings through our comprehensive services. Choosing SM Logistics as an alternative to in-house, full-time dispatchers brings forth a range of benefits:
                        </p>
                        <ol>
                          <li>Cost-Effective Solution</li>
                          <li>Savings Beyond Salaries</li>
                          <li>Industry Expertise</li>
                          <li>Access to Valuable Resources</li>
                        </ol>

                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>

                      <div className='services'>
                        <h4 className='my-4'>Choose our services</h4>
                        <ul>
                          <li><Link to="">Truck Dispatching</Link></li>
                          <li><Link to="">Billing</Link></li>
                          <li><Link to="">Document Management</Link></li>
                          <li><Link to="">Factoring Service</Link></li>
                          <li><Link to="">Rate Negotiation</Link></li>
                          <li><Link to="">Trucking Invoice Service</Link></li>
                        </ul>
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='contact-links'>
                        <Link to="/contact-us">Contact Us</Link>
                        <Link to="tel:7076469911">(707) 646-9911</Link>
                        <Link to="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default HotShot