import React, { useEffect, Fragment } from "react";
import { Col, Row, Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import img from "../../../assets/images/services/billing.png";
import document1 from "../../../assets/images/services/documents.png";

import ContactForm from "../../../components/contact-form/contact-form.component";
import Faq from "../../faq/faq.component";
import WhoCanWork from "../../../components/home/who-can-work/who-can-work.component";

import "./invoice-service.style.scss";

const InvoiceService = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const changeHandler = (path) => {
    navigate(path);
  };
  return (
    <Fragment>
      <section className="invoice-service services-common">
        <Container fluid>
          <Row>
            <Col lg={12} className="p-0">
              <div className="banner">
                <img src={img} alt="box-truck" />
              </div>
            </Col>
          </Row>
        </Container>
        <Row>
          <Col lg={12} className="p-0">
            <div className="content">
              <Container>
                <Row>
                  <Col lg={6}>
                    <div className="heading">
                      <h1>Invoice Service</h1>
                    </div>
                    <div className="points-wrapper">
                      <ul>
                        <li>Invoicing, accounting and billing</li>
                        <li>Personal approach</li>
                        <li>Hands-off process for drivers</li>
                      </ul>
                    </div>
                  </Col>
                  <Col lg={6}>
                    <ContactForm />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} className="">
                    <div className="truck-content mt-5 mb-3">
                      <h2>Trucking Invoice Services</h2>
                      <p className="my-4">
                        Your trucking services offer great advantages to your
                        clients. They have specific needs related to the
                        transportation and delivery of goods, and you have the
                        skills and equipment required to fulfill those needs.
                        But you need to be supported too – you need the steady
                        stream of revenue that keeps you on the road and your
                        business growing.
                      </p>

                      <p>
                        This is where trucking invoices are so important. You
                        complete your service, bill your customer, and receive
                        your payment. It should be as simple as that.
                      </p>

                      <p>
                        But, invoices for trucking companies are a little more
                        complex than this. Here at SM Logistics, our invoicing
                        services are designed to keep you and your business
                        moving forward while keeping your clients happy at the
                        same time.
                      </p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="services my-5">
                      <h4 className="my-4">Choose Your Truck</h4>
                      <ul>
                        <li onClick={() => changeHandler("/trucks/power-only")}>
                          <Link to="">Power Only</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/hot-shot")}>
                          <Link to="">Hotshot</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/box-truck")}>
                          <Link to="">Box Truck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/flatbed")}>
                          <Link to="">FlatBed</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/reefer")}>
                          <Link to="">Reefer</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/step-deck")}>
                          <Link to="">Step Deck</Link>
                        </li>
                        <li onClick={() => changeHandler("/trucks/dry-van")}>
                          <Link to="">Dry Van</Link>
                        </li>
                      </ul>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <img
                      src={document1}
                      alt="document"
                      className="w-100 my-3"
                    />
                    <h2 className="my-4">
                      The Benefits of Factoring for Trucking Companies
                    </h2>
                    <p>
                      SM Logistics services are designed with trucking companies
                      in mind. We understand how important it is to receive
                      prompt and reliable payment for the services you provide,
                      as this is what keeps your company going. We also
                      recognize how vital it is to have strong relationships
                      with your clients, so we deliver our services with this in
                      mind too.&nbsp;These services include the following key
                      aspects:
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-3a935c81-f28a-4fec-8d64-f9847c98e6f1="loaded">
                        Reliable Billing
                      </li>
                    </ul>
                    <p>
                      SM Logistics handles invoicing for you, offering reliable
                      bills to your trucking clients to ensure all your work is
                      properly accounted for. This forms the basis of your
                      revenue stream and helps you achieve the funds you need to
                      grow your business. With a solid invoicing strategy and a
                      good stream of revenue, you will find it much easier to
                      plan for future growth.
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-3a935c81-f28a-4fec-8d64-f9847c98e6f1="loaded">
                        Ongoing Management
                      </li>
                    </ul>
                    <p>
                      Unfortunately, bills are not always paid on time. Invoice
                      management services help you follow up on unpaid bills and
                      invoices, taking the stress out of management. This also
                      helps you recoup money owed for trucking
                      services&nbsp;sooner rather than later while maintaining a
                      positive relationship with clients.
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-3a935c81-f28a-4fec-8d64-f9847c98e6f1="loaded">
                        Flexible Services that Meet Client Needs
                      </li>
                    </ul>
                    <p>
                      Different clients will have different requirements when it
                      comes to invoicing. For your more long-term clients, you
                      may need to break out of your regular trucking company
                      invoice schedule and provide something a little more
                      flexible. This can be difficult to achieve when you are
                      concentrating on driving and logistics, which is why SM
                      Logistics handles this for you. Foster strong connections
                      between your business and your clients without the
                      additional hassle.
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-3a935c81-f28a-4fec-8d64-f9847c98e6f1="loaded">
                        Tax Planning for Your Business
                      </li>
                    </ul>
                    <p>
                      Going it alone as a truck driver or trucking
                      company&nbsp;owner-operator means bearing the tax burden
                      that comes with this. Invoicing is a big part of this, and
                      it is this that will form the basis of your tax strategy.
                      However, you didn’t get into truck driving because of your
                      love of tax planning and invoicing. So, let the expert
                      team at SM Logistics handle all of this for you.
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-3a935c81-f28a-4fec-8d64-f9847c98e6f1="loaded">
                        Integration with Other SM Logistics Services
                      </li>
                    </ul>
                    <p>
                      The team here at SM Logistics are experts in the trucking
                      industry, and we have comprehensive experience giving the
                      best solutions to our clients. Our solutions exist both as
                      standalone services&nbsp;– for example, if you simply need
                      some help with your invoicing&nbsp;– and as integrated
                      components of a wider array of offerings. If you need a
                      more complete&nbsp;set of services, including trucking
                      invoice services, we can provide this to you. This way,
                      you can focus on doing what you do best.
                    </p>
                    <h2>
                      Choosing SM Logistics as Your Trucking Company Invoice
                      Service Providers
                    </h2>
                    <p>
                      Increasing numbers of drivers are turning to SM Logistics
                      for trucking invoice services. Here are a few reasons why:
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-3a935c81-f28a-4fec-8d64-f9847c98e6f1="loaded">
                        Expertise and Experience in the Industry
                      </li>
                    </ul>
                    <p>
                      The team at SM Logistics has years of&nbsp;experience and
                      long-standing expertise in the trucking&nbsp;industry.
                      This means you can rely on us as your truck invoicing
                      partner. Working with industry experts gives you the peace
                      of mind you need to keep on providing the very best for
                      your clients&nbsp;– we are proud to be those experts.
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-3a935c81-f28a-4fec-8d64-f9847c98e6f1="loaded">
                        Tried-and-Tested Solutions
                      </li>
                    </ul>
                    <p>
                      We listen to our trucking customers, and we work to
                      research and discover which solutions work best for them.
                      We then use this insight to craft invoicing services that
                      are perfectly suited to truck drivers and owner-operators
                      in the field. The result is a set of tried-and-tested
                      services that go above and beyond the needs of our
                      customers.
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-3a935c81-f28a-4fec-8d64-f9847c98e6f1="loaded">
                        A Close, Personal Approach
                      </li>
                    </ul>
                    <p>
                      We don’t just provide these tried-and-tested trucking
                      services on an off-the-peg basis. We also take the time to
                      get to know you, your clients, and the specific approaches
                      you need to achieve success. This helps us to be flexible
                      in the solutions we offer.
                    </p>
                    <ul>
                      <li data-rocket-lazy-bg-3a935c81-f28a-4fec-8d64-f9847c98e6f1="loaded">
                        A Hands-free Solution for You&nbsp;
                      </li>
                    </ul>
                    <p>
                      Everything we provide to drivers is intended to make life
                      and work easier and more effective. Chasing up unpaid
                      bills and handling a flexible invoicing strategy detract
                      from the services you provide to your trucking clients.
                      This is why we work hard to take the stress out of running
                      your business.
                    </p>
                    <h2>Trucking Invoicing Services from SM Logistics</h2>
                    <p>
                      Reach out to our team to learn more about the trucking
                      invoicing services we provide and to get a quote.
                    </p>
                  </Col>
                </Row>
              </Container>
              <Container fluid>
                <Row>
                  <Col lg={12}>
                    <WhoCanWork />
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row>
                  <Col lg={12}>
                    <Faq page="truck-dispatch" />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <div className="contact-links">
                      <Link to="/contact-us">Contact Us</Link>
                      <Link to="tel:7076469911">(707) 646-9911</Link>
                      <Link to="mailto:smlogistics.us@gmail.com">
                        smlogistics.us@gmail.com
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </Col>
        </Row>
      </section>
    </Fragment>
  );
};

export default InvoiceService;
