import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { Link } from "react-router-dom"
import { Helmet } from 'react-helmet';

import img from "../../assets/images/about-header.png"

import "./about.style.scss"

function About() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <Helmet>
        <title>About - SM Logistics LLC</title>
        <meta name="description" content="SM Logistics provides dispatching services for owner-operators and truck fleets since 2015." />
        <meta name="keywords" content="best dispatching company, finding some loads in sm logistics displaching company" />
        <meta name="author" content="SM Logistics LLC" />
        <meta property="og:type" content="Company" />
        <meta property="og:title" content="SM Logistics LLC" />
        <meta property="og:description" content="SM Logistics job is to provide the best loads in the market for every driver. Our expert team ensures that drivers from any region and state get high-quality and best-paying loads." />
        <meta property="og:image" content={`${process.env.PUBLIC_URL}/header-log.png`} />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>
      <section className='about'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='about-banner'>
                <img src={img} alt="about-img" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='about-content'>
                <Container>
                  <Row>
                    <Col lg={12} className='p-0'>
                      <div className='about-heading'>
                        <h1>About the company</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='content-info'>
                        <p>SM Logistics provides dispatching services for owner-operators and truck fleets since 2015.</p>
                        <p>We suggest not only searching for loads but an array of services for drivers and carrier companies including billing, document management, and paperwork, broker and shipper communications, SAFETY DOT compliance, and much more.</p>
                        <h5>Drive, deliver, earn; we’ll do all the rest for you.</h5>
                        <p>One of the uncompromising key points for us is transparency. We have clear pricing with no hidden payments or termination fees. We charge our customers from 5 percent to 10 percent from every load depending on the trailer type they are using: dry van, step deck, reefer, flatbed, or hotshot (only 40’ trailers and CDL drivers).</p>
                        <p>We give special attention and priority to all the drivers we are working with. To ensure that you get the highest paying loads, we provide every customer with a dedicated dispatcher and 24 / 7 support. You have the right to change your dispatcher and choose another specialist if you’re not satisfied with them.</p>
                        <p>SM Logistics services include rate negotiation and all the conversation with the brokers and shippers. We make every effort to get the best freight by the highest price for our clients. Though, there is no forced dispatch: drivers may decline any loads if they want to.</p>
                        <p>Our head office is located in Wilmington, DE, although we dispatch drivers from all across the US.</p>
                        <p>If you need any additional information about our business, services, or privileges, feel free to contact us</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='contact-links'>
                        <Link to="/contact-us">Contact Us</Link>
                        <Link to="tel:7076469911">(707) 646-9911</Link>
                        <Link to="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</Link>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='map'>
                        <div style={{width: "100%"}}>
                          <iframe 
                            title='SM Logistics LLC'
                            width="100%" 
                            height="600" 
                            frameBorder="0" 
                            scrolling="no" 
                            marginHeight="0" 
                            marginWidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=30n%20gould%20street%20ste%20R%20sheridan%20wy%2082801+(SM%20Logistics%20LLC)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                              <a href="https://www.maps.ie/population/">Population Estimator map</a></iframe></div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default About
