import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { Link } from 'react-router-dom'

import truckdispatch from "../../assets/images/services/truck-dispatch.png"
import billing from "../../assets/images/services/billing.png"
import documents from "../../assets/images/services/documents.png"
import factoring from "../../assets/images/services/factoring.png"
import rate from "../../assets/images/services/rate.png"
import safety from "../../assets/images/services/safety.png"

import boxtruck from "../../assets/images/truck-types/box-stuck.png"
import straighttruck from "../../assets/images/truck-types/straight-struck.png"

import "./services.style.scss"

function Services() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='services'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='content-wrapper'>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className='heading'>
                        <h1>Our Services</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='details pl-0'>
                        <h4>Truck dispatching</h4>
                        <p>
                          Truck Dispatching: <br />
                          Keeping Your Business Moving So You Can Focus on Driving.<br />
                          Here at SM Logistics, we are proud to be the truck dispatcher company you can rely on.</p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='img'>
                        <img src={truckdispatch} alt="poweronly" width="100%" />
                      </div>
                    </Col>
                  </Row>
                  <Row>

                    <Col lg={6} className='pr-0'>
                      <div className='img'>
                        <img src={billing} alt="Hotshot" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='details'>
                        <h4>Billing</h4>
                        <p>
                          Trucking accounting company to keep you and your truck on the road. While you are out there on the road, we give all the necessary support and assistance to get the job done the right way, time and time again.</p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='details pl-0'>
                        <h4>Document Management</h4>
                        <p>
                          We offer an extensive range of solutions for truckers and trucking companies. <br />
                          Including dispatch services dedicated to providing you invaluable support.</p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='img'>
                        <img src={documents} alt="documents" width="100%" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='img'>
                        <img src={factoring} alt="factoring" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='details'>
                        <h4>Factoring service</h4>
                        <p>
                          As with any important business decision, whether you decide to use a truck factoring service depends on your business model, scale of operations, and how much risk you want to assume.</p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='details pl-0'>
                        <h4>Rate negotiation</h4>
                        <p>
                          Our services go far beyond simple average rate negotiation, <br />
                          and we help you position your operation and your brand at the forefront of the market.<br />
                          We’ll take care of everything. All you need to do is drive.                </p>
                        <a href="/">Read more</a>
                      </div>
                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='img'>
                        <img src={rate} alt="rate" width="100%" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='gross-wrapper'>
                        <div className='heading'>
                          <h3>From 5% of gross</h3>
                          <Link to="/contact-us">Contact us</Link>
                        </div>
                        <hr />
                        <div className='points'>
                          <ul>
                            <li>Dispatching</li>
                            <li>Billing</li>
                            <li>Document Management & Paperwork</li>
                            <li>Factoring service</li>
                            <li>Rate negotiation</li>
                          </ul>
                        </div>

                      </div>

                    </Col>
                  </Row>

                  <Row>
                    <Col lg={6} className='pr-0'>
                      <div className='img'>
                        <img src={safety} alt="safety" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0'>
                      <div className='details'>
                        <h4>SAFETY / DOT compliance</h4>
                        <p>
                          No one likes paperwork, and it can be hard to keep up with all the changes. Worst of all, mistakes or omissions can result in costly penalties. Thankfully, SM Logistics has your solution.</p>
                        <a href="/">Read more</a>
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='contact-wrapper'>
                        <div className='heading'>
                          <h3>Contact for details</h3>
                          <Link to="/contact-us">Contact us</Link>
                        </div>
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='map'>
                        <div style={{width: "100%"}}>
                          <iframe 
                            title='SM Logistics LLC'
                            width="100%" 
                            height="600" 
                            frameBorder="0" 
                            scrolling="no" 
                            marginHeight="0" 
                            marginWidth="0"
                            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=30n%20gould%20street%20ste%20R%20sheridan%20wy%2082801+(SM%20Logistics%20LLC)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">
                              <a href="https://www.maps.ie/population/">Population Estimator map</a></iframe></div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default Services
