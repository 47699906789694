import React, { useEffect, Fragment } from 'react'
import { Col, Row, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import img from "../../../assets/images/truck-types/truck-header.png"
import reefer from "../../../assets/images/truck-types/reefer.png"

import ContactForm from '../../../components/contact-form/contact-form.component'

import "./reefer.style.scss"

const Reefer = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='reefer truck-common'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='banner'>
                <img src={img} alt="flatbed" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='content'>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className='heading'>
                        <h1>Reefer</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className='points-wrapper'>
                        <ul>
                          <li>No forced dispatch and hidden payments</li>
                          <li>Personal dispatcher with 24 / 7 support</li>
                          <li>Only 5% of gross</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <ContactForm />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0 my-5'>
                      <div className='truck-img'>
                        <img src={reefer} alt="flatbed" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0 my-5'>
                      <div className='truck-details'>
                        <h2>Reefer Dispatch</h2>

                        <p>
                          At SM Logistics, we specialize in delivering top-notch dispatching services
                          tailored specifically for reefer trucks. Let our dedicated team take charge
                          of dispatching for your temperature-sensitive freight, leaving you free to
                          focus on what you do best – driving.
                        </p>
                        <p>
                          Reefer trucks play a critical role in ensuring the freshness and quality of
                          temperature-sensitive cargo, and we understand the importance of maintaining
                          the right atmospheric conditions. SM Logistics is here to support your
                          business with a dispatcher you can rely on—one who shares the right attitude
                          towards your operations.
                        </p>
                        <p>
                          Our commitment is to provide a seamless and stress-free experience for your
                          business. With full transparency and a personalized approach, SM Logistics
                          offers a comprehensive set of services that cover the entire process of
                          dispatching reefer freight. From maintaining the right conditions for
                          sensitive cargo to optimizing routes, we've got you covered.
                        </p>
                        <p>
                          Partner with SM Logistics for dispatching services that prioritize your
                          business needs. Experience the difference of having a reliable partner who
                          understands the unique challenges of reefer trucking and is dedicated to
                          keeping your operation moving smoothly. Drive with confidence, knowing that
                          SM Logistics has your dispatching needs covered.
                        </p>


                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className=''>
                      <hr />
                      <div className='truck-content'>
                        <h2>Reefer Truck Dispatch Benefits</h2>

                        <p>
                          Discover the multitude of benefits for your business by choosing SM
                          Logistics for Reefer Truck Dispatch:
                        </p>
                        <ol>
                          <li>
                            <p>
                              <strong>Streamlined, Reliable Deliveries:</strong>
                              SM Logistics ensures your deliveries are not only streamlined but also
                              highly reliable. With our dispatch services, your reefer trucks operate
                              seamlessly, meeting delivery timelines with precision.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Steady Revenue Stream:</strong>
                              Choose SM Logistics for a steady and consistent revenue stream. Our
                              dispatching services are designed to optimize your operations, ensuring
                              a reliable income for your reefer trucking business.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Less Hassle, Less Worry:</strong>
                              Experience less hassle and worry with SM Logistics at your side. Our
                              comprehensive dispatching solutions take care of the intricacies,
                              allowing you to focus on driving and providing exceptional service to
                              your customers.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Meet Market Demand:</strong>
                              SM Logistics helps you meet market demand effectively. By optimizing
                              routes and ensuring timely deliveries, we position your reefer trucking
                              business to fulfill market requirements efficiently.
                            </p>
                          </li>
                        </ol>
                        <p>
                          Elevate your business with SM Logistics and experience the advantages of
                          streamlined operations, steady revenue, reduced hassle, and the ability to
                          meet and exceed market demand. Drive with confidence, knowing that SM
                          Logistics is dedicated to maximizing the potential of your reefer trucking
                          endeavors.
                        </p>

                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>

                      <div className='services'>
                        <h4 className='my-4'>Choose our services</h4>
                        <ul>
                          <li><Link to="">Truck Dispatching</Link></li>
                          <li><Link to="">Billing</Link></li>
                          <li><Link to="">Document Management</Link></li>
                          <li><Link to="">Factoring Service</Link></li>
                          <li><Link to="">Rate Negotiation</Link></li>
                          <li><Link to="">Trucking Invoice Service</Link></li>
                        </ul>
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='contact-links'>
                        <Link to="/contact-us">Contact Us</Link>
                        <Link to="tel:7076469911">(707) 646-9911</Link>
                        <Link to="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default Reefer