import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap"
import { Link } from 'react-router-dom'

import img from "../../assets/images/page-how-to-header.png"


import "./how-to-start.style.scss"

function HowToStart() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='how-to-start'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='banner'>
                <img src={img} alt="how-to-start" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='how-to-start-content'>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className='heading'>
                        <h1>How to start</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className='step-wrapper'>
                        <h4>Step 1</h4>
                        <p>
                          If you:
                        </p>
                          <ul>
                            <li>Have an MC number for at least three months</li>
                            <li>Have an equipment we work with</li>
                            <li>Ready to travel interstate</li>
                          </ul>
                        <p><strong>Get right to Step 2</strong></p>
                        <p>Unfortunately, we can't start our cooperation right now if you don't meet all the criteria. Do not hesitate to reach out to us once you are ready, we'll be happy to assist you!</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <div className='step-wrapper'>
                        <h4>Step 2</h4>
                        <p>Apply using <Link to="/contact-us">this form</Link> or by phone call: <Link to="tel:+1 (707) 646-9911">+1 (707) 646-9911</Link>.</p>
                      </div>
                    </Col>
                    <Col lg={6}>
                    <div className='step-wrapper'>
                      <h4>Step 3</h4>
                        <p>
                          Prepare the necessary documents:
                          
                        </p>
                        <ul>
                          <li>W9 form (Request for Taxpayer Identification Number and Certification)</li>
                          <li>Insurance (certificate with liability cargo policies with us listed as a certificate holder).</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6}>
                    <div className='step-wrapper'>
                      <h4>Step 4</h4>
                        <p>
                        Sign the dispatch agreement. It is the basis for our services that comes along with limited PoA. It gives us a right to represent the customer's company on its behalf among different people.
                        </p>
                      </div>
                    </Col>
                    <Col lg={6}>
                    <div className='step-wrapper'>
                      <h4>Step 5</h4>
                        <p>
                        We are all set. Let’s get to work.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='contact-links'>
                        <Link to="/contact-us">Contact Us</Link>
                        <Link to="tel:7076469911">(707) 646-9911</Link>
                        <Link to="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default HowToStart
