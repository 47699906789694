import React, { Fragment, useEffect } from 'react'
import { Helmet } from 'react-helmet';

import Banner from '../components/home/banner/banner.component'
import ServicesAndPricing from '../components/home/services-and-pricing/services-and-pricing.component'
import ToWork from '../components/home/to-work/to-work.component'
import GetStarted from '../components/home/get-started/get-started.component'
import WhoCanWork from '../components/home/who-can-work/who-can-work.component'
import Testimonials from '../components/home/testimonials/testimonials.componenet'

import "./home.style.scss"

function Home() {
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
  }, [])
  return (
    <Fragment>
        <Helmet>
          <title>SM Logistics LLC</title>
          <meta name="description" content="SM Logistics job is to provide the best loads in the market for every driver. Our expert team ensures that drivers from any region and state get high-quality and best-paying loads." />
          <meta name="keywords" content="dispatching company best in lahore, dispatching trucks" />
          <meta name="author" content="SM Logistics LLC" />
          <meta property="og:type" content="Company" />
          <meta property="og:title" content="SM Logistics LLC" />
          <meta property="og:description" content="SM Logistics job is to provide the best loads in the market for every driver. Our expert team ensures that drivers from any region and state get high-quality and best-paying loads." />
          <meta property="og:image" content={`${process.env.PUBLIC_URL}/header-log.png`} />
          <meta property="og:url" content={window.location.href} />
          <link rel="canonical" href={window.location.href} />
        </Helmet>
         <Banner />
         <ServicesAndPricing />
         <ToWork />
         <GetStarted page="home"/>
         <WhoCanWork />
         <Testimonials />
         {/* <Blog /> */}
    </Fragment>
  )
}

export default Home
