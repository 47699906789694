import React, { useEffect, Fragment } from 'react'
import { Col, Row, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import img from "../../../assets/images/truck-types/truck-header.png"
import flatbed from "../../../assets/images/truck-types/flatebed.png"

import ContactForm from '../../../components/contact-form/contact-form.component'

import "./flatbed.style.scss"

const FlatBed = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='flatbed truck-common'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='banner'>
                <img src={img} alt="flatbed" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='content'>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className='heading'>
                        <h1>Flatbed</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className='points-wrapper'>
                        <ul>
                          <li>No forced dispatch and hidden payments</li>
                          <li>Personal dispatcher with 24 / 7 support</li>
                          <li>Only 5% of gross</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <ContactForm />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0 my-5'>
                      <div className='truck-img'>
                        <img src={flatbed} alt="flatbed" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0 my-5'>
                      <div className='truck-details'>
                        <h2>SM Logistics: Your Partner in Flatbed Dispatch Services – Supporting You Every Step of the Way</h2>
                        <p>At SM Logistics, we proudly offer Flatbed Dispatch Services to stand by your side as you tackle the demands of the trucking industry. Our commitment is to support you every step of the way, ensuring your flatbed operations run seamlessly. With industry expertise and a dedication to reliability, SM Logistics is here to assist you in getting the job done efficiently and successfully. Choose SM Logistics for unparalleled support in flatbed dispatch services, and experience the difference of having a trusted partner in your logistics journey.</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className=''>
                      <hr />
                      <div className='truck-content'>
                        <h2>Advantages of Flatbed Dispatch Services</h2>



                        <p>
                          Explore the array of benefits that accompany our Flatbed Dispatch Services
                          at SM Logistics:
                        </p>
                        <ol>
                          <li>
                            <p>
                              <strong>Optimize Your Investment: </strong>
                              With our flatbed truck dispatch, SM Logistics ensures you extract
                              maximum value from your investment. We focus on route optimization and
                              efficient load management to enhance the profitability of your flatbed
                              operations.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Reliable Income Source: </strong>
                              Count on SM Logistics for a dependable source of income in your flatbed
                              trucking business. Our dispatch services are designed to provide
                              consistency, contributing to the stability and success of your
                              operations.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Strategic Client Collaborations: </strong>
                              SM Logistics facilitates collaborations with the right clients for your
                              flatbed services. Through careful matchmaking, we create partnerships
                              that align with your capabilities, fostering sustainable and mutually
                              beneficial relationships.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Streamlined Operations: </strong>
                              Experience a more streamlined approach to running your business with SM
                              Logistics. Our comprehensive dispatch services handle the complexities,
                              allowing you to focus on core operations and improve overall business
                              efficiency.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Fair and Competitive Rates: </strong>
                              SM Logistics ensures you receive fair and competitive rates for your
                              flatbed trucking services. Our negotiation expertise and industry
                              knowledge empower you to secure rates that reflect the value of your
                              services.
                            </p>
                          </li>
                          <li>
                            <p>
                              <strong>Elevate with SM Logistics: </strong>
                              When it comes to Flatbed Truck Dispatch, choose excellence by partnering
                              with SM Logistics. Our track record of success and unwavering commitment
                              to client satisfaction make us the preferred choice for maximizing the
                              potential of your flatbed trucking business.
                            </p>
                          </li>
                        </ol>
                        <p>
                          Experience the advantages firsthand by choosing SM Logistics as your partner
                          for Flatbed Dispatch Services. We are dedicated to enhancing your business
                          outcomes and ensuring a seamless and prosperous journey in the flatbed
                          trucking industry.
                        </p>



                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>

                      <div className='services'>
                        <h4 className='my-4'>Choose our services</h4>
                        <ul>
                          <li><Link to="">Truck Dispatching</Link></li>
                          <li><Link to="">Billing</Link></li>
                          <li><Link to="">Document Management</Link></li>
                          <li><Link to="">Factoring Service</Link></li>
                          <li><Link to="">Rate Negotiation</Link></li>
                          <li><Link to="">Trucking Invoice Service</Link></li>
                        </ul>
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='contact-links'>
                        <Link to="/contact-us">Contact Us</Link>
                        <Link to="tel:7076469911">(707) 646-9911</Link>
                        <Link to="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default FlatBed