import React, {useState} from 'react'
import { useSelector } from "react-redux"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'

import { Col, Row } from 'react-bootstrap'
import { Link } from "react-router-dom"

import Header_logo from "../../assets/images/header-logo.png";

import './navbar.style.scss'

const Navbar = () => {
    const [showBars, setShowBars] = useState(false)
    const navigations = useSelector((state)=>state.navigations.navigationMenu)
    const renderNav = navigations.map((menu, index)=>{
        if(menu.innerMenu !== undefined && menu.innerMenu.length>0){
             return(
                <li className='link' key={index}>
                    <Link to={menu.path}>{menu.title}</Link>
                    <div className='inner-links'>
                        <ul>
                            {
                                menu.innerMenu.map((innerMenu, innerIndex)=>{
                                    return <li className='inner-link' key={innerIndex}><Link to={innerMenu.path}>{innerMenu.title}</Link></li>
                                })
                            }
                        </ul>
                    </div>
                </li>
             )
        }else{
            return(
                <li className='link' key={index}>
                    <Link to={menu.path}>{menu.headerLogo ? <img src={Header_logo} alt="Header Logo"/> : menu.title}</Link>
                </li>
            )
        }
    })

    const showBarsHandler = () => {
        setShowBars(!showBars)

        console.log(showBars)
    }

    return (
        <div className='nav-menu'>
            <Row>
                <Col lg={12} className='p-0'>
                    <div className='top-navigation container'>
                        <ul className='nav-links for-desktop' >
                            {renderNav}
                        </ul>

                        <ul className='nav-links for-mobile' style={{display: showBars ? 'flex' : 'none'}}>
                            {renderNav}
                        </ul>

                        <span className='menu-bars' onClick={()=>showBarsHandler()}> <FontAwesomeIcon icon={faBars} /> </span>
                        
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Navbar
