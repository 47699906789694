import React, { useEffect } from 'react';
import { HashRouter, Routes, Route } from 'react-router-dom';

import BaseLayout from './common/base-layout/base-layout.component';
import Home from './pages/home.component';
import About from './pages/About/about.component';
import Careers from './pages/About/careers/careers.component';
import ReferralProgram from './pages/About/referral-program/referral-program.component';
import Testimonial from './pages/About/testimonial/testimonial.component';
import ContactUs from './pages/contact-us/contact-us.componenet';
import Faq from './pages/faq/faq.component';
import HowToStart from './pages/how-to-start/how-to-start.component';
import Services from './pages/services/services.component';
import TruckDispatch from './pages/services/truck-dispatch/truck-dispatch.component';
import Documents from './pages/services/documents/documents.component';
import Billing from './pages/services/billing/billing.component';
import FactoringService from './pages/services/factoring-service/factoring-service.component';
import RateNegotiation from './pages/services/rate-negotiation/rate-negotiation.component';
import Shippers from './pages/shippers/shippers.component';
import TruckTypes from './pages/truck-types/truck-types.component';
import TermOfService from './pages/terms-of-service/terms-of-service.component';
import PowerOnly from './pages/truck-types/power-only/power-only.component';
import HotShot from './pages/truck-types/hot-shot/hot-shot.component';
import BoxTruck from './pages/truck-types/box-truck/box-truck.component';
import FlatBed from './pages/truck-types/flatbed/flatbed.component';
import Reefer from './pages/truck-types/reefer/reefer.component';
import StepDeck from './pages/truck-types/step-deck/step-deck.component';
import DryVan from './pages/truck-types/dry-van/dry-van.component';
import PrivacyAndPolicy from './pages/privacy-and-policy/privacy-and-policy.component';
import NotFound from './pages/404.component';

import './App.css';
import InvoiceService from './pages/services/invoice-service/invoice-service.component';

function App() {
  
	return (
		<HashRouter>
			<Routes basename="/smlogistics">
				<Route
					path="/"
					element={
						<BaseLayout>
							<Home />
						</BaseLayout>
					}
				/>
				<Route
					path="/about"
					element={
						<BaseLayout>
							<About />
						</BaseLayout>
					}
				/>
				<Route
					path="/careers"
					element={
						<BaseLayout>
							<Careers />
						</BaseLayout>
					}
				/>
				<Route
					path="/referral"
					element={
						<BaseLayout>
							<ReferralProgram />
						</BaseLayout>
					}
				/>
				<Route
					path="/testimonials"
					element={
						<BaseLayout>
							<Testimonial />
						</BaseLayout>
					}
				/>
				<Route
					path="/services"
					element={
						<BaseLayout>
							<Services />
						</BaseLayout>
					}
				/>
				<Route
					path="/services/truck-dispatch"
					element={
						<BaseLayout>
							<TruckDispatch />
						</BaseLayout>
					}
				/>
				<Route
					path="/services/documents"
					element={
						<BaseLayout>
							<Documents />
						</BaseLayout>
					}
				/>
				<Route
					path="/services/billing"
					element={
						<BaseLayout>
							<Billing />
						</BaseLayout>
					}
				/>
				<Route
					path="/services/factoring-service"
					element={
						<BaseLayout>
							<FactoringService />
						</BaseLayout>
					}
				/>
				<Route
					path="/services/rate-negotiation"
					element={
						<BaseLayout>
							<RateNegotiation />
						</BaseLayout>
					}
				/>
				<Route
					path="/services/invoice-service"
					element={
						<BaseLayout>
							<InvoiceService />
						</BaseLayout>
					}
				/>
				<Route
					path="/trucks"
					element={
						<BaseLayout>
							<TruckTypes />
						</BaseLayout>
					}
				/>
				<Route
					path="/trucks/power-only"
					element={
						<BaseLayout>
							<PowerOnly />
						</BaseLayout>
					}
				/>
				<Route
					path="/trucks/hot-shot"
					element={
						<BaseLayout>
							<HotShot />
						</BaseLayout>
					}
				/>
				<Route
					path="/trucks/box-truck"
					element={
						<BaseLayout>
							<BoxTruck />
						</BaseLayout>
					}
				/>
				<Route
					path="/trucks/flatbed"
					element={
						<BaseLayout>
							<FlatBed />
						</BaseLayout>
					}
				/>
				<Route
					path="/trucks/reefer"
					element={
						<BaseLayout>
							<Reefer />
						</BaseLayout>
					}
				/>
				<Route
					path="/trucks/step-deck"
					element={
						<BaseLayout>
							<StepDeck />
						</BaseLayout>
					}
				/>
				<Route
					path="/trucks/dry-van"
					element={
						<BaseLayout>
							<DryVan />
						</BaseLayout>
					}
				/>
				<Route
					path="/how-to-start"
					element={
						<BaseLayout>
							<HowToStart />
						</BaseLayout>
					}
				/>
				<Route
					path="/shippers"
					element={
						<BaseLayout>
							<Shippers />
						</BaseLayout>
					}
				/>
				<Route
					path="/faq"
					element={
						<BaseLayout>
							<Faq />
						</BaseLayout>
					}
				/>
				<Route
					path="/contact-us"
					element={
						<BaseLayout>
							<ContactUs />
						</BaseLayout>
					}
				/>
				<Route
					path="/term-of-service"
					element={
						<BaseLayout>
							<TermOfService />
						</BaseLayout>
					}
				/>
				<Route
					path="/privacy-and-policy"
					element={
						<BaseLayout>
							<PrivacyAndPolicy />
						</BaseLayout>
					}
				/>
				<Route
					path="*"
					element={
						<BaseLayout>
							<NotFound />
						</BaseLayout>
					}
				/>
			</Routes>
		</HashRouter>
	);
}

export default App;
