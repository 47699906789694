import React, { useEffect, Fragment } from 'react'
import { Col, Row, Container } from "react-bootstrap"
import { Link } from "react-router-dom"
import img from "../../../assets/images/truck-types/truck-header.png"
import dryvan from "../../../assets/images/truck-types/dry-van.png"

import ContactForm from '../../../components/contact-form/contact-form.component'

import "./dry-van.style.scss"

const DryVan = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])
  return (
    <Fragment>
      <section className='box-truck truck-common'>
        <Container fluid>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='banner'>
                <img src={img} alt="box-truck" />
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col lg={12} className='p-0'>
              <div className='content'>
                <Container>
                  <Row>
                    <Col lg={12}>
                      <div className='heading'>
                        <h1>Dry Van</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <div className='points-wrapper'>
                        <ul>
                          <li>No forced dispatch and hidden payments</li>
                          <li>Personal dispatcher with 24 / 7 support</li>
                          <li>Only 5% of gross</li>
                        </ul>
                      </div>
                    </Col>
                    <Col lg={6}>
                      <ContactForm />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className='pr-0 my-5'>
                      <div className='truck-img'>
                        <img src={dryvan} alt="boxtruck" width="100%" />
                      </div>

                    </Col>
                    <Col lg={6} className='pl-0 my-5'>
                      <div className='truck-details'>
                        <h2>Your Reliable Partner in Dry Van Logistics</h2>

                        <p>
                          In the dynamic world of transportation and logistics, dry van carriers and
                          shippers play a crucial role in the safe and efficient delivery of goods. At
                          SM Logistics, we recognize the challenges inherent in the industry, and we
                          are here to offer the ultimate support system for both dry van carriers and
                          shippers. Our dedicated dry van dispatch service is meticulously crafted to
                          optimize dry van logistics, improve delivery efficiency, and simplify the
                          lives of every truck driver on the road.
                        </p>
                        <p>
                          Our commitment extends beyond mere logistics; we aim to create an
                          environment where dry van operations thrive. With SM Logistics as your
                          partner, experience the seamless coordination of shipments, enhanced
                          delivery processes, and a support system that understands and addresses the
                          unique challenges of dry van transportation. Drive with confidence, knowing
                          that SM Logistics is dedicated to maximizing the efficiency and success of
                          your dry van logistics operations.
                        </p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} className=''>
                      <hr />
                      <div className='truck-content'>
                        <h2>The Significance of Transportation</h2>
                        <p>In the intricate landscape of freight logistics, dry van transportation stands as the backbone, catering to diverse industries and shipping requirements. At SM Logistics, we recognize the pivotal role of dry van carriers in securing and transporting a wide array of cargo. Whether it's consumer goods, industrial materials, or perishable items, dry van transportation remains the reliable choice for shippers seeking efficiency and reliability.</p>
                        <p>Our commitment at SM Logistics is to amplify the inherent benefits of dry van transportation, making it a top choice for shippers across industries. The versatility, cost-effectiveness, and ease of loading and unloading associated with dry van carriers align seamlessly with the needs of modern logistics. Partner with SM Logistics to experience a new level of efficiency and reliability in dry van transportation, where your cargo is handled with the utmost care and delivered with precision to its intended destination. Drive with confidence, knowing that SM Logistics is dedicated to maximizing the effectiveness of your dry van logistics operations.</p>
                        <h2 className='mt-5'>Optimizing Logistics</h2>
                        <p>
                          At SM Logistics, we recognize that behind every successful dry van load, there is a dedicated dry van truck driver. We deeply value the hard work and commitment of these professionals and are dedicated to making their job easier. Our team of dispatchers is available 24/7, providing unwavering support and assistance whenever it's needed.
                        </p>
                        <p>
                          From meticulous route planning to load optimization, SM Logistics is here to support dry van owner-operators and those part of larger dry van freight trucking companies. Our services are tailored to meet the unique needs of each driver, ensuring a seamless and efficient experience on the road. Drive with confidence, knowing that SM Logistics has your back, offering the assistance and support necessary for the success of your dry van logistics operations.
                        </p>
                        <h2 className='mt-5'>Why Choose SM Logistics:</h2>
                        <ul>
                          <li>Experience: With years of experience in the industry, we have a deep understanding of the intricacies of transportation.</li>
                          <li>24/7 Support: We know that the road never sleeps, and neither do we. Our team is available around the clock to assist you.</li>
                          <li>Efficiency: We use state-of-the-art technology to streamline the dispatch process, minimizing downtime and maximizing productivity.</li>
                          <li>Nationwide Coverage: Our services extend across the country, making us one of the top dispatching services you can rely on.</li>
                        </ul>
                      </div>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>

                      <div className='services'>
                        <h4 className='my-4'>Choose our services</h4>
                        <ul>
                          <li><Link to="">Truck Dispatching</Link></li>
                          <li><Link to="">Billing</Link></li>
                          <li><Link to="">Document Management</Link></li>
                          <li><Link to="">Factoring Service</Link></li>
                          <li><Link to="">Rate Negotiation</Link></li>
                          <li><Link to="">Trucking Invoice Service</Link></li>
                        </ul>
                      </div>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <div className='contact-links'>
                        <Link to="/contact-us">Contact Us</Link>
                        <Link to="tel:7076469911">(707) 646-9911</Link>
                        <Link to="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</Link>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  )
}

export default DryVan