import React, { Fragment, useEffect } from 'react'
import { Container, Row, Col } from "react-bootstrap"

import "./terms-of-service.style.scss"

const TermOfService = () => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [])
    return (
        <Fragment>
            <section className='term-of-service'>
                <Container>
                    <Row>
                        <Col lg={12} className='p-0'>
                            <div className='content'>
                                <p
                                    className="s1"
                                    style={{
                                        paddingTop: "3pt",
                                        paddingLeft: "67pt",
                                        textIndent: "0pt",
                                        textAlign: "center"
                                    }}
                                >
                                    Terms of service
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s2"
                                    style={{
                                        paddingTop: "2pt",
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        textAlign: "left"
                                    }}
                                >
                                    These Terms of Service have been updated and are effective as of August 30,
                                    2023
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s3"
                                    style={{
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        lineHeight: "108%",
                                        textAlign: "left"
                                    }}
                                >
                                    This document (the “<b>Terms of Service</b>” or the “<b>Agreement</b>
                                    <a
                                        href="https://smlogistics.us/"
                                        style={{
                                            color: "#202429",
                                            fontFamily: "Calibri, sans-serif",
                                            fontStyle: "normal",
                                            fontWeight: "normal",
                                            textDecoration: "none",
                                            fontSize: "12pt"
                                        }}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        ”) establishes the terms and conditions governing your use of the website{" "}
                                    </a>
                                    <span
                                        style={{
                                            color: "#00F",
                                            fontFamily: "Calibri, sans-serif",
                                            fontStyle: "normal",
                                            fontWeight: "normal",
                                            textDecoration: "underline",
                                            fontSize: "12pt"
                                        }}
                                    >
                                        https://smlogistics.us/
                                    </span>{" "}
                                    (“<b>Site</b>”). These Terms of Service, together with the Provider’s
                                    Privacy Policy, constitute a binding agreement between users of the Site
                                    (“you”, unless otherwise specified) and the Provider.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s3"
                                    style={{
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        lineHeight: "108%",
                                        textAlign: "left"
                                    }}
                                >
                                    This Site is operated by <b>SM Logistic LLC. </b>(a company duly registered
                                    under the laws of the State of Wyoming, with principal offices at 30 N Gould
                                    ST STE R, SHERIDAN, WY, 82801, USA) and its affiliates (collectively the “
                                    <b>Provider</b>”, “<b>we</b>”, or “<b>us</b>”) on its own behalf or behalf
                                    of its affiliates.
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <p
                                    className="s2"
                                    style={{
                                        paddingLeft: "5pt",
                                        textIndent: "0pt",
                                        lineHeight: "108%",
                                        textAlign: "left"
                                    }}
                                >
                                    Please read the Terms of Service carefully before using the Site!{" "}
                                    <span className="s3">
                                        By using the Site, you acknowledge and signify that you have read,
                                        understood, and agree to be bound by this Agreement. If you do not agree
                                        to all the terms and conditions outlined in these Terms of Service, please
                                        do not use the Site in any way.
                                    </span>
                                </p>
                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                    <br />
                                </p>
                                <ol id="l1">
                                    <li data-list-text={1}>
                                        <p
                                            className="s2"
                                            style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
                                        >
                                            Your Acceptance of this Agreement
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                        <ol id="l2">
                                            <li data-list-text="1.1.">
                                                <p
                                                    className="s3"
                                                    style={{
                                                        paddingLeft: "59pt",
                                                        textIndent: "-36pt",
                                                        lineHeight: "108%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    This agreement between you and the Provider governs your use of the
                                                    Site, its contents, and features. Each time you visit, browse,
                                                    access, or otherwise use the Site, you signify your acceptance and
                                                    agreement to be bound by this Agreement for using the Site, and to
                                                    comply with all applicable laws and regulations.
                                                </p>
                                            </li>
                                            <li data-list-text="1.2.">
                                                <p
                                                    className="s3"
                                                    style={{
                                                        paddingLeft: "59pt",
                                                        textIndent: "-36pt",
                                                        lineHeight: "107%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    If you do not want to agree to these Terms of Service, you must not
                                                    access or use the Site.
                                                </p>
                                            </li>
                                            <li data-list-text="1.3.">
                                                <p
                                                    className="s3"
                                                    style={{
                                                        paddingLeft: "59pt",
                                                        textIndent: "-36pt",
                                                        lineHeight: "108%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    By accessing the Site, you represent and warrant that you are at
                                                    least eighteen (18) years old and have the legal authority to agree
                                                    to and accept this Agreement on your behalf. If you have not reached
                                                    such age, have no legal authority to agree to and accept this
                                                    Agreement, or/and do not agree with the terms of this Agreement,
                                                    then you may not use the Site.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={2}>
                                        <h1
                                            style={{ paddingLeft: "41pt", textIndent: "-18pt", textAlign: "left" }}
                                        >
                                            Scope of this Agreement; Authority
                                        </h1>
                                        <ol id="l3">
                                            <li data-list-text="2.1.">
                                                <p
                                                    className="s3"
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "26pt",
                                                        textIndent: "-21pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    These Terms of Service only apply to your activities when using the
                                                    Site or its contents.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="2.2.">
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    From time to time, we may establish or authorize the Provider’s
                                                    service provider to establish Provider-branded services that operate
                                                    under different terms and conditions. Those different terms and
                                                    conditions will be linked to that service’s home page. Please review
                                                    such different terms and conditions if you choose to visit those
                                                    sites, as they govern your use thereof.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="2.3.">
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    These Terms of Service do not affect or amend the terms of any other
                                                    contracts you have entered into or may enter into with the Provider
                                                    or a Provider affiliate, such as carrier contracts, agency
                                                    agreements, transportation and logistics contracts, and other
                                                    contracts for the Provider’s goods or services (each of these
                                                    separate contracts with the Provider and/or its affiliates is
                                                    referred to as “Provider Contract(s)”), and your and the Provider’s
                                                    (and its affiliates) respective obligations thereunder, except as
                                                    otherwise expressly outlined in such Provider Contract. The Provider
                                                    “affiliate” is any entity that controls, is under common control
                                                    with, or is controlled by the Provider.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="2.4.">
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The Provider is based in the State of Wyoming in the United States.
                                                    The Provider provides the services within the Site for use primarily
                                                    by persons located in the United States, and the Site is not
                                                    intended to be used by persons located outside of the United States.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="2.5.">
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The Provider makes no claims that the Site or any of its content is
                                                    accessible or appropriate outside of the United States. Access to
                                                    the Site may not be legal by certain persons or in certain
                                                    countries. If you access the Site from outside the United States,
                                                    you do so on your own initiative. We cannot guarantee that these
                                                    Terms of Service and Site will comply with local laws where you are
                                                    located.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={3}>
                                        <p
                                            className="s5"
                                            style={{ paddingLeft: "18pt", textIndent: "-13pt", textAlign: "left" }}
                                        >
                                            Changes to these Terms of Service
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                        <ol id="l4">
                                            <li data-list-text="3.1.">
                                                <p
                                                    className="s3"
                                                    style={{
                                                        paddingLeft: "26pt",
                                                        textIndent: "-21pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    We may modify, suspend, or terminate the Site’s operation, as well
                                                    as its content and
                                                </p>
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    offerings, in whole and/or in part, at our sole discretion, at any
                                                    time and for any reason.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="3.2.">
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    From time to time, the Provider may, in its sole discretion, for any
                                                    reason and without any liability to you or any other person, change,
                                                    supplement, or amend these Terms of Service.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="3.3.">
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Access to some areas of the Site may be limited to users
                                                    authenticated and granted permission by the Provider. The Provider
                                                    reserves its right to revoke permission to access such areas of the
                                                    Site for any reason.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="3.4.">
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    These Terms of Service may only be modified in writing by an
                                                    authorized officer of the Provider, and they may not be modified
                                                    orally or in writing by our Site’s customer service representatives
                                                    or our employees.
                                                </p>
                                            </li>
                                            <li data-list-text="3.5.">
                                                <p
                                                    className="s3"
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    You may not change, supplement, or amend these Terms of Service in
                                                    any manner. We encourage you to check for revisions to the Terms of
                                                    Service each time you use the Site and, in any event, you are bound
                                                    to changes to this Agreement if you continue to use the Site after
                                                    such changes have been introduced and for which notification of
                                                    substantive changes has been made (such as a notice on the home page
                                                    of the Site that such change has been made). Any changes to the
                                                    dispute resolution procedures set forth below in Section “Governing
                                                    Law and Venue” shall not apply to any disputes that have been
                                                    commenced before the changes to the Terms of Service take effect.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={4}>
                                        <p
                                            className="s2"
                                            style={{ paddingLeft: "17pt", textIndent: "-12pt", textAlign: "left" }}
                                        >
                                            Privacy Policy
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                        <ol id="l5">
                                            <li data-list-text="4.1.">
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    <a
                                                        href="https://smlogistics.us/privacy-policy/"
                                                        style={{
                                                            color: "#202429",
                                                            fontFamily: "Calibri, sans-serif",
                                                            fontStyle: "normal",
                                                            fontWeight: "normal",
                                                            textDecoration: "none",
                                                            fontSize: "12pt"
                                                        }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Please review our Privacy Policy, which explains our practices
                                                        relating to the collection, use, storage (if any), and disclosure
                                                        of personal information you provide through the use of the Site.
                                                        The Privacy Policy is accessible at{" "}
                                                    </a>
                                                    <span
                                                        style={{
                                                            color: "#00F",
                                                            fontFamily: "Calibri, sans-serif",
                                                            fontStyle: "normal",
                                                            fontWeight: "normal",
                                                            textDecoration: "underline",
                                                            fontSize: "12pt"
                                                        }}
                                                    >
                                                        https://smlogistics.us/privacy-policy/
                                                    </span>
                                                    . By accessing and using our Site and providing us with your
                                                    personal data, you consent to the Privacy Policy.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={5}>
                                        <p
                                            className="s2"
                                            style={{ paddingLeft: "17pt", textIndent: "-12pt", textAlign: "left" }}
                                        >
                                            Intellectual Property Rights
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                        <ol id="l6">
                                            <li data-list-text="5.1.">
                                                <p
                                                    className="s3"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The ownership of the Site, its content, features, functionality, and
                                                    systems, including copyright, trademarks, and service marks used on
                                                    the Site, as well as all other intellectual property rights, belongs
                                                    to the Provider, its affiliates, and/or their licensors. The
                                                    Provider, its affiliates, or licensors reserve all rights not
                                                    expressly granted or licensed herein. The Site, along with its
                                                    entire contents, data, software, code, features, and functionality
                                                    (comprising information, text, displays, images, video, audio, and
                                                    the design, selection, and arrangement thereof), materials,
                                                    processes, procedures, methods, techniques, and any other content
                                                    collectively referred to as "Data and Information," is owned by the
                                                    Provider, its affiliates, its licensors, or other material
                                                    providers, either through ownership or licensing. These assets are
                                                    safeguarded by the laws of the United States and international
                                                    regulations, encompassing copyright, trademark, patent, trade
                                                    secret, and other intellectual property and proprietary rights,
                                                    collectively known as <b>"Intellectual Property Rights.").</b>
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="5.2.">
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    By using the Provider's services, you explicitly recognize,
                                                    comprehend, and consent to the fact that the Provider or its
                                                    affiliates maintain exclusive ownership of all Intellectual Property
                                                    Rights associated with the Data and Information, as well as domain
                                                    names linking to the Provider sites in both the United States and
                                                    international domains, including those connected to the Provider's
                                                    business. All rights to these assets are reserved.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="5.3.">
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    <a
                                                        href="mailto:smlogistics.us@gmail.com"
                                                        className="s8"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        If you seek to utilize material from the Site beyond the
                                                        permissions outlined in this section, kindly direct your request
                                                        to{" "}
                                                    </a>
                                                    <a
                                                        href="mailto:smlogistics.us@gmail.com"
                                                        className="s7"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        smlogistics.us@gmail.com
                                                    </a>
                                                    <a
                                                        href="mailto:smlogistics.us@gmail.com"
                                                        className="s8"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        .
                                                    </a>
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={6}>
                                        <p
                                            className="s2"
                                            style={{
                                                paddingTop: "1pt",
                                                paddingLeft: "17pt",
                                                textIndent: "-12pt",
                                                textAlign: "left"
                                            }}
                                        >
                                            Acceptable Use; Prohibited Use
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                        <ol id="l7">
                                            <li data-list-text="6.1.">
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    You are accountable for all activities you participate in, whether
                                                    on or through the utilization of the Site. By accessing the Site,
                                                    you commit to utilizing it in compliance with these Terms of
                                                    Service, the Revised Terms, and all relevant laws, rules, and
                                                    regulations.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="6.2.">
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    You commit to maintaining the accuracy, completeness, and
                                                    truthfulness of all information provided to the Provider or shared
                                                    through the Site, including, but not limited to, contact details.
                                                    Furthermore, you undertake not to provide the Provider or transmit
                                                    through the Site any information or materials that:
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <ol id="l8">
                                                    <li data-list-text="(a)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Are inaccurate, false, misleading, deceptive, tortious,
                                                            injurious, unlawful, threatening, abusive, defamatory, obscene,
                                                            lewd, lascivious, violent, harassing, offensive, pornographic,
                                                            profane, or indecent, or that would encourage conduct
                                                            constituting a criminal offense, civil liability, or a violation
                                                            of applicable laws, rules, and/or regulations;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(b)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "21pt",
                                                                textIndent: "-16pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Infringe upon the proprietary rights of others, including, but
                                                            not limited to, copyright,
                                                        </p>
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            trademark, trade dress, patent, trade secret, or moral rights,
                                                            or material violating another’s
                                                        </p>
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            rights of privacy, publicity, or confidentiality;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(c)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "20pt",
                                                                textIndent: "-15pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            You lack the right to provide, share, or transmit;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(d)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Include credit card account information or personal details that
                                                            could be utilized to access a financial account;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(e)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            In any way breach any applicable federal, state, local, or
                                                            international law or regulation, including laws concerning the
                                                            export of data or software to and from the US or other
                                                            countries;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(f)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "justify"
                                                            }}
                                                        >
                                                            Are shared with the intention of exploiting, harming, or
                                                            attempting to exploit or harm minors, such as exposing them to
                                                            inappropriate content, soliciting personally identifiable
                                                            information, or engaging in similar activities;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(g)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "justify"
                                                            }}
                                                        >
                                                            Consist of any advertising or promotional material without our
                                                            prior written consent, including "junk mail," "chain letter,"
                                                            "spam," or any other comparable solicitation;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(h)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "justify"
                                                            }}
                                                        >
                                                            Are intended to impersonate or imitate the Provider, its
                                                            employees, another user, or any other person or entity,
                                                            including the use of email addresses or screen names associated
                                                            with any of the aforementioned; or
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(i)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "justify"
                                                            }}
                                                        >
                                                            Could, as determined by us, impede or inhibit anyone's use or
                                                            enjoyment of the Site, cause harm to the Provider or users of
                                                            the Site, or expose them to liability.
                                                        </p>
                                                    </li>
                                                </ol>
                                            </li>
                                            <li data-list-text="6.3.">
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Additionally, you undertake not to partake in activities on or
                                                    through the use of the Site, or utilize information obtained from
                                                    the Site, for the following purposes:
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <ol id="l9">
                                                    <li data-list-text="(a)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "20pt",
                                                                textIndent: "-15pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Violate these Terms of Service or any applicable laws, rules, or
                                                            regulations;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(b)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "21pt",
                                                                textIndent: "-16pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Engage in fraudulent activities;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(c)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Breach the terms of use, rules, policies, or guidelines of your
                                                            Internet access provider, online service, or sites linked to by
                                                            the Site;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(d)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Harm, delete, modify, or create derivative versions of any
                                                            information or material within the Site;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(e)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Alter any copyright or other proprietary notices or legends
                                                            within the Site or information or material printed or downloaded
                                                            from the Site;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(f)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Utilize any robot, scraper, or other automated process not
                                                            provided by us to collect information or extract data from the
                                                            Site;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(g)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Reverse engineer, copy, disassemble any programming components
                                                            of the Site, or attempt to discover source code from the Site;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(h)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Bypass, circumvent, or attempt to bypass or circumvent measures
                                                            in place to prevent or limit access to areas, code, or content
                                                            of the Site;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(i)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "justify"
                                                            }}
                                                        >
                                                            Disable, disrupt, impair, overburden, or damage the Site or
                                                            interfere with any other party’s use thereof, including through
                                                            viruses, denial of service attacks, spamming, flooding, or other
                                                            disruptive components or acts;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(j)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Use any robot, spider, or other automatic device, process, or
                                                            means to access the Site for any purpose, including monitoring
                                                            or copying any material within the Site;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            (o) Use any manual process to monitor or copy any material
                                                            within the Site or for any other unauthorized purpose without
                                                            our prior written consent;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(k)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "justify"
                                                            }}
                                                        >
                                                            Attempt to gain unauthorized access to, interfere with, damage,
                                                            or disrupt any parts of the Site, the server on which the Site
                                                            is stored, or any server, computer, or database connected to the
                                                            Site; or
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(l)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "17pt",
                                                                textIndent: "-12pt",
                                                                textAlign: "justify"
                                                            }}
                                                        >
                                                            Otherwise attempt to interfere with the proper functioning of
                                                            the Site.
                                                        </p>
                                                    </li>
                                                </ol>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="6.4.">
                                                <p
                                                    style={{
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    If we discover or receive information from third parties or law
                                                    enforcement officials regarding any of the prohibited uses mentioned
                                                    above or other unlawful uses on your behalf,
                                                </p>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    we reserve the right to terminate your access to the Site due to
                                                    your violation of these Terms of Service. Without limiting the
                                                    foregoing, we retain the right to report any violation of these
                                                    Terms of Service to law enforcement authorities. YOU AGREE TO WAIVE
                                                    AND HOLD HARMLESS THE PROVIDER AND ITS AFFILIATES, LICENSEES, AND
                                                    SERVICE PROVIDERS FROM ANY CLAIMS ARISING FROM ANY ACTION TAKEN BY
                                                    US OR ANY OF THE AFOREMENTIONED PARTIES DURING OR AS A CONSEQUENCE
                                                    OF INVESTIGATIONS BY EITHER SUCH PARTIES OR LAW ENFORCEMENT
                                                    AUTHORITIES.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="6.5.">
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Nothing in these Terms of Service shall prohibit or restrict the
                                                    Provider or any Provider affiliate from complying with all
                                                    applicable laws, rules, and regulations, as well as all law
                                                    enforcement requests and/or orders. The Provider may use and
                                                    disclose any user contact information as the Provider deems
                                                    reasonable to comply with the law, respond to requests or orders,
                                                    enforce these Terms of Service, and protect the safety of persons or
                                                    property.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }} />
                                            </li>
                                            <li data-list-text="6.6.">
                                                <p
                                                    className="s6"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    <a
                                                        href="http://www.onguardonline.gov/"
                                                        className="s8"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        Even though the Site is not intended for or designed to be used by
                                                        children, it is essential for parents to be aware that a
                                                        substantial amount of information is accessible to them. Moreover,
                                                        various commercially available products and services are at their
                                                        disposal to aid in implementing controls that restrict minors'
                                                        access to adult, violent, or other content that parents may wish
                                                        to block. The Federal Trade Commission offers valuable information
                                                        on safeguarding children's internet usage, which can be found on
                                                        their website at{" "}
                                                    </a>
                                                    <span
                                                        style={{
                                                            color: "#00F",
                                                            fontFamily: '"Segoe UI", sans-serif',
                                                            fontStyle: "normal",
                                                            fontWeight: "normal",
                                                            textDecoration: "none",
                                                            fontSize: "12pt"
                                                        }}
                                                    >
                                                        www.onguardonline.gov
                                                    </span>
                                                    .
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={7}>
                                        <p
                                            className="s2"
                                            style={{
                                                paddingTop: "2pt",
                                                paddingLeft: "17pt",
                                                textIndent: "-12pt",
                                                textAlign: "left"
                                            }}
                                        >
                                            Copyright Infringement
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                        <ol id="l10">
                                            <li data-list-text="7.1.">
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The Provider upholds the intellectual property rights of others and
                                                    requires all users of the Site to do the same. The Provider will
                                                    thoroughly investigate reports of copyright infringement and will
                                                    take appropriate actions upon receiving proper notifications. This
                                                    may include the termination of Site access and other privileges for
                                                    individuals who repeatedly infringe on the copyright rights of
                                                    others. If you believe that your work has been used, shared, or
                                                    copied on the Site in a manner that constitutes copyright
                                                    infringement, please inform the Provider's Copyright Agent (at the
                                                    address provided below) in writing, including the following details:
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <ol id="l11">
                                                    <li data-list-text="(a)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Your real name, along with your current valid working email
                                                            address and telephone number for contact purposes;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(b)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Identification of the copyrighted work that you claim has been
                                                            infringed, or if multiple works are involved, a representative
                                                            list of such works;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(c)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "20pt",
                                                                textIndent: "-15pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            A description of where the allegedly infringing work is being
                                                            used or appears on the Site;
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(d)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            A written statement from you affirming that you have a
                                                            good-faith belief that the disputed use is not authorized by the
                                                            copyright owner, its agent, or the law;
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(e)">
                                                        <p
                                                            style={{
                                                                paddingTop: "1pt",
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            A statement from you, made under penalty of perjury, asserting
                                                            that the information provided in your notice is accurate and
                                                            that you are the copyright owner or authorized to act on behalf
                                                            of the copyright owner; and
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                    <li data-list-text="(f)">
                                                        <p
                                                            style={{
                                                                paddingLeft: "18pt",
                                                                textIndent: "-13pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Your electronic or physical signature.
                                                        </p>
                                                    </li>
                                                </ol>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Please send your copyright infringement notice to the following
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    className="s10"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    —
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    <a
                                                        href="mailto:smlogistics.us@gmail.com"
                                                        style={{
                                                            color: "#202429",
                                                            fontFamily: "Calibri, sans-serif",
                                                            fontStyle: "normal",
                                                            fontWeight: "normal",
                                                            textDecoration: "none",
                                                            fontSize: "12pt"
                                                        }}
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        the Provider’s Copyright Agent is at{" "}
                                                    </a>
                                                    <a
                                                        href="mailto:smlogistics.us@gmail.com"
                                                        target="_blank"
                                                        rel="noreferrer"
                                                    >
                                                        smlogistics.us@gmail.com
                                                    </a>
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={8}>
                                        <p
                                            className="s2"
                                            style={{
                                                paddingTop: "2pt",
                                                paddingLeft: "17pt",
                                                textIndent: "-12pt",
                                                textAlign: "left"
                                            }}
                                        >
                                            Disclaimer, Liability Exclusion, Liability Limitation, Release and
                                            Indemnity
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                        <ol id="l12">
                                            <li data-list-text="8.1.">
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The Provider and any affiliated entities operating the Site disclaim
                                                    any liability for your utilization of the Site. Periodically, the
                                                    Site may include technical inaccuracies or typographical errors, and
                                                    we do not guarantee the accuracy of any information provided. It is
                                                    advisable to verify that you are accessing the most current
                                                    information on the Site and to ensure the accuracy and completeness
                                                    of the information before making decisions related to services,
                                                    products, or other matters described on the Site.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="8.2.">
                                                <h3
                                                    style={{
                                                        paddingLeft: "24pt",
                                                        textIndent: "-19pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    DISCLAIMER:
                                                </h3>
                                                <p
                                                    style={{
                                                        paddingTop: "9pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Your use of the site, Its content, and any services or items
                                                    obtained through the site is at your own rist. You assume the entire
                                                    cost of all necessary servicing, repair, or conrrection to any
                                                    computer or other equipment arising from, connected with, or because
                                                    of your use of the site, You may have the opportunity ot engage in
                                                    commercial transactions with respons other than the provider or the
                                                    provider affiliate. All such transactions are at your own rist.
                                                    Without LIMITING THE GENERALITY of the foregoing, The provider and
                                                    the provider affiliate make no REPRESENTATION, WARRANTY, OR
                                                    CONDITION THAT:
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <ul id="l13">
                                                    <li data-list-text="">
                                                        <p
                                                            className="s6"
                                                            style={{
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            THE SITE WILL BE COMPATIBLE WITH YOUR COMPUTER AND RELATED
                                                            EQUIPMENT AND SOFTWARE;
                                                        </p>
                                                    </li>
                                                    <li data-list-text="">
                                                        <p
                                                            className="s6"
                                                            style={{
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            THE SITE WILL BE AVAILABLE OR WILL FUNCTION WITHOUT INTERRUPTION
                                                            OR WILL BE FREE OF ERRORS, OR ANY ERRORS WILL BE CORRECTED;
                                                        </p>
                                                    </li>
                                                    <li data-list-text="">
                                                        <p
                                                            className="s6"
                                                            style={{
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            THE INFORMATION AVAILABLE ON OR THROUGH THE SITE WILL BE
                                                            ACCURATE, COMPLETE, SEQUENTIAL, OR TIMELY;
                                                        </p>
                                                    </li>
                                                    <li data-list-text="">
                                                        <p
                                                            className="s6"
                                                            style={{
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            THE USE OF THE SITE, INCLUDING THE BROWSING AND DOWNLOADING OF
                                                            ANY INFORMATION, WILL BE FREE OF VIRUSES, TROJAN HORSES, WORMS,
                                                            OR OTHER DESTRUCTIVE OR DISRUPTIVE COMPONENTS; OR
                                                        </p>
                                                    </li>
                                                    <li data-list-text="">
                                                        <p
                                                            className="s6"
                                                            style={{
                                                                paddingTop: "4pt",
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            THE USE OF THE SITE WILL NOT INFRINGE THE RIGHTS (INCLUDING
                                                            INTELLECTUAL PROPERTY RIGHTS) OF ANY PERSON.
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                        <p
                                                            className="s11"
                                                            style={{
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            PROVIDER AND ITS AFFILIATES DISCLAIM ANY AND ALL LIABILITY
                                                            REGARDING SUCH MATTERS TO THE FULLEST EXTENT PERMITTED BY LAW.
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li data-list-text="8.3.">
                                                <p
                                                    className="s2"
                                                    style={{
                                                        paddingLeft: "26pt",
                                                        textIndent: "-21pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    LIABILITY EXCLUSION:
                                                </p>
                                                <p
                                                    className="s12"
                                                    style={{
                                                        paddingTop: "9pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    THE SITE, ITS CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH
                                                    THE SITE ARE
                                                </p>
                                                <p
                                                    className="s12"
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        lineHeight: "108%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT ANY
                                                    WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE
                                                    PROVIDER NOR ANY PERSON ASSOCIATED WITH IT MAKES ANY WARRANTY OR
                                                    REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY,
                                                    RELIABILITY, QUALITY, ACCURACY, OR AVAILABILITY OF THE SITE OR THAT
                                                    IT WILL OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. TO THE FULLEST
                                                    EXTENT PROVIDED BY LAW, THE PROVIDER HEREBY DISCLAIMS ALL WARRANTIES
                                                    OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY OR OTHERWISE,
                                                    INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES OF MERCHANTABILITY,
                                                    NON-INFRINGEMENT, AND FITNESS FOR A PARTICULAR PURPOSE.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="8.4.">
                                                <p
                                                    className="s13"
                                                    style={{
                                                        paddingLeft: "29pt",
                                                        textIndent: "-24pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    LIMITATION OF LIABILITY:
                                                </p>
                                                <p
                                                    className="s12"
                                                    style={{
                                                        paddingTop: "9pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        lineHeight: "108%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT, INCLUDING DUE
                                                    TO NEGLIGENCE, WILL THE PROVIDER OR ANY OF ITS AFFILIATES OR THEIR
                                                    RESPECTIVE OFFICERS, DIRECTORS, EMPLOYEES, OR LICENSORS BE LIABLE
                                                    FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, PUNITIVE, SPECIAL OR OTHER
                                                    DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES DUE TO LOST INCOME
                                                    OR PROFITS (ANTICIPATED OR OTHERWISE) OR BUSINESS INTERRUPTION, ANY
                                                    LOSS OF USE, LOSS OF PRODUCTION, ARISING OUT OF USE OF THE SITE, OR
                                                    ANY ITS CONTENT, INFORMATION, PRODUCT OR SERVICE, EVEN IF THE
                                                    PROVIDER OR ANY OF ITS AFFILIATES HAS BEEN ADVISED OF THE
                                                    POSSIBILITY THEREOF. TO THE FULLEST EXTENT PERMITTED BY LAW, THE
                                                    LIABILITY OF THE PROVIDER AND ITS AFFILIATES AND THEIR RESPECTIVE
                                                    OFFICERS, DIRECTORS, EMPLOYEES, AND LICENSORS ARISING OUT OF OR IN
                                                    CONNECTION WITH THIS SITE IS LIMITED TO THE LESSER OF THE DOLLAR
                                                    AMOUNT YOU PAID THE PROVIDER TO USE THIS SITE OR USD
                                                </p>
                                                <p
                                                    className="s12"
                                                    style={{
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        lineHeight: "15pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    $500.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="8.5.">
                                                <p
                                                    className="s2"
                                                    style={{
                                                        paddingLeft: "26pt",
                                                        textIndent: "-21pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    RELEASE<span className="s14">:</span>
                                                </p>
                                                <p
                                                    className="s12"
                                                    style={{
                                                        paddingTop: "9pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        lineHeight: "108%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    YOU HEREBY RELEASE, REMISE, AND FOREVER DISCHARGE EACH OF THE
                                                    PROVIDER, ITS AFFILIATES, AND ALL OF THEIR RESPECTIVE AGENTS,
                                                    DIRECTORS, OFFICERS, EMPLOYEES, INFORMATION PROVIDERS, SERVICE
                                                    PROVIDERS, SUPPLIERS, LICENSORS, AND LICENSEES, AND ALL OTHER
                                                    RELATED, ASSOCIATED, OR CONNECTED PERSONS FROM ANY AND ALL MANNER OF
                                                </p>
                                                <p
                                                    className="s12"
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        lineHeight: "108%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    RIGHTS, CLAIMS, COMPLAINTS, DEMANDS, CAUSES OF ACTION, PROCEEDINGS,
                                                    LIABILITIES, OBLIGATIONS, LEGAL FEES, COSTS, AND DISBURSEMENTS OF
                                                    ANY NATURE AND KIND WHATSOEVER AND HOWSOEVER ARISING, WHETHER KNOWN
                                                    OR UNKNOWN, WHICH NOW OR HEREAFTER EXIST, WHICH ARISE FROM, RELATE
                                                    TO, OR ARE CONNECTED WITH YOUR USE OF THE SITE.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="8.6.">
                                                <p
                                                    className="s2"
                                                    style={{
                                                        paddingLeft: "26pt",
                                                        textIndent: "-21pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    INDEMNITY<span className="s14">:</span>
                                                </p>
                                                <p
                                                    className="s12"
                                                    style={{
                                                        paddingTop: "9pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        lineHeight: "108%",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS EACH OF THE
                                                    PROVIDER, ITS AFFILIATES, AND ALL OF THEIR RESPECTIVE AGENTS,
                                                    DIRECTORS, OFFICERS, EMPLOYEES, INFORMATION PROVIDERS, SERVICE
                                                    PROVIDERS, SUPPLIERS, LICENSORS AND LICENSEES, AND ALL OTHER
                                                    RELATED, ASSOCIATED, OR CONNECTED PERSONS FROM AND AGAINST ANY AND
                                                    ALL LIABILITIES, EXPENSES, AND COSTS, INCLUDING, WITHOUT LIMITATION,
                                                    REASONABLE LEGAL FEES AND EXPENSES, INCURRED BY THEM IN CONNECTION
                                                    WITH ANY CLAIM OR DEMAND ARISING OUT OF, RELATED TO, OR CONNECTED
                                                    WITH YOUR USE OF THE SITE, THE PRODUCTS AND SERVICES OFFERED THROUGH
                                                    THE SITE, OR YOUR BREACH OF THIS AGREEMENT.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="8.7.">
                                                <p
                                                    className="s15"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The disclaimer, liability exclusion, liability limitation, release,
                                                    and indemnity provisions, as well as Sections “Intellectual Property
                                                    Rights”, “Governing Law and Venue”, “Waiver; Severability”,
                                                    “Limitation on Time to File Claims”, “Communications” in this
                                                    Agreement shall survive indefinitely after the termination of this
                                                    Agreement.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={9}>
                                        <p
                                            className="s2"
                                            style={{ paddingLeft: "17pt", textIndent: "-12pt", textAlign: "left" }}
                                        >
                                            Links
                                        </p>
                                        <p
                                            style={{
                                                paddingTop: "9pt",
                                                paddingLeft: "5pt",
                                                textIndent: "0pt",
                                                textAlign: "left"
                                            }}
                                        >
                                            The provided text appears to be a segment from the terms of service or
                                            terms and conditions of a website or online service. Let me break down
                                            the key points for better understanding:
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                        <ol id="l14">
                                            <li data-list-text="9.1.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "26pt",
                                                        textIndent: "-21pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Linked Sites Disclaimer:
                                                </h2>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The website (referred to as "the Site") may have links to or from
                                                    sites that are controlled by third parties. The provider of the
                                                    website and its affiliates (referred to as "the Provider" and "the
                                                    Provider Affiliate") do not make any representations or warranties
                                                    about these linked sites, including their content, or the goods and
                                                    services you may find or acquire there.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="9.2.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "26pt",
                                                        textIndent: "-21pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Lack of Control and Disclaimer of Responsibility:
                                                </h2>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The Provider has no control over the content of these linked sites
                                                    or the resources they provide. Neither the Provider nor its
                                                    affiliates accept responsibility for these sites or any loss or
                                                    damage that may occur as a result of your use of them. Users access
                                                    these linked sites at their own risk.
                                                </p>
                                                <h2
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Caution and Recommendation:
                                                </h2>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Users are advised to check the terms of use and privacy policy
                                                    applicable to each site they visit. The terms and privacy policies
                                                    may vary among sites due to their nature and who is permitted to
                                                    access them. This is a cautionary note, suggesting users be aware
                                                    that different websites may have different rules and policies.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    In summary, this section of the terms is emphasizing that the
                                                    Provider and its affiliates are not responsible for the content,
                                                    goods, or services on linked third-party sites, and users should be
                                                    cautious and review the terms and privacy policies of each site they
                                                    visit.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={10}>
                                        <p
                                            className="s2"
                                            style={{
                                                paddingTop: "9pt",
                                                paddingLeft: "23pt",
                                                textIndent: "-18pt",
                                                textAlign: "left"
                                            }}
                                        >
                                            Third-Party Products and Services
                                        </p>
                                        <p
                                            style={{
                                                paddingTop: "9pt",
                                                paddingLeft: "5pt",
                                                textIndent: "0pt",
                                                textAlign: "justify"
                                            }}
                                        >
                                            This section of the terms and conditions outlines the involvement of
                                            third-party products and services on the website. Here are the key
                                            points:
                                        </p>
                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                            <br />
                                        </p>
                                        <ol id="l15">
                                            <li data-list-text="10.1.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Mention of Third-Party Products and Services:
                                                </h2>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The Site may feature information about third-party products and
                                                    services. This could include arrangements for discounts on specific
                                                    products or services or advertisements for such products and
                                                    services.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="10.2.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Disclaimer of Responsibility for Third-Party Products and Services:
                                                </h2>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    The Provider clarifies that these third-party products and services
                                                    are not provided by the website itself. The Provider explicitly
                                                    states that it is not responsible for these goods or services or the
                                                    terms under which they are bought or sold. Users are informed that
                                                    if they choose to purchase such third-party goods or services, they
                                                    do so at their own risk.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <h2
                                                    style={{
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    Cautionary Note on Links to Third-Party Sites:
                                                </h2>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    If the Site includes links to other websites or resources provided
                                                    by third parties, these links are presented for user convenience.
                                                    However, users are reminded that they access these third-party
                                                    websites entirely at their own risk and are subject to the terms and
                                                    conditions of those external websites.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    In summary, this section underscores that the website is not a
                                                    provider of third-party products and services, and users should
                                                    exercise caution when dealing with such offerings. It also
                                                    emphasizes the user's responsibility to carefully evaluate and
                                                    understand the terms associated with the purchase of third-party
                                                    goods or services. Additionally, users are reminded of the risks
                                                    associated with accessing external websites through provided links.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={11}>
                                        <p
                                            className="s2"
                                            style={{
                                                paddingTop: "9pt",
                                                paddingLeft: "23pt",
                                                textIndent: "-18pt",
                                                textAlign: "left"
                                            }}
                                        >
                                            Termination of your access to the Site:
                                        </p>
                                        <ol id="l16">
                                            <li data-list-text="11.1.">
                                                <h2
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Provider's Discretion:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    The Provider retains the authority, at its sole and absolute
                                                    discretion, to terminate, suspend, and/or limit a user's access to
                                                    the site. This action can be taken without prior notice or assuming
                                                    any liability<span className="s6">.</span>
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={12}>
                                        <p
                                            className="s2"
                                            style={{ paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}
                                        >
                                            Governing Law and Venue
                                        </p>
                                        <ol id="l17">
                                            <li data-list-text="12.1.">
                                                <h2
                                                    style={{
                                                        paddingTop: "9pt",
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Governing Law:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    The Terms of Service, your use of the Site, and all related matters
                                                    are subject to and will be interpreted in accordance with the laws
                                                    of the State of Delaware.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="12.2.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Jurisdiction:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Any dispute between the Provider (or its affiliates) and the user,
                                                    arising from, connected with, or relating to the Site, the use of
                                                    the Site, the Terms of Service, or related matters, must be brought
                                                    exclusively in a federal or state court with jurisdiction within the
                                                    State of Delaware.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <h2
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Waiver of Objections:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Both the Provider and the user explicitly waive any objection to
                                                    such a venue based on lack of personal jurisdiction or inconvenient
                                                    forum.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={13}>
                                        <p
                                            className="s2"
                                            style={{ paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}
                                        >
                                            Waiver; Severability
                                        </p>
                                        <ol id="l18">
                                            <li data-list-text="13.1.">
                                                <h2
                                                    style={{
                                                        paddingTop: "9pt",
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Waiver:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    The failure by the provider to insist upon or enforce strict
                                                    performance of any provision in the Terms of Service does not
                                                    constitute a waiver of that provision or any right contained in the
                                                    agreement.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="13.2.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Course of Conduct and Trade Practice:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    The historical conduct between the user and the provider, as well as
                                                    any trade practices, do not act to modify the provisions of the
                                                    Terms of Service. In other words, the terms and conditions remain in
                                                    force regardless of past practices.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="13.3.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Severability:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    If any provision in the Terms of Service is deemed invalid, void, or
                                                    unenforceable, that specific provision will be considered as
                                                    separate and independent from the rest. The invalidity, voidness, or
                                                    unenforceability of one provision does not affect the validity and
                                                    enforceability of the remaining provisions, which will continue to
                                                    be in full force and effect.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <h2
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Reservation of Rights:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Any rights not expressly granted by the Terms of Service are
                                                    reserved to the Provider. This statement indicates that the provider
                                                    retains any rights that are not explicitly given to the user in the
                                                    terms and conditions.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={14}>
                                        <p
                                            className="s2"
                                            style={{ paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}
                                        >
                                            Limitation on Time to File Claims
                                        </p>
                                        <ol id="l19">
                                            <li data-list-text="14.1.">
                                                <h2
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    Commencement of Action:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    Any cause of action or claim that a user may have arisen from or
                                                    related to the Terms of Service or the use of the website must be
                                                    initiated within thirty (30) calendar days. This time frame may be
                                                    limited further by applicable law.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <p
                                                    className="s16"
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Barred Claims:
                                                </p>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    If a user fails to commence legal action within the specified time
                                                    frame, the cause of action or claim is deemed permanently barred.
                                                    This means that the user loses the right to pursue legal action for
                                                    that particular matter.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                        </ol>
                                    </li>
                                    <li data-list-text={15}>
                                        <p
                                            className="s2"
                                            style={{
                                                paddingLeft: "23pt",
                                                textIndent: "-18pt",
                                                textAlign: "justify"
                                            }}
                                        >
                                            Communications
                                        </p>
                                        <ol id="l20">
                                            <li data-list-text="15.1.">
                                                <h2
                                                    style={{
                                                        paddingTop: "9pt",
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "justify"
                                                    }}
                                                >
                                                    Written Communication:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Any notice or communication under the Terms of Service must be in
                                                    writing.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <h2
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Methods of Communication:
                                                </h2>
                                                <ul id="l21">
                                                    <li data-list-text="">
                                                        <p
                                                            style={{
                                                                paddingTop: "1pt",
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Notices or communications can be considered given and received
                                                            when sent by:
                                                        </p>
                                                        <ul id="l22">
                                                            <li data-list-text="">
                                                                <p
                                                                    style={{
                                                                        paddingLeft: "77pt",
                                                                        textIndent: "-18pt",
                                                                        textAlign: "left"
                                                                    }}
                                                                >
                                                                    Email,
                                                                </p>
                                                            </li>
                                                            <li data-list-text="">
                                                                <p
                                                                    style={{
                                                                        paddingLeft: "77pt",
                                                                        textIndent: "-18pt",
                                                                        textAlign: "left"
                                                                    }}
                                                                >
                                                                    Text messaging, or
                                                                </p>
                                                            </li>
                                                            <li data-list-text="">
                                                                <p
                                                                    style={{
                                                                        paddingLeft: "77pt",
                                                                        textIndent: "-18pt",
                                                                        textAlign: "left"
                                                                    }}
                                                                >
                                                                    Notifications.
                                                                </p>
                                                                <h2
                                                                    style={{
                                                                        paddingTop: "2pt",
                                                                        paddingLeft: "5pt",
                                                                        textIndent: "0pt",
                                                                        textAlign: "left"
                                                                    }}
                                                                >
                                                                    Language of Communication:
                                                                </h2>
                                                                <p
                                                                    style={{
                                                                        paddingTop: "1pt",
                                                                        paddingLeft: "5pt",
                                                                        textIndent: "0pt",
                                                                        textAlign: "left"
                                                                    }}
                                                                >
                                                                    The designated language for communication is English.
                                                                </p>
                                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                                    <br />
                                                                </p>
                                                            </li>
                                                        </ul>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li data-list-text="15.2.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Electronic Communications Consent:
                                                </h2>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <h2
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Consent by Using the Site:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    By using the Site, the user gives consent to receive electronic
                                                    communications from the Provider. These communications relate to the
                                                    user's use of the Site and its contents.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <h2
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Communication Method:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    The Provider is authorized to communicate with the user via email.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <h2
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Authorization for Data Security Notifications:
                                                </h2>
                                                <p
                                                    style={{
                                                        paddingTop: "1pt",
                                                        paddingLeft: "5pt",
                                                        textIndent: "0pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    The user expressly authorizes the Provider to contact them via email
                                                    if the Provider or its affiliate is required by law to notify the
                                                    user of a data security incident or data breach. This implies that,
                                                    in certain circumstances, the Provider may use email as a means to
                                                    communicate important information regarding data security.
                                                </p>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                            </li>
                                            <li data-list-text="15.3.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    Copyright Infringement Notices:
                                                </h2>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <h2
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Procedure:
                                                </h2>
                                                <ul id="l23">
                                                    <li data-list-text="">
                                                        <p
                                                            style={{
                                                                paddingTop: "1pt",
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            All notices of copyright infringement claims should be sent to
                                                            the copyright agent designated in clause 7.1.
                                                        </p>
                                                    </li>
                                                    <li data-list-text="">
                                                        <p
                                                            style={{
                                                                paddingTop: "2pt",
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            The method and means for sending such notices are outlined
                                                            elsewhere in the agreement.
                                                        </p>
                                                        <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                            <br />
                                                        </p>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li data-list-text="15.4.">
                                                <h2
                                                    style={{
                                                        paddingLeft: "32pt",
                                                        textIndent: "-27pt",
                                                        textAlign: "left"
                                                    }}
                                                >
                                                    General Notices and Communications:
                                                </h2>
                                                <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                                    <br />
                                                </p>
                                                <h2
                                                    style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}
                                                >
                                                    Types of Communication:
                                                </h2>
                                                <ul id="l24">
                                                    <li data-list-text="">
                                                        <p
                                                            style={{
                                                                paddingTop: "1pt",
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Any notices, feedback, comments, requests for technical support,
                                                            and other communications related to the Terms of Service or the
                                                            Site should be sent.
                                                        </p>
                                                        <h2
                                                            style={{
                                                                paddingTop: "2pt",
                                                                paddingLeft: "5pt",
                                                                textIndent: "0pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            Contact Email:
                                                        </h2>
                                                    </li>
                                                    <li data-list-text="">
                                                        <p
                                                            style={{
                                                                paddingTop: "1pt",
                                                                paddingLeft: "41pt",
                                                                textIndent: "-18pt",
                                                                textAlign: "left"
                                                            }}
                                                        >
                                                            The designated email address for sending these communications
                                                            is: <a href="mailto:smlogistics.us@gmail.com">smlogistics.us@gmail.com</a>
                                                        </p>

                                                    </li>
                                                </ul>
                                            </li>
                                        </ol>
                                    </li>
                                </ol>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Fragment>
    )
}

export default TermOfService