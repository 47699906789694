import React, { Fragment, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Container, Row, Col } from "react-bootstrap"

import AccordionComponent from '../../components/faqs/accordion/accordion.component'

import img from "../../assets/images/faq-header.png"

import "./faq.style.scss"

function Faq({page}) {
  useEffect(()=>{
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
  });
  }, [])
  return (
    <Fragment>
         <section className='faq'>
              {
                page === "truck-dispatch"
                ?
                null
                :
                <Container fluid>
                  <Row>
                      <Col lg={12} className='p-0'>
                          <div className='faq-banner'>
                            <img src={img} alt="faq-img" />
                          </div>
                      </Col>
                  </Row>
                </Container>
              }
            
            <Container>
              <Row>
                  <Col lg={12} className='p-0'>
                    <div className='faq-content'>
                        <Container>
                          
                            <Row>
                                <Col lg={12} className='p-0'>
                                    <div className='faq-heading'>
                                        {
                                          page === "truck-dispatch"
                                          ?
                                          <h4>FAQ</h4>
                                          :
                                          <h1>FAQ</h1>
                                        }
                                    </div>
                                </Col>
                            </Row>
                            {
                              page === "truck-dispatch"
                              ?
                              null
                              :
                              <Row>
                                <Col lg={12}>
                                    <div className='content-info'>
                                      <h2>Frequently Asked Questions</h2>
                                      <p>At SM Logistics, we welcome your questions. It’s part of our commitment to excellence in customer care and our dedication to honesty and transparency. Feel free to <Link to="/contact-us">contact us</Link>, or see if your question is one we are frequently asked.</p>
                                    </div>
                                </Col>
                            </Row>
                            }
                            
                            <Row>
                                <Col lg={12}>
                                    <div className='questions'>
                                      <AccordionComponent />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                  </Col>
              </Row>
            </Container>
         </section>
    </Fragment>
  )
}

export default Faq
